.copy-writee p {
  margin: 0px !important;
}
.pb--85 {
  padding-bottom: 85px !important;
}
.mt--160 {
  margin-top: 220px !important;
}
.footer-bg-one {
  background-color: #1a348c;
  /* background-image: linear-gradient(to right, #124a8b, #012644); */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* background-color: #002543; */
  /* background-image: url('https://themewant.com/products/html/drill/handyman/assets/images/footer/01.jpg'); */
  background-size: cover;
  background-repeat: no-repeat;
  /* background: linear-gradient(20deg, rgb(27 52 140) 28%, rgb(248 165 0) 100%); */
}
.bg-cta-footer-one {
  background: #1f1f1f;
  border-radius: 10px;
  margin-top: -80px;
}
.bg-cta-footer-one {
  /* background: #00223d; */
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  margin-top: -80px;
}
.bg-cta-footer-one .logo-area-footer {
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 38px;
  display: block;
  border-radius: 10px 0 0 10px;
  text-align: center;
  color: var(--color-heading);
  text-decoration: none;
  outline: none;
}
.bg-cta-footer-one .logo-area-footer img {
  width: 200px;
}
.bg-cta-footer-one .single-cta-area {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.bg-cta-footer-one .single-cta-area .icon {
  height: 50px;
  width: 50px;
  /* background: #002543; */
  background: linear-gradient(20deg, rgb(27 52 140) 28%, rgb(248 165 0) 100%);
  /* background-image: linear-gradient(to right, #124a8b, #012644); */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.fa-solid,
.fas {
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
}
.bg-cta-footer-one .single-cta-area .icon i {
  color: #fff;
}
.fa-phone:before {
  content: "\f095";
}
.bg-cta-footer-one .single-cta-area .contact-info {
  margin-left: 15px;
  text-align: left;
}
.bg-cta-footer-one .single-cta-area .contact-info p {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 7px;
  color: #74787c;
}
.bg-cta-footer-one .single-cta-area .contact-info a {
  font-weight: 600;

  font-size: 15px;
  line-height: 24px;
  color: #000;
  transition: 0.3s;
  text-decoration: none;
}
.align-items-center {
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.pt--90 {
  padding-top: 90px !important;
}
.single-footer-one-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 136px;
  justify-content: space-between;
}
.single-footer-one-wrapper .single-footer-component {
  flex-basis: 20%;
}
.single-footer-one-wrapper .single-footer-component.first {
  flex-basis: 30%;
}
.single-footer-one-wrapper .single-footer-component .title-area {
  position: relative;
  margin-bottom: 45px;
}
.single-footer-one-wrapper .single-footer-component .title-area .title {
  color: #000;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}
.single-footer-one-wrapper .single-footer-component .title-area::after {
  position: absolute;
  background-image: url("https://themewant.com/products/html/drill/handyman/assets/images/footer/02.png");
  background-size: cover;
  content: "";
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 61px;
  background-repeat: no-repeat;
}
.single-footer-one-wrapper .single-footer-component .body p.disc {
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #fff;
}
.rts-social-style-one ul {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;
}
.rts-social-style-one ul li {
  margin-right: 10px;
  font-size: 16px;
  line-height: 26px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #fff;
}
.rts-social-style-one ul li a {
  display: block;
  padding: 13px;
  background: #032a4c63;
  height: 40px;
  width: 40px;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.fa-brands,
.fab {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}
.rts-social-style-one ul li a i {
  color: #fff;
  transition: 0.3s;
}
.single-footer-one-wrapper .single-footer-component {
  flex-basis: 20%;
}
.single-footer-one-wrapper .single-footer-component .title-area {
  position: relative;
  margin-bottom: 45px;
}
.single-footer-one-wrapper .single-footer-component .title-area .title {
  color: #fff;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}
.pages-footer ul {
  padding: 0;
  margin: 0;
}
.pages-footer ul li {
  display: flex;
  align-items: center;
  margin: 0;
}
.pages-footer ul li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.pages-footer ul li a i {
  margin-right: 15px;
  font-weight: 900;
  font-size: 16px;
  line-height: 40px;
}
.pages-footer ul li a p {
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  transition: 0.3s;
  margin-bottom: 0;
  color: #fff;
}
.cards {
  width: 100%;
  margin: 20px 0 40px;
  float: left;
}
.visa,
.master,
.american {
  background-image: url("https://tripoholidays.com/public/images/ns-sprite.png");
  float: left;
  height: 37px;
  width: 57px;
  margin-right: 10px;
}
.visa {
  background-position: -1px -35px;
}
.cards > a {
  padding: 1px;
  margin: 3px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.master {
  background-position: -69px -35px;
}
.american {
  background-position: -138px -35px;
}
.py_pal {
  background-image: url("https://tripoholidays.com/public/images/ns-sprite.png");
  float: left;
  height: 37px;
  width: 84px;
  background-position: -315px -83px;
}
.rupaylg {
  background-image: url("https://tripoholidays.com/public/images/rupay-lg-nw.png");
  float: left;
  height: 35px;
  width: 57px;
  background-position: 0 0;
}
.pcilg {
  background-image: url("https://tripoholidays.com/public/images/pci-logo.png");
  float: left;
  height: 35px;
  width: 57px;
  background-position: 0 0;
}
.social-image-icon {
  width: 24px;
  height: 24px;
}

@media (max-width: 595.98px) {
  .bg-cta-footer-one .single-cta-area {
    display: flex;
    margin-left: 20px;
    align-items: center;
    /* text-align: center; */
    /* margin-bottom: 10px; */
    justify-content: start;
    /* gap: 10px; */
    margin-top: 15px;
  }
}

@media (min-width: 992px) {
  .popular_dest {
    margin-left: -35px;
  }

  .popular_dest_title {
    text-wrap: nowrap;
  }
}
