.hotelsearchmobile .rom-on-main {
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.hotelsearchmobile .wrapdiv{
    width: 100%;
    background: #fff;
    float: left;
    position: relative;
    z-index: 999999999999991;
    position: fixed !important;
    top: 0;
}

.hotelsearchmobile .rec-bl {
    width: 100%;
    background: #2d3290;
    height: auto;
    padding: 8px 0;
    float: left;
}

.hotelsearchmobile .overflowdiv{
    width: 100%;
    position: relative;
    height: calc(100vh - 30px);
    overflow-y: auto;
    scrollbar-width:thin;
}

.hotelsearchmobile .fli-c-bl {
    width: 94%;
    margin: 0 auto;
}

.hotelsearchmobile .ser-l-bl2 {
    width: 80%;
    float: left;
}

.hotelsearchmobile .sel1-bl {
    width: 25px;
    float: left;
}

.hotelsearchmobile .arr-nw {
    width: 16px;
    height: 12px;
    content: '';
    background: url(https://www.easemytrip.com/images/mob-web/hotel-img/icon.png);
    background-position: -197px 52px;
    background-size: 107px 65px;
    margin: 9px 0;
}

.hotelsearchmobile .sel2-bl {
    width: auto;
    font-size: 17px;
    color: #ffffff;
    padding-top: 4px;
    font-weight: 700;
}

.hotelsearchmobile .rom-on {
    width: 100%;
    margin: 0 auto 0%;
    background-color: #fff;
    border-radius: 3px;
}

.hotelsearchmobile .rom-detail {
    width: 100%;
    color: #000;
    border-bottom: 1PX SOLID #eaeaea;
    padding: 2% 0;
}

.hotelsearchmobile .spc-cty {
    margin-top: 0 !important;
}

.hotelsearchmobile .rom-detail-m {
    width: 95%;
    margin: 0 auto;
}

.hotelsearchmobile .rct-arw-tx {
    font-size: 15px;
    width: 92%;
    float: left;
    margin-top: 0%;
}

.hotelsearchmobile .gst {
    width: 95%;
    margin: 0 auto;
    color: #000;
}

.hotelsearchmobile .gst-tx {
    font-size: 13px;
    color: #787878;
    background: #eee;
    display: inline-block;
    border-radius: 15px;
    padding: 2px 10px;
    margin-top: 9px;
}

.hotelsearchmobile .aldt-m {
    width: 100%;
}

.hotelsearchmobile .ald-m {
    width: 40%;
    float: left;
    font-size: 18px;
    margin-top: 2%;
}

.hotelsearchmobile .ald-m2 {
    width: auto;
    float: right;
}

.hotelsearchmobile .plus_box1, .minus_boxADt, .minus_box1, .plus_boxChd {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: #fff;
    color: #2d3290;
    font-size: 20px;
    line-height: 29px;
    outline: none;
    border: 1px solid #2d3290;
    padding: 0 0 2%;
}

.hotelsearchmobile .qty {
    width: 40px;
    height: auto;
    text-align: center;
    border: 1px solid #fff;
    outline: none;
    font-size: 20px;
}

.hotelsearchmobile .aldt-m-cld {
    width: 100%;
    margin: 5% 0;
}

.hotelsearchmobile .aldt-m-chid {
    width: 100%;
    margin: 1% 0;
}

.hotelsearchmobile .ald-m4 {
    width: 25%;
    float: right;
    position: relative;
    margin-bottom: 12px;
}

.hotelsearchmobile .chld-bx {
    width: 100%;
    outline: none;
    font-size: 18px;
    padding: 4px 0;
    border: 1px solid #dedede;
}

.hotelsearchmobile .ad-rom {
    width: 95%;
    margin: 0 auto;
    color: #000;
}

.hotelsearchmobile .ad-rm-m {
    width: 36%;
    float: left;
    color: #2d3290;
    font-size: 13px;
    cursor: pointer;
    /* text-wrap: nowrap;
    text-overflow: ellipsis; */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 1% 0;
}

.hotelsearchmobile .ad-rm-m2 {
    width: auto;
    float: right;
    margin: 1% 0;
}

.hotelsearchmobile .ad-rm-m2 a {
    color: #2d3290;
    font-size: 13px;
    text-decoration: none;
    list-style: none;
}

.hotelsearchmobile .rom-detail {
    width: 100%;
    color: #000;
    border-bottom: 1PX SOLID #eaeaea;
    padding: 2% 0;
}