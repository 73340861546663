.detailDiv {
  /* width: max-content; */
  /* height: 200px; */
  border-radius: 3px;
  margin-bottom: 15px;
  /* position: relative; */
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(226, 226, 226);
}

.FlightInfoDetailsMain {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  text-align: center;
  /* align-items: center; */
  padding-top: 0px;
  /* margin-bottom: 20px; */
  width: 100%;
}

.FlightInfoDetailsMain p{
  margin-bottom: 0px;
}

.flightInfoDetailsPara {
  padding-top: 0px;
  margin-top: 0px;
}

.flightInforDetailsMain2 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.flightInfoImageMain {
  height: 20px;
  width: 25px;
}

.flightInfoImagetag {
  height: 40px;
  margin-right: 5px;
  /* width: 40px; */
  /* margin-left: 0px; */
  /* margin-top: 20px */
}

.flightInfoDivtag1 {
  /* margin-left: 30px; */
  /* margin-top: 0px; */
  /* padding-top: 0px; */
}

.flightInfoDivPara1 {
  /* margin-top: 0px; */
  /* padding-top: 0px; */
}

.flightInfoMainDiv2 {
  /* margin-left: 70px; */
}

.flightInfoMainDiv2 p{
  margin-bottom: 0;
}

.flightInfoPara2 {
  /* line-height: 0.5cm; */
  font-weight: bold;
  font-size: 18px;
}

.flightInfoPara3 {
  line-height: 0.5cm;
  color: rgb(136, 135, 135);
}

.flightInfoPara4 {
  line-height: 0.5cm;
}

.flightInfoImage2 {
  /* padding-left: 0px; */
  /* margin-top: 5px; */
}

.flightInfoMainDiv5 {
  /* margin-left: 100px; */
}

.flightInfoPara5 {
  line-height: 0.5cm;
  font-weight: bold;
  font-size: 18px;
}

.flightInfoPara6 {
  line-height: 0.5cm;
  color: rgb(136, 135, 135);
}

.flightInfoMainDiv6 {
  width: 100%;
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
  /* justify-content: space-evenly; */
}

.flightInfoInnerDiv6 {
  border: 1px solid rgb(204, 204, 204);
  width: 35%;
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 5px;
}
.flightInfoInnerInnerDiv {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgb(204, 204, 204);
}

.flightInfoPara7 {
  /* margin-left: 15px; */
}

.flightInfoMainInner1 {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgb(204, 204, 204);
}

.flightInfoPara8 {
  font-weight: bold;
  /* margin-left: 15px; */
}

.flightInfoPara9 {
  margin-left: 19px;
}

.flightInfoMainDivInner4 {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgb(204, 204, 204);
}

.flightInfoPar10 {
  font-weight: bold;
  /* margin-left: 15px; */
}

.flightInfoMainDiv15 {
  width: 65%;
  padding: 5px;
  /* margin-right: 15px; */
  /* margin-left: 10px; */
  padding-left: 40px;
}

.flightInforUnderLiner {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.flightInfoMainDiv8 {
  /* width: 95%; */
  /* margin-left: 2.5%; */
  border: 1px solid rgb(204, 204, 204);
  border-radius: 3px;
  padding-left: 10px !important;
}

.flightInfoUnderline2 {
  font-size: 13px;
  /* margin: 15px; */
}

.flightInfortabPanel1 {
  width: 100%;
}

.flightInfoPanel2 {
  width: 95%;
  margin-left: 2.5%;
  border: 1px solid rgb(204, 204, 204);
  display: flex;
}

.flightInfoPanel3 {
  width: 33%;
  text-align: center;
  border-right: 1px solid rgb(204, 204, 204);
}

.flightInfoPanel4 {
  width: 33%;
  text-align: center;
}

.flightInfoPanel5 {
  width: 96%;
  margin-left: 2.5%;
  display: flex;
}

.flightInfoPanel6 {
  display: flex;
  border-left: 1px solid rgb(204, 204, 204);
  border-right: 1px solid rgb(204, 204, 204);
  width: 33%;
  text-align: center;
}

.flightInfoPara7 {
  /* width: 30.2%; */
  /* margin: auto; */
  /* text-align: center; */
}

.flightInfoPanel8 {
  border-left: 1px solid rgb(204, 204, 204);
  border-right: 1px solid rgb(204, 204, 204);
  width: 33.9%;
  text-align: center;
  margin: auto;
}

.flightInfoPanelImage {
  /* margin-left: 105px; */
  height: 40px;
  width: 40px;
  margin-top: 10px;
}

.flightInfoPanel9 {
  /* width: 95%; */
  width: 100%;
  /* margin-left: 2.5%; */
  border: 1px solid rgb(204, 204, 204);
  padding: 10px;
  font-size: 13px;
}

.flightInfoPanel0 {
  margin: 10px;
}

.flightInfoPara12 {
  margin-left: 0px;
  padding-left: 0px;
}

.css-heg063-MuiTabs-flexContainer {
  overflow: auto;
  scrollbar-width: none;
}

/* .flightInforUnderLiner,
.flightInfoUnderline2 {
  list-style: square;
} */

.flightInforUnderLiner,
.flightInfoUnderline2 {
  max-height: 150px;
  overflow-y: auto;
  scrollbar-width: thin;
  padding-left: 20px;
  /* list-style-type: disc !important; */
}

@media (max-width: 768px) {
  .flightInfoImagetag {
    height: 30px;
  }

  .flightInfoDivPara1,
  .flightInfoPara4,
  .fli {
    font-size: 13px;
  }

  .flightInfoPara2,
  .flightInfoPara3,
  .flightInfoPara12 {
    font-size: 15px;
  }

  .flightInfoInnerDiv6 {
    width: 100%;
  }

  .flightInfoMainDiv15 {
    width: 100%;
  }

  .flightInfoPara8,
  .flightInfoPara9,
  .flightInfoPar10,
  .flightInfoPara7 {
    margin: 0;
    font-size: 15px;
  }

  .flightInfoMainDiv8 {
    border: none;
  }

  .flightInfoMainDiv15,
  .flightInfoMainDiv8 {
    margin-top: 10px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  }

  .flightInfoMainDiv15 h3,
  .flightInfoMainDiv8 h3 {
    font-size: 22px;
    font-weight: 500;
  }
}

@media (max-width: 378px) {
  .css-13xfq8m-MuiTabPanel-root {
    padding: 0 !important;
  }

  .FlightInfoDetailsMain {
    justify-content: center;
  }

  .flightInforDetailsMain2 {
    flex-wrap: wrap !important;
  }

  .imgdiv {
    margin-inline: 5px;
    width: 100% !important;
    flex-direction: row;
    justify-content: space-around;
    border-bottom: 1px dotted grey;
    margin-bottom: 5px;
  }

  .flightInfoMainDiv2 {
    width: 40%;
    margin: 0;
    padding: 0;
  }

  .flightInfoMainDiv5 {
    width: 20%;
  }

  .flightInfoPara2,
  .flightInfoPara3,
  .flightInfoPara4 {
    margin: 0;
    width: 100%;
    text-align: center;
  }
}

.flightInfoMainDiv6 p {
  margin-bottom: 0;
  font-size: 14px;
}
/* .flightInforUnderList{
  display: flex;
  gap: 5px;
 
} */
.flightInforUnderList .icons{
  font-size: 16px !important;

  /* width: 5%; */
}
.flightInforUnderList span{
  /* width: 90%; */
}
.flightInforUnderList{
  display: flex;
  gap: 5px;
}
.extraSkeletonImage4{
  width: 170px;
}
.extraSkeletionImage{
  width: 100px;
  height: 60px;
}
.extraSkeletionImage2{
  width: 80px;
  height: 60px;
}
.extraSkeleton5{
  width: 380px;
}
@media(max-width:1100px){
  .extraSkeletoon{
    display: none;
  }
}
@media(max-width:992px){
  .extraSkeletion2{
    display: none;
  }
}
@media(max-width:605px){
  .extraSkeletion3{
    display: none;
  }
}

@media(max-width:515px){
  .extraSkeletion4{
    display: none;
  }
}
@media(max-width:445px){
  .extraSkeleton4{
    display: none;
  }
  .extraSkeletionImage{
    width: 50px;
    height: 20px !important;
  }
  .extraSkeletionImage2{
    width: 50px;
    height: 40px;
  }
  .extraSkeleton5{
    width: 260px;
  }
  .extraSkeletonImage4{
    width: 100px;
  }
}
@media only screen and (min-width:740px) and (max-width:769px){
  .extraSkeleton4{
    display: none;
  }
}