.gallery-one {
  position: relative;
  display: block;
  /* padding: 200px 0 70px;  */
  /* background-color: #faf5ee; */
  /* margin-top: -70px; */
  margin-top: 70px;
  margin-bottom: 100px;
}
/* .gallery-one-bg {
  position: absolute;
  top: 128px;
  left: 50%;
  transform: translateX(-50%);
  background-repeat: no-repeat;
  background-position: 50%;
  height: 560px;
  width: 1157px;
  opacity: 0.6;
} */
.gallery-one__container-box {
  position: relative;
  display: block;
  max-width: 1620px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}
.gallery-one__content {
  position: relative;
  display: block;
  margin-right: -5px;
  margin-left: -5px;
}
.list-inline,
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.gallery-one__content li {
  position: relative;
  display: block;
  float: left;
  width: 20%;
  padding: 0 5px;
  overflow: hidden;
  margin-bottom: 30px;
}
.gallery-one__img-box {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 8px;
}
.gallery-one__img-box:before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: rgba(48, 49, 68, 0.9);
  border-radius: 8px;
  transition: all 0.7s ease;
  transform: translateY(-105%);
  z-index: 1;
}
.gallery-one__img-box img {
  width: 100%;
  border-radius: 8px;
  transform: scale(1);
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.gallery-one__iocn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateY(50px);
  z-index: 2;
}
.gallery-one__iocn a {
  color: #fff;
  font-size: 35px;
  transition: all 0.5s ease;
}
.fa-instagram:before {
  content: "\f16d";
}

.destinations-one__single {
  position: relative;
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
}
.destinations-one__imggg {
  position: relative;
  display: block;
  border-radius: 3px;
  overflow: hidden;
  z-index: 1;
}
.destinations-one__single:hover .destinations-one__imggg:before {
  transform: scale(1.2);
  transform-origin: bottom center;
}

.destinations-one__imggg:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  border-radius: 8px;
  background-color: rgba(48, 48, 65, 0.5);
  transform: scaleY(0);
  transition: transform 0.5s ease;
  transform-origin: bottom center;
  z-index: 1;
}
.destinations-one__imggg img {
  width: 100%;
  border-radius: 8px;
  transform: scale(1);
  transition: transform 0.5s ease;
}
.destinations-one__contenttt {
  position: absolute;
  bottom: 8px;
  left: 30px;
  z-index: 2;
}
.destinations-one__title {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 14px;
  display: block;
}
.destinations-one__sub-title,
.destinations-one__title {
  letter-spacing: -0.02rem;
  position: relative;
  z-index: 1;
}
.destinations-one__title > a {
  color: #fff;
  text-decoration: none;
  transition: all 0.5s ease;
}
.destinations-one__button {
  position: absolute;
  top: 20px;
  right: 20px;
  line-height: 30px;
  transition: all 0.5s ease;
  transform: scaleX(0);
  z-index: 2;
}
.destinations-one__single:hover .destinations-one__button {
  transform: scaleX(1);
}
.destinations-one__button > button {
  font-size: 11px;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  background-color: #002543;
  display: inline-block;
  border-radius: 8px;
  padding: 0 15px;
  transition: all 0.5s ease;
}
.destinations-one__single:hover .destinations-one__img img {
  transform: scale(1.05);
}

.svg-similar-reso {
  width: 30%;
}
@media (max-width: 767px) {
  .svg-similar-reso {
    width: 80%;
  }
}
