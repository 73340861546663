/* Holidays package css  */
.pt-60 {
  padding-top: 60px;
}
.holiday-pack-h2 {
  margin-top: 0;
  font-size: 35px;
  margin-bottom: 15px;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
  line-height: 1.3;
  color: #222;
}
.holiday-pack-h2 strong {
  color: #222;
  font-weight: 600;
}
.holidays-pack-para {
  width: 100%;
  position: relative;
  font-family: "Montserrat", sans-serif !important;
  background: #fff;
  color: #999;
  font-size: 16px;
}
.showcase {
  display: flex;
  flex-wrap: wrap;
  gap: 1.3%;
}
.travel-card {
  position: relative;
  display: inline-flex;
  flex-flow: column nowrap;
  font-weight: 300;
  background-color: white;
  box-shadow: 0px 0px 100px -10px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  width: 100%;
  margin-top: 20px;
  text-decoration: none;
  color: #019787;
  transition: all 0.2s;
}
.travel-card .image {
  width: 100%;
  height: 200px;
  border-radius: 5px;
  /* border-top-left-radius: inherit;
  border-top-right-radius: inherit; */
  overflow: hidden;
}
.travel-card .image > img {
  width: 100%;
  height: 100%;
  transition: all 0.28s ease-in-out;
  height: 200px;
  object-fit: cover;
}
.travel-card > .content {
  color: #545454;
  padding: 15px 20px;
}
.travel-card > .content > .topic {
  font-size: 18px;
  word-break: break-all;
  overflow: hidden;
  position: relative;
  background-color: white;
  margin-bottom: 0;
  font-weight: 600;
}
.travel-card > .content > .recommendation {
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
}
.travel-card > .content > .recommendation > .score {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* background: #002543; */
  background: linear-gradient(20deg, rgb(27 52 140) 28%, rgb(248 165 0) 100%);
  color: white;
  padding: 2px 8px;
  border-radius: 30px;
  font-size: 12px;
}
.travel-card > .content > .recommendation > .score > :first-child {
  padding-top: 1px;
  padding-left: 4px;
}
.travel-card > .content > .recommendation > .score > :last-child {
  font-size: 1.1em;
  padding-left: 6px;
}
.travel-card > .content > .price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.travel-card > .content > .price > .discount-info {
  color: #002543;
  font-size: 14px;
  font-weight: 500;
}
.travel-card > .content > .price > .original-price {
  font-size: 20px;
  font-weight: 600;
  color: #222;
}
.fa,
.fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.travel-card > .content > .price > .original-price i {
  font-size: 16px;
  margin-right: 3px;
}
.fa-rupee-sign:before {
  content: "\f156";
}

/* Why us  */
#why-us {
  position: relative;
  margin-top: 20px;
  padding: 30px 0px;
}
#why-us h2 {
  margin-bottom: 30px;
}
#why-us .pt-2 {
  padding-top: 0.5rem !important;
}
.img-rounded {
  border-radius: 5px !important;
}

/* Slider COde  */
.rounded {
  border-radius: 8px !important;
}
/* .item img {
  position: relative;
} */

.item {
  position: relative;
}
.slider-content-container img {
  display: block;
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(
    0 0 0 / 65%
  ); /* Change the color and opacity of the overlay as needed */
  opacity: 0;
  transition: opacity 0.5s ease;
  border-radius: 8px;
}

.item:hover .overlay {
  opacity: 1;
}
.item:hover .overlay-content {
  opacity: 1;
}
.item .overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.item .view-button {
  color: #fff;
  display: block;
  border: solid 1px #fff;
  padding: 10px;
  /* margin-left: -44px; */
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
}
