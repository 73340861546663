._top_bnnr {
    width: 94%;
    margin: 15px auto;
    display: flex;
}

._top_bnnr img {
    width: 100%;
    object-fit: cover;
    height: 230px;
    border-radius: 15px;
}

@media (max-width:992px){
    ._top_bnnr{
        display: none;
    }
}