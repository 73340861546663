#full-container {
  overflow: hidden;
  position: relative;
  max-width: 100%;
  background-color: #fff;
}
#full-container label {
  margin-bottom: 0px;
}
.roundtrippg #content {
  position: relative;
  line-height: 1.8;
  background-color: #fff;
  z-index: 5;
}
.roundtrippg #content-wrap {
  position: relative;
}
.roundtrippg .section-flat {
  width: 100%;
  position: relative;
  margin-top: 30px;
}
.roundtrippg .single_sec_flat {
  margin-top: 0px;
}
.roundtrippg .section-flat .section-content {
  width: 100%;
  padding: 15px 0 15px;
  position: relative;
  clear: both;
}
.roundtrippg .page-search .section-content {
  background-color: #f6f6f6;
}
.roundtrippg .page-search .section-content {
  padding-bottom: 30px;
}
.roundtrippg .single_sec_flat .section-content {
  padding: 0px;
}
.roundtrippg .modify_search_sec .inner_search_sec .search_opacity {
  display: none;
}
.roundtrippg .modify_search_sec .flight_search_info,
.roundtrippg .inner_hotel_detail .insurance_search_info {
  background: #fff;
  border: 1px solid rgba(156, 170, 179, 0.28);
  box-shadow: 0 0 9px 0 rgb(0 0 0 / 10%);
  border-radius: 5px;
  padding: 8px 10px;
  margin: 8px 0px 5px;
  position: relative;
}
.roundtrippg .modify_search_sec .flight_search_info .cus_flight_info,
.roundtrippg .inner_hotel_detail .insurance_search_info .cus_flight_info {
  display: inline-block;
}
.roundtrippg .flight_search_info .cus_flight_info ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}
.roundtrippg .modify_search_sec .flight_search_info ul li.flight_namess,
.roundtrippg .inner_hotel_detail
  .insurance_search_info
  .cus_flight_info
  ul
  li.flight_namess {
  font-size: 16px;
  line-height: 21px;
  color: #000;
  font-weight: 500;
}
.roundtrippg .modify_search_sec .flight_search_info .cus_flight_info ul li,
.roundtrippg .inner_hotel_detail .insurance_search_info .cus_flight_info ul li {
  vertical-align: top;
}
.roundtrippg .modify_search_sec .flight_search_info .cus_flight_info ul li:first-child,
.roundtrippg .inner_hotel_detail .insurance_search_info .cus_flight_info ul li:first-child {
  width: auto;
}
.roundtrippg .modify_search_sec .flight_search_info ul li.flight_namess span,
.roundtrippg .inner_hotel_detail
  .insurance_search_info
  .cus_flight_info
  ul
  li.flight_namess
  span {
  font-size: 12px;
  line-height: 16px;
  display: block;
  font-weight: 400;
}
/* .modify_search_sec .flight_search_info .cus_flight_info ul li,
.inner_hotel_detail .insurance_search_info .cus_flight_info ul li {
  vertical-align: top;
} */
.roundtrippg .modify_search_sec .flight_search_info ul li.arrow,
.roundtrippg .inner_hotel_detail .insurance_search_info .cus_flight_info ul li.arrow {
  font-size: 16px;
  color: #000;
  vertical-align: text-bottom;
  line-height: 36px;
}
.roundtrippg .fa,
.roundtrippg .fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.roundtrippg .fa-arrow-right:before {
  content: "\f061";
}
.roundtrippg .modify_search_sec .flight_search_info ul li.border_rgt,
.roundtrippg .inner_hotel_detail .insurance_search_info .cus_flight_info ul li.border_rgt {
  border-right: 1px solid #e3e3e3;
}
.roundtrippg .modify_search_sec .flight_search_info .cus_flight_info ul li.flight_time,
.roundtrippg .inner_hotel_detail .insurance_search_info .cus_flight_info ul li.flight_time {
  color: #000;
}
.roundtrippg .modify_search_sec .flight_search_info .cus_flight_info ul li span.span_label,
.roundtrippg .inner_hotel_detail
  .insurance_search_info
  .cus_flight_info
  ul
  li
  span.span_label {
  font-size: 13px;
  line-height: 18px;
  display: block;
}
.roundtrippg .modify_search_sec .flight_search_info .cus_flight_info ul li strong,
.roundtrippg .inner_hotel_detail .insurance_search_info .cus_flight_info ul li strong {
  font-weight: 300;
  color: #000;
}
.roundtrippg .modify_search_sec
  .flight_search_info
  .cus_flight_info
  ul
  li.flight_time
  strong,
  .roundtrippg .inner_hotel_detail
  .insurance_search_info
  .cus_flight_info
  ul
  li.flight_time
  strong {
  font-size: 16px;
  line-height: 21px;
}
.roundtrippg .modify_search_sec
  .flight_search_info
  .cus_flight_info
  ul
  li.flight_time
  span.date,
  .roundtrippg .inner_hotel_detail
  .insurance_search_info
  .cus_flight_info
  ul
  li.flight_time
  span.date {
  font-size: 14px;
  line-height: 18px;
}
.roundtrippg .modify_search_sec .flight_search_info .cus_flight_info ul li span.span_label,
.roundtrippg .inner_hotel_detail
  .insurance_search_info
  .cus_flight_info
  ul
  li
  span.span_label {
  font-size: 13px;
  line-height: 18px;
  display: block;
}
.roundtrippg .modify_search_sec .flight_search_info .cus_flight_info ul li.travel_class,
.roundtrippg .inner_hotel_detail .insurance_search_info .cus_flight_info ul li.travel_class {
  font-size: 13px;
  line-height: 16px;
}
.roundtrippg .modify_search_sec .flight_search_info .cus_flight_info,
.roundtrippg .inner_hotel_detail .insurance_search_info .cus_flight_info {
  display: inline-block;
}
.roundtrippg .flight_search_info .cus_flight_info ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}
.roundtrippg .modify_search_sec .modify_btn {
  display: inline-block;
  vertical-align: top;
  margin: 4px 0px 0px 10px;
}
.roundtrippg .modify_search_sec .modify_btn button,
.roundtrippg .modify_search_sec .modify_search .close_flight a,
.roundtrippg .insurance_sidebar form.insurance_form .modify_btn button {
  color: #fff;
  border: 0px;
}
.roundtrippg .modify_search_sec .modify_btn button,
.roundtrippg .modify_search_sec .modify_search .close_flight a,
.roundtrippg .modify_search_sec .flight_search_info .offer_style_btn a.offer_btn,
.roundtrippg .insurance_sidebar form.insurance_form .modify_btn button {
  width: auto;
  border-radius: 4px;
  margin: auto;
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  z-index: 99;
  background: #5091fb;
  height: auto;
}
.roundtrippg .mt-10 {
  margin-top: 10px;
}
.roundtrippg .roundtrip_header {
  background: #fff;
}
.roundtrippg .roundtrip_header ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}
.roundtrippg .roundtrip_header ul li {
  float: left;
  margin-right: 1%;
  color: #000;
}
.roundtrippg .roundtrip_header ul li:last-child {
  margin-right: 0;
}
.roundtrippg .roundtrip_header ul li.prev_next_btn {
  border-radius: 3px;
  background: #f1f1f1;
  text-align: center;
}
.roundtrippg .roundtrip_header ul li.prev_next_btn a {
  color: #222121;
  text-transform: uppercase;
}
.roundtrippg .roundtrip_header ul li.prev_next_btn span {
  display: inline-block;
  margin: 0px 5px;
  border-right: 1px solid #cecece;
  width: 1px;
  vertical-align: middle;
}
.roundtrippg clearfix,
.roundtrippg .mg_ul_box,
.roundtrippg .my_clr_r,
.roundtrippg .searchpop1 .wait-txe {
  clear: both;
}
.roundtrippg .flight_info {
  border: 1px solid #d2d2d2;
  padding: 3px;
  box-shadow: 0px 2px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 4px;
}
.roundtrippg .flight_info ul {
  list-style-type: none;
  display: block;
  padding: 0px;
}
.roundtrippg .flight_info ul li {
  float: left;
  width: 16.6%;
  color: #000;
}
.roundtrippg .flight_info ul li a {
  color: #000;
}
.roundtrippg .flight_info ul li a i {
  font-size: 12px;
}
.roundtrippg .flight_info ul li input {
  padding: 0 15px;
  width: 100%;
  height: 45px;
  line-height: 46px;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  box-shadow: none;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: all 0.2s;
  background-color: #fff;
  color: #222;
}
.roundtrippg .block-content-2 {
  padding: 30px 30px;
  box-shadow: 0px 2px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.roundtrippg .roundtrip_search .custom_block_content {
  position: relative;
}
.roundtrippg .roundtrip_search .custom_block_content,
.roundtrippg .oneway_search .custom_block_content,
.roundtrippg .internationtrip_search .custom_block_content {
  border-radius: 5px;
  border: 1px solid #d2d2d2;
}
/* .box-result {
  display: flex;
} */
.roundtrippg .custom_box_result {
  display: block;
}
.roundtrippg .round_trip_list .custom_box_result .flight_namess {
  color: #89ad3e;
  margin: 0px 0px 3px;
}
.roundtrippg .round_trip_list .custom_box_result .flight_namess span {
  margin-left: 5px;
}
.roundtrippg .list-search-resultss {
  list-style: none;
  flex: 1;
}
.roundtrippg .custom_box_result ul.list-search-resultss,
.roundtrippg .custom_box_result ul.list-search-resultss li {
  flex: auto;
}
.roundtrippg .custom_box_result ul.list-search-resultss li {
  float: left;
  width: 16.6%;
  margin: 0px;
}
.roundtrippg .custom_box_result ul.list-search-resultss li img {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
}
.roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li img {
  width: 25px;
}
.roundtrippg .list-search-resultss > li {
  flex: 1;
}
.roundtrippg .list-search-resultss > li + li {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px dashed #ccc;
}
.roundtrippg .custom_box_result ul.list-search-resultss li + li {
  border: 0px;
}
.roundtrippg .oneway_search .custom_box_result ul.list-search-resultss li.price,
.roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li.price {
  color: #f57a19;
}
.roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li.round_check {
  text-align: right;
  padding: 0px;
}
.roundtrippg .round_check .checkbox-default input {
  /* width: max-content; */
  /* position: absolute; */
  opacity: 0;
  cursor: pointer;
  /* height: 100%; */
  /* width: 100%; */
  top: 0px;
  left: 0px;
  z-index: 9;
  margin: 0px;
}
.roundtrippg .round_check .checkbox-default .checkboxmark {
  display: inline-block;
  position: relative;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  flex: 0 0 20px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px #ccc;
}
.roundtrippg .round_check .checkbox-default input:checked ~ .checkboxmark {
  background-color: #5091fa;
  box-shadow: inset 0 0 0 1px #5091fa;
}
.roundtrippg .round_check .checkbox-default .checkboxmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 5px;
  top: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
}
.roundtrippg .round_check .checkbox-default input:checked ~ .checkboxmark:after {
  display: block;
}
.roundtrippg .custom_box_result ul.list-search-resultss li span.date {
  display: block;
  color: #000;
  font-weight: 600;
}
.roundtrippg .custom_box_result ul.list-search-resultss li span.duration {
  display: block;
  text-align: center;
}
.roundtrippg .custom_box_result ul.list-search-resultss li span.duration span:before {
  content: "";
  height: 7px;
  display: block;
}
.roundtrippg .roundtrip_search
  .custom_box_result
  ul.list-search-resultss
  li
  span.duration
  > span:before {
  background: url("https://www.zapbooking.com/public/images/arow_main.png")
    right no-repeat;
}
.roundtrippg .cus_tooltip {
  position: relative;
  display: inline-block;
  z-index: 99;
  width: 100%;
}
.roundtrippg .cus_tooltip .tooltiptext {
  visibility: hidden;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -35px;
  left: 50%;
  opacity: 0;
  transition: opacity 0.3s;
  transform: translate(-50%, 0);
}
.roundtrippg .custom_box_result ul.list-search-resultss li span.duration span {
  display: block;
  font-size: 12px;
}
.roundtrippg .cus_tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}
.roundtrippg .roundtrip_search .flight_details {
  position: relative;
  z-index: 9;
}
.roundtrippg .roundtrip_search .flight_details > a {
  text-align: right;
  display: block;
}
.roundtrippg .flight_details .flight_details_info {
  display: none;
  position: relative;
}
/* @media (max-width: 480px) {
  .roundtrip_search .custom_box_result ul.list-search-result li:nth-child(5) {
    width: calc(100% - 80px);
    float: left;
    padding: 0px 4px;
  }
} */

/* form css */
.roundtrippg .pr {
  position: relative;
}
.roundtrippg .search-page {
  font-family: "Rubik", sans-serif;
  color: #333;
  font-size: 14px;
}
.roundtrippg .modify-search {
  font-family: "Rubik", sans-serif;
  z-index: 13;
  background: linear-gradient(to right, #131b5c, #25092f);
  /* background: -webkit-linear-gradient(left, #b7243a, #43264E);
    background: -moz-linear-gradient(left, #b7243a, #43264E);
    background: -ms-linear-gradient(left, #b7243a, #43264E);
    background: -o-linear-gradient(left, #b7243a, #43264E); */
}
.roundtrippg .wr-width {
  max-width: 1140px;
  padding: 0 24px;
}
.roundtrippg .wr-hr-center {
  margin: 0 auto;
}
.roundtrippg .fs-14 {
  font-size: 1rem;
}
.roundtrippg .grid > * {
  display: block;
  float: left;
  box-sizing: border-box;
  padding-left: 8px;
  padding-right: 8px;
}
.roundtrippg .pt-15 {
  padding-top: 15px;
}
.roundtrippg .grid > *:first-child {
  padding-left: 0;
}
.roundtrippg .modify-search .animated {
  animation-duration: 0.5s;
  transition-timing-function: ease-in;
  animation-name: showContent;
}
.roundtrippg .modify-search ul.trip-type,
.roundtrippg .modify-search .input-filters {
  padding-left: 8.6%;
  color: #fff;
  letter-spacing: 0;
}
.roundtrippg .modify-search ul.trip-type {
  float: none;
}
.roundtrippg .modify-search ul.trip-type li {
  display: inline-block;
  font-size: 0;
  width: 12%;
}
.roundtrippg .cursor-pointer {
  cursor: pointer;
}
/* .radio {
  display: inline-block;
  position: relative;
  border-radius: 50%;
  border: 1px solid #909090;
  width: 16px;
  height: 16px;
} */
.roundtrippg .v-aligm-t {
  vertical-align: top;
}
.roundtrippg .modify-search ul.trip-type li .trip-type-input-holder .radio {
  vertical-align: top;
  border: 1px solid #fff;
}
.roundtrippg input,
.roundtrippg .drop-down {
  border: 0;
  outline: 0;
  /* background-color: transparent; */
  letter-spacing: 0;
  color: #fff;
}
.roundtrippg .radio input[type="radio"] {
  opacity: 0;
}
.roundtrippg .bold {
  font-family: "Rubik", sans-serif;
}
.roundtrippg .radio input[type="radio"] + i {
  position: absolute;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  padding: 1px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.roundtrippg .modify-search
  ul.trip-type
  li
  .trip-type-input-holder
  .radio
  input[type="radio"]
  + i {
  width: 7px;
  height: 7px;
  padding: 0;
}
.roundtrippg .pl-5 {
  padding-left: 5px;
}
.roundtrippg .modify-search ul.trip-type li {
  display: inline-block;
  font-size: 0;
  width: 12%;
}
.roundtrippg .modify-search ul.trip-type .active .trip-type-input-holder .radio {
  border: 5px solid #fff;
}
.roundtrippg .pull-right {
  float: right;
}
.roundtrippg .fs-22 {
  font-size: 1.57143rem;
}
.roundtrippg .i-b {
  display: inline-block;
}
[class^="ytfi-"]:before,
[class*=" ytfi-"]:before {
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "fontello";
}
.roundtrippg .ytfi-cancel:before {
  content: "";
}
.roundtrippg .modify-search ul.trip-type:after {
  content: "";
  display: block;
  clear: both;
}
.roundtrippg .flight-details {
  background: linear-gradient(to right, #150175, #0f0a54);
  color: #fff;
  font-family: "Rubik", sans-serif;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.roundtrippg .grid > .col-10 {
  width: 83%;
}
.roundtrippg .modify-search .flight-details {
  background: inherit;
  padding: 12px 0;
}
.roundtrippg .modify-search .flight-details li {
  display: inline-block;
  color: #fff;
  text-align: left;
}
.roundtrippg .modify-search .flight-details .sel-icon {
  margin-right: 6%;
  /* min-width: 5%; */
}
/* .ytfi-trailing-plane {
  z-index: 100;
  background: url(./flight.png) no-repeat;
} */
.roundtrippg .modify-search .flight-details .sel-origin,
.roundtrippg .modify-search .flight-details .sel-dest {
  width: 17%;
}
.roundtrippg .modify-search .flight-details .sel-origin {
  margin-right: 7.6%;
}
.roundtrippg .fs-10 {
  font-size: 0.71429rem;
}
.roundtrippg .modify-search .flight-details li .light-label {
  opacity: 0.66;
}
.roundtrippg .pb-2 {
  padding-bottom: 2px !important;
}
.roundtrippg .modify-search .bdr-btm {
  border-bottom: 1px solid rgba(226, 226, 226, 0.22);
}
.roundtrippg .full-width {
  width: 100%;
}
.roundtrippg .ellipsis {
  max-width: 99.5%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background: transparent;
}
.roundtrippg .fs-11 {
  font-size: 11px !important;
}
.roundtrippg .rotate-anim {
  transition: transform 0.2s;
}
.roundtrippg .abs {
  position: absolute;
}
.roundtrippg .modify-search .flight-details .sel-origin .ytfi-arrow1 {
  top: 1.5rem;
  right: -31%;
  opacity: 0.44;
}
.roundtrippg .ytfi-arrow1:before {
  content: "";
}
.roundtrippg .modify-search .error-msg {
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  color: #f34f4f;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
}
.roundtrippg .modify-search .flight-details .sel-dest {
  margin-right: 3.4%;
}
.roundtrippg .modify-search .flight-details .sel-date {
  width: 18.1%;
  margin-right: 4.5%;
}
.roundtrippg .fs-16 {
  font-size: 1.14286rem;
}
.roundtrippg .pb-2 {
  padding-bottom: 2px;
}
.roundtrippg .modify-search .flight-details .sel-date .depart {
  width: 38%;
  display: inline-block;
}
.roundtrippg .hide {
  display: none !important;
}
.roundtrippg .modify-search .flight-details .sel-date input {
  border-bottom: 0;
}
.roundtrippg .modify-search .flight-details .sel-date .full-width {
  width: 100%;
}
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.roundtrippg .ng-cloak,
.roundtrippg .x-ng-cloak,
.roundtrippg .ng-hide:not(.ng-hide-animate) {
  display: none !important;
}
.roundtrippg .modify-search .datepicker-wrapper {
  position: absolute;
  z-index: 999;
  color: #444;
  width: 360px;
  top: 100%;
}
.roundtrippg .fixed {
  position: fixed;
}
.roundtrippg .modify-search .datepicker-wrapper * {
  box-sizing: border-box;
}
.roundtrippg .search-page .dp-bg {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: transparent;
}
.roundtrippg .modify-search .datepicker-inner .ytfi-arrow-right,
.roundtrippg .modify-search .datepicker-inner .ytfi-arrow-left {
  height: 24px;
  position: absolute;
  top: 28px;
  width: 40px;
  padding: 6px 12px;
  font-size: 10px;
  color: #999;
  border: 1px solid #fff;
}
.roundtrippg .modify-search .datepicker-inner .ytfi-arrow-right {
  right: 30px;
}
.roundtrippg .modify-search .datepicker-inner .ytfi-arrow-left {
  left: 30px;
}
.roundtrippg .modify-search .full {
  width: 100%;
  float: left;
}
.roundtrippg .modify-search .flight-details .sel-date .ytfi-calendar,
.roundtrippg .modify-search .flight-details .sel-date .ytfi-cancel {
  right: -2px;
  bottom: 4px;
  opacity: 0.4;
}
.roundtrippg .modify-search
  .datepicker-wrapper
  .datepicker-inner
  .datepicker-header
  .datepicker-close.ytfi-cancel {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 15px;
  height: 15px;
  color: #444;
  font-size: 25px;
  line-height: 15px;
  font-style: normal;
  font-weight: bold;
  opacity: 1;
}
.roundtrippg .modify-search .flight-details .sel-date .hyphen {
  margin-right: 10px;
}
.roundtrippg .modify-search .flight-details .sel-date .return {
  width: 40%;
  display: inline-block;
}
.roundtrippg .modify-search .flight-details .sel-class {
  width: 20.16%;
}
.roundtrippg .modify-search .flight-details li:last-of-type {
  padding: 0;
}
.roundtrippg .modify-search .flight-details .sel-class .ellipsis {
  max-width: 93.5%;
}
.roundtrippg .modify-search .flight-details .sel-class .ytfi-angle-down,
.roundtrippg .modify-search .flight-details .sel-class .ytfi-angle-up {
  opacity: 0.44;
  right: 5px;
  top: 22px;
  position: absolute;
}
.roundtrippg .modify-search .flight-details .sel-class .traveller-table {
  width: 281px;
  background: #fff;
  color: #666;
  left: auto;
  right: 0;
  top: 50px;
  z-index: 14;
  padding: 2px 23px 11px 21px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
}
.roundtrippg .modify-search .triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  top: -8px;
  right: 10px;
}
.roundtrippg .modify-search
  .flight-details
  .sel-class
  .traveller-table
  .tab-container
  ul
  li.noOf {
  display: block;
  color: #333;
  border-bottom: 1px solid rgba(226, 226, 226, 0.77);
}
.roundtrippg .modify-search
  .flight-details
  .sel-class
  .traveller-table
  .tab-container
  ul
  li.noOf
  .spinner {
  float: right;
}
.roundtrippg .modify-search
  .flight-details
  .sel-class
  .traveller-table
  .tab-container
  ul
  li.noOf
  .spinner
  .minus,
  .roundtrippg .modify-search
  .flight-details
  .sel-class
  .traveller-table
  .tab-container
  ul
  li.noOf
  .spinner
  .plus {
  padding: 5px 10px;
  border: 1px solid rgba(226, 226, 226, 0.77);
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}
.roundtrippg .disabled {
  pointer-events: none;
  color: #999;
  opacity: 0.6;
}
.roundtrippg .modify-search
  .flight-details
  .sel-class
  .traveller-table
  .tab-container
  ul
  li.noOf
  .spinner
  .minus,
  .roundtrippg .modify-search
  .flight-details
  .sel-class
  .traveller-table
  .tab-container
  ul
  li.noOf
  .spinner
  .plus {
  padding: 5px 10px;
  border: 1px solid rgba(226, 226, 226, 0.77);
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}
.roundtrippg .mt-15 {
  margin-top: 15px;
}
.roundtrippg .modify-search .flight-details .sel-class .traveller-table .error-msg {
  box-shadow: none;
}
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.roundtrippg .ng-cloak,
.roundtrippg .x-ng-cloak,
.roundtrippg .ng-hide:not(.ng-hide-animate) {
  display: none !important;
}
.roundtrippg .modify-search
  .flight-details
  .sel-class
  .traveller-table
  .tab-container
  ul
  li.noOf:after {
  content: "";
  display: block;
  clear: both;
}
.roundtrippg .modify-search
  .flight-details
  .sel-class
  .traveller-table
  .tab-container
  ul
  li.noOf
  .cat-info {
  color: #999;
}
.roundtrippg .modify-search
  .flight-details
  .sel-class
  .traveller-table
  .tab-container
  ul
  li.noOf
  .spinner:after {
  content: "";
  display: block;
  clear: both;
}
.roundtrippg .modify-search
  .flight-details
  .sel-class
  .traveller-table
  .tab-container
  ul
  li.noOf:last-child {
  border-bottom: 0;
  padding: 20px 0 14px;
}
.roundtrippg .pt-20 {
  padding-top: 20px;
}
.roundtrippg .pb-16 {
  padding-bottom: 16px;
}
.roundtrippg .ml-5 {
  margin-left: 5px;
}
.roundtrippg .modify-search
  .flight-details
  .sel-class
  .traveller-table
  .tab-container
  ul
  li.noOf
  .cat-info {
  color: #999;
}
.roundtrippg .modify-search
  .flight-details
  .sel-class
  .traveller-table
  .tab-container
  ul
  li.noOf:last-child {
  border-bottom: 0;
  padding: 20px 0 14px;
}
.roundtrippg .mt-8 {
  margin-top: 8px;
}
.roundtrippg .modify-search .flight-details .sel-class .traveller-table .custom-radio label {
  opacity: 1;
  display: inline-block;
}
.roundtrippg .modify-search
  .flight-details
  .sel-class
  .traveller-table
  .custom-radio
  input[type="radio"] {
  top: 0;
  left: 0;
  visibility: hidden;
}
.roundtrippg .modify-search
  .flight-details
  .sel-class
  .traveller-table
  .custom-radio
  input[type="radio"]:checked
  ~ .radio-cs {
  border: 3px solid #fff;
  background: #f34f4f;
  box-shadow: 0 0 3px #f34f4f;
}
.roundtrippg .modify-search
  .flight-details
  .sel-class
  .traveller-table
  .custom-radio
  span:last-child {
  vertical-align: top;
  display: inline-block;
  color: #777;
}
.roundtrippg .modify-search
  .flight-details
  .sel-class
  .traveller-table
  .custom-radio
  input[type="radio"]:checked
  ~ span.text {
  color: #333;
}
.roundtrippg .modify-search
  .flight-details
  .sel-class
  .traveller-table
  .custom-radio
  .radio-cs {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: 1px solid rgba(226, 226, 226, 0.77);
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  margin-right: 5px;
}
.roundtrippg .tr {
  text-align: right;
}
.roundtrippg .mb-10 {
  margin-bottom: 10px;
}
.roundtrippg .modify-search .flight-details .sel-class .traveller-table .btn {
  background: #121157;
  color: #fff;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  display: inline-block;
  text-align: center;
  padding: 7px;
  /* border-color: #f34f4f; */
}
.roundtrippg .modify-search .flight-details:after {
  content: "";
  display: block;
  clear: both;
}
.roundtrippg .modify-search
  .flight-details
  .sel-class
  .traveller-table
  .custom-radio
  span:last-child {
  vertical-align: top;
  display: inline-block;
  color: #777;
}
.roundtrippg .text-right {
  text-align: right;
}
.roundtrippg .modify-search .sel-submit {
  padding: 15px 0;
}
.roundtrippg .modify-search .sel-submit label {
  display: none;
}
.roundtrippg .btn-submit {
  padding: 11px 26px 10px 22px;
  background-color: #fff;
  color: #000;
  border-radius: 4px;
  border: 0;
}
.roundtrippg .modify-search .sel-submit .btn-submit {
  box-shadow: 0 2px 4px 0 #4d264c;
  padding: 8px 22px 10px 22px;
  min-width: 137.45px;
  height: 38px;
}
.roundtrippg .modify-search .add-city {
  margin-left: 8.5%;
  padding-left: 0;
}
.roundtrippg .pb-14 {
  padding-bottom: 14px;
}
.roundtrippg .button {
  padding: 9px 26px 8px 22px;
  border-radius: 4px;
}
.roundtrippg .secondary-button {
  background-color: #fff;
  border: 2px solid #f34f4f;
  color: #f34f4f;
}
.roundtrippg .modify-search .add-city .secondary-button {
  background-color: transparent;
  color: #fff;
  opacity: 0.66;
  border-color: #fff;
}
.roundtrippg .pb-20 {
  padding-bottom: 20px;
}
.roundtrippg .grid > .col-12 {
  width: 100%;
}
.roundtrippg .grid > *:last-child {
  padding-right: 0;
}
.roundtrippg .modify-search ul.trip-type,
.roundtrippg .modify-search .input-filters {
  padding-left: 8.6%;
  color: #fff;
  letter-spacing: 0;
}
.roundtrippg .grid:after {
  content: "";
  display: block;
  clear: both;
}

/* stick bottom */
.roundtrippg .sticky_bottom {
  background: linear-gradient(to right, #25092f, #131b5c);
  border-top: 1px solid #fff;
  bottom: 0;
  color: #fff;
  position: fixed;
  width: 100%;
  z-index: 999;
}
.roundtrippg .sticky_bottom ul li {
  float: left;
}
.roundtrippg .sticky_bottom ul li.flight_txt img {
  display: inline-block;
  margin-right: 5px;
}
.roundtrippg .sticky_bottom ul li.flight_txt .flight_name {
  display: inline-block;
  color: #fff;
  vertical-align: top;
  word-break: break-word;
}
.roundtrippg .sticky_bottom ul li.flight_txt .flight_name span,
.roundtrippg .sticky_bottom ul li.flight_duration span {
  display: block;
}
.roundtrippg .sticky_bottom ul li.flight_duration > div {
  display: inline-block;
}
.roundtrippg .sticky_bottom ul li.flight_duration .cus_time {
  color: #fff;
  font-weight: bold;
  vertical-align: middle;
}
.roundtrippg .sticky_bottom ul li.flight_txt .flight_name span,
.roundtrippg .sticky_bottom ul li.flight_duration span {
  display: block;
}
.roundtrippg .fa-arrow-right:before {
  content: "\f061";
}
.roundtrippg .sticky_bottom ul li.flight_price {
  color: #fff;
  font-weight: bold;
  text-align: right;
}
.roundtrippg .sticky_bottom ul li.flight_price i {
  margin-right: 2px;
}
.roundtrippg .fa-rupee-sign:before {
  content: "\f156";
}
.roundtrippg .sticky_bottom .stk_grand_total li.grandtotal_txt {
  color: #fff;
  font-weight: normal;
}
.roundtrippg .sticky_bottom .stk_grand_total li.grandtotal_txt span {
  float: right;
  font-weight: bold;
}
.roundtrippg .sticky_bottom .stk_grand_total li.grandtotal_btn {
  text-align: right;
}
.roundtrippg .sticky_bottom .stk_grand_total li.grandtotal_btn button {
  background: #fff;
  color: #000;
  border: 0px;
  outline: none;
  box-shadow: none;
}
.roundtrippg .grandtotal_btn .btn {
  .btn {
    overflow: hidden;
    position: relative;
    z-index: 10;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 17px 35px;
    font-size: 14px;
    line-height: 1.8;
    border-radius: 3px;
    font-weight: 500;
    letter-spacing: 0.3px;
    text-align: center;
    border: 2px solid;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.05);
    font-family: "GothamRounded", "Almarai", sans-serif;
    transition: color 0.2s, background 0.2s, border-color 0.2s, box-shadow 0.2s,
      transform 0.15s;
  }
}

/* Flight details */
.roundtrippg .ovf-hidden {
  overflow: hidden;
}
.roundtrippg .table {
  display: table;
}
.roundtrippg .flight-details .header {
  padding-top: 6px;
  padding-left: 6px;
  background: linear-gradient(to right, #131b5c, #25092f);
}
.roundtrippg .fs-13 {
  font-size: 0.92857rem;
}
.roundtrippg .text-center {
  text-align: center;
}
.roundtrippg .table > * {
  display: table-cell;
}
.roundtrippg .flight-details .header .heading {
  padding: 7px 16px 13px;
  opacity: 0.9;
}
.roundtrippg .flight-details.full-details .header .heading {
  opacity: 1;
}
.roundtrippg .flight-details.full-details .header .heading.active:before {
  content: "";
  border: 7px solid #301b41;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid #301b41;
  border-bottom: 0;
  transform: translate(-50%, 100%);
  position: absolute;
  bottom: 0;
  left: 50%;
}
.roundtrippg .fs-22 {
  font-size: 22px;
}
.roundtrippg .pr-5 {
  padding-right: 5px;
}
.roundtrippg .ytfi-cancel:before {
  content: "";
}
.roundtrippg .clearfix:after {
  content: "";
  display: block;
  clear: both;
}
.roundtrippg .flight-details .details {
  width: 62.6%;
  background: #fff;
  border-bottom-left-radius: 6px;
}
.roundtrippg .flight-details.full-details .details {
  width: 100%;
}
.roundtrippg .white-bg {
  background: #fff;
}
.roundtrippg .flight-details .details .contents {
  color: #333;
  padding: 20px 0 10px;
  border-bottom-left-radius: 6px;
}
.roundtrippg .flight-details .details .contents .container,
.roundtrippg .flight-details .details .contents .disclaimer {
  padding-right: 3%;
  padding-left: 4.5%;
}
.roundtrippg .scroll-v {
  overflow-y: auto;
  overflow-x: hidden;
  –webkit-overflow-scrolling: touch;
}
.roundtrippg .flight-details .tab-content {
  max-height: 400px;
  padding-right: 1.5%;
}
.roundtrippg .flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -o-flex;
  display: flex;
}
.roundtrippg .flight-details .details .contents .airline-det {
  border-bottom: 2px solid #f2f2f2;
  padding: 6px 0px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -o-flex;
  display: flex;
  width: 100%;
  align-items: center;
}
.roundtrippg .flight-details .details .contents .airline-det .logo .ytfi-plane:before {
  content: "";
}
.roundtrippg .domAirLogo {
  width: 70px;
  height: 70px;
  background: transparent
    url("https://www.yatra.com/content/b2c-cdn/airline-logo-cdn/resources/images/DFL.png?version=202202031210")
    0 0 no-repeat;
  background-size: 700px 140px;
  float: left;
  image-rendering: optimizequality;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimize-contrast;
}
.roundtrippg .roundtrippg .search-page .air-logo {
  border-radius: 4px;
  background-color: #fff;
  box-sizing: content-box;
}
.roundtrippg .domAirLogo.size-28 {
  background-size: 280px 56px;
  width: 28px;
  height: 28px;
}
.roundtrippg .size-28.xIX {
  background-position: -0px -28px;
}
.roundtrippg .text-left {
  text-align: left;
}
.roundtrippg .pl-10 {
  padding-left: 10px;
}
/* .flight-details .details .contents .airline-det .airline {
  width: calc(72% - 30px);
} */
.roundtrippg .mb-2 {
  margin-bottom: 2px;
}
.roundtrippg .pull-left {
  float: left;
}
/* .flight-details .details .contents .airline-det .airline .name {
  max-width: 50%;
} */
.roundtrippg .mr-2 {
  margin-right: 2px;
}
.roundtrippg .font-lightestgrey {
  color: #999;
}
.roundtrippg .v-aligm-m {
  vertical-align: middle;
}
.roundtrippg .flight-details .details .contents .airline-det .amenties {
  width: 28%;
}
.roundtrippg .flight-details .details .contents .airline-det .amenties .amenety {
  padding-right: 5px;
}
.roundtrippg .tipsy {
  cursor: pointer;
  position: relative;
}
.roundtrippg .ytfi-paid-meal:before {
  content: "";
}
.roundtrippg .mr-5 {
  margin-right: 5px;
}
.roundtrippg .bottom-center {
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-name: tipsy-anim-bottom-center;
  -moz-animation-name: tipsy-anim-bottom-center;
  animation-name: tipsy-anim-bottom-center;
}
.roundtrippg .tipsy .tipsy-content {
  background: #000;
  opacity: 0.77;
  display: none;
  min-width: 150px;
  position: absolute;
  bottom: 100%;
  color: #fff;
  padding: 5px;
  border: 1px solid #cdcecf;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-box-shadow: 0 0 5px 1px #cdcecf;
  -webkit-box-shadow: 0 0 5px 1px #cdcecf;
  box-shadow: 0 0 5px 1px #cdcecf;
  left: 50%;
  transform: translate(-50%, -1em);
  z-index: 2;
}
.roundtrippg .tipsy .tipsy-content.bottom {
  transform-origin: 0% 100%;
  bottom: -1em;
}
.roundtrippg .tipsy .tipsy-content.bottom.bottom-center {
  transform: translate(-50%, 100%);
}
.roundtrippg .flight-details
  .details
  .contents
  .airline-det
  .amenties
  .amenety
  .tipsy-content {
  width: max-content;
}
.roundtrippg .flight-details
  .details
  .contents
  .airline-det
  .amenties
  .amenety.meal
  .tipsy-content {
  min-width: auto;
  max-width: 100px;
}
.roundtrippg .flight-details .details .contents .airline-det .amenties .amenety.disabled {
  color: #666;
  opacity: 0.25;
}
.roundtrippg .disabled > * {
  cursor: not-allowed;
  font-family: "Rubik-regular";
  color: #999;
}
.roundtrippg .ytfi-wifi:before {
  content: "";
}
.roundtrippg .flight-details .details .contents .schedule-det {
  padding: 15px 0;
}
.roundtrippg .pr-18 {
  padding-right: 18px !important;
}
.roundtrippg .flight-details .details .contents .schedule-det .city {
  width: 35%;
}
.roundtrippg .fs-13 {
  font-size: 13px !important;
}
.roundtrippg .no-wrap {
  white-space: nowrap;
}
.roundtrippg .mb-2 {
  margin-bottom: 2px !important;
}
.roundtrippg .fs-16 {
  font-size: 16px !important;
}
.roundtrippg .mb-5 {
  margin-bottom: 5px !important;
}
.roundtrippg .ml-2 {
  margin-left: 2px !important;
}
.roundtrippg .flight-details .details .contents .schedule-det .duration-stop {
  width: 26%;
}
.roundtrippg .mb-14 {
  margin-bottom: 14px !important;
}
.roundtrippg .ytfi-clock:before {
  content: "";
}
.roundtrippg .bdr-btm-grey {
  border-bottom: 2px solid #9c9c9c;
}
.roundtrippg .flight-details
  .details
  .contents
  .schedule-det
  .duration-stop
  .transport-icon {
  right: -7px;
  padding-left: 6px;
  top: 1px;
  transform: translateY(-50%);
}
.roundtrippg .ytfi-plane:before {
  content: "";
}
.roundtrippg .mt-10 {
  margin-top: 10px;
}
.roundtrippg .flight-details .details .contents .schedule-det .city {
  width: 35%;
}
.roundtrippg .flight-details .details .contents .schedule-det .city.arrive-det {
  padding-left: 12%;
}
.roundtrippg .flight-details .details .contents .amen-details {
  padding: 5px 15px;
  background: #f9f9f9;
  border-radius: 5px;
}
.roundtrippg .mb-15 {
  margin-bottom: 15px;
}
.roundtrippg .ytfi-bag:before {
  content: "";
}
.roundtrippg .flight-details .details .contents .amen-details .meal {
  margin-left: 20%;
}
.roundtrippg .ytfi-meal:before {
  content: "";
}
.roundtrippg .mb-8 {
  margin-bottom: 8px;
}
.roundtrippg .yellow-msg {
  background: #fffdd5;
  padding: 2px 5px;
  border-radius: 62px;
}
.roundtrippg .search-page .air-logo {
  border-radius: 4px;
  background-color: #fff;
  box-sizing: content-box;
}
.roundtrippg .size-28.xI5 {
  background-position: -28px -28px;
}
.roundtrippg .flight_detail_change_para {
  font-size: 12px;
}
.roundtrippg .mb-35 {
  margin-bottom: 35px;
}
.roundtrippg .flight-details .rules {
  padding: 0 5% 40px 5%;
}
.roundtrippg .search-page.domestic .round .flight-details .rules {
  padding-bottom: 0;
}
.roundtrippg .flight-details .rules .contents {
  padding: 20px 0;
}
.roundtrippg .search-page.domestic .round .flight-details .rules .contents {
  padding-bottom: 0;
}
.roundtrippg .component-bg {
  background: #f3f3f3;
}
.roundtrippg .flight-details .rules .contents .tooltip-header {
  display: none;
}
.roundtrippg .flight-details .rules .contents table {
  border-collapse: collapse;
}
.roundtrippg .flight-details .rules .contents .fare-summary-rules thead tr {
  font-family: "Rubik" "sens serif";
}
.roundtrippg .pb-5 {
  padding-bottom: 5px !important;
}
.roundtrippg .flight-details .rules .contents .fare-summary-rules tbody {
  color: rgba(255, 255, 255, 0.6);
}
.roundtrippg .flight-details .rules .contents table tbody tr td,
.roundtrippg .flight-details .rules .contents table tbody tr th {
  padding: 6px 0;
}
.roundtrippg .flight-details .rules .contents table tbody tr:last-child td {
  padding-bottom: 15px;
}
.roundtrippg .ytfi-rupee:before {
  content: "";
}
.roundtrippg .flight-details .rules .contents .fare-summary-rules .total {
  font-size: 0.9286rem;
}
.roundtrippg .flight-details .rules .contents .fare-summary-rules tfoot tr td {
  padding: 7px 0;
}
.roundtrippg .flight-details .rules .contents .fare-summary-rules .total td {
  padding: 8px 0;
  border-top: 1px solid #5a426d;
}
.roundtrippg .ml-7 {
  margin-left: 7px;
}
.roundtrippg .flight-details .rules .contents .color-light {
  color: rgba(255, 255, 255, 0.7);
}
.roundtrippg .flight-details .rules .contents .fare-summary-rules .upay {
  font-size: 1.14286rem;
}
.roundtrippg .flight-details .rules .contents .fare-summary-rules .upay td {
  padding: 15px 0;
  border-top: 1px solid #5a426d;
  border-bottom: 1px solid #5a426d;
}
.roundtrippg .flight-details .rules .disclaimer {
  opacity: 0.7;
}
.roundtrippg .flight-details .rules .contents .rules-table {
  margin-right: -10px;
}
.roundtrippg .flight-details .rules .contents .rules-table .container {
  padding-right: 10px;
  max-height: 435px;
}
.roundtrippg .mb-15 {
  margin-bottom: 15px;
}
.roundtrippg .flight-details .rules .contents .rules-table .container .sector-rules {
  border-bottom: 1px solid #5a426d;
}
.roundtrippg .flight-details .rules .contents .rules-table .container .sector-rules .head {
  border-bottom: 1px solid #5a426d;
}
.roundtrippg .flight-details .rules .contents table {
  border-collapse: collapse;
}
.roundtrippg .pb-9 {
  padding-bottom: 9px;
}
.roundtrippg .flight-details .rules .contents table tbody tr td,
.roundtrippg .flight-details .rules .contents table tbody tr th {
  padding: 6px 0;
}
.roundtrippg .flight-details .rules .contents table tbody tr.last td {
  padding-bottom: 15px;
}
.roundtrippg .flight-details .rules .contents table tbody tr.resrules {
  border-top: 1px solid #5a426d;
}
.roundtrippg .flight-details .rules .contents table tbody tr th {
  padding-top: 15px;
}
.roundtrippg .flight-details .rules .disclaimer {
  opacity: 0.7;
}
.roundtrippg .flight-details .rules .contents .rules-table .disclaimer {
  border-top: 1px solid #5a426d;
  padding-top: 15px;
  opacity: 0.7;
  white-space: normal;
}
.roundtrippg .flight-details .rules .contents .rules-table .disclaimer.highlight {
  background: #59406a;
  padding: 8px;
  border-radius: 5px;
  opacity: 1;
  border: 0;
}
.roundtrippg .flight-details
  .rules
  .contents
  .rules-table
  .rules-disc
  .disclaimer.highlight {
  padding: 8px;
}
.roundtrippg .flight-details .rules .contents table tbody.servicefee tr td {
  padding: 3px 0;
}
.roundtrippg .round_trip_not_found {
  font-size: 20px;
  margin-top: 200px;
  text-align: center;
}
@media (max-width: 480px) {
  .roundtrippg .pad_xs_10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .roundtrippg .roundtrip_header {
    padding: 3px;
  }
  .roundtrippg .flight_info ul li {
    font-size: 9px !important;
    line-height: 13px !important;
  }
  .roundtrippg .roundtrip_search .flight_info ul li {
    font-size: 10px;
    line-height: 14px;
  }
  .roundtrippg .flight_info ul li a i {
    font-size: 8px;
  }
  .roundtrippg .roundtrip_search .flight_info ul li a i {
    font-size: 8px;
  }
  .roundtrippg .custom_block_content {
    padding: 5px 5px;
  }
  .roundtrippg .roundtrip_search .custom_block_content {
    padding: 5px 2px;
  }
  .roundtrippg .custom_box_result ul.list-search-resultss li {
    font-size: 10px;
    line-height: 16px;
    padding: 0px;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li {
    padding: 0px 2px;
  }
  .roundtrippg .custom_box_result ul.list-search-resultss li:first-child {
    width: 100%;
    padding: 0px;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li {
    padding: 0px;
    margin: 0px;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss.result_list li {
    font-size: 10px;
    line-height: 14px;
  }
  .roundtrippg .custom_box_result ul.list-search-resultss li:nth-child(2),
  .roundtrippg .custom_box_result ul.list-search-resultss li:nth-child(4) {
    width: 40px;
    padding: 0px;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li.price {
    font-size: 10px;
    line-height: 14px;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li.price {
    font-size: 15px;
    line-height: 21px;
  }
  .roundtrippg .custom_box_result ul.list-search-resultss li.price .mainprice {
    display: block;
  }
  .roundtrippg .custom_box_result ul.list-search-resultss li:nth-child(3) {
    width: calc(50% - 90px);
    margin: 0px 5px;
  }
  .roundtrippg .roundtrip_search
    .custom_box_result
    ul.list-search-resultss
    li.round_check
    .checkbox-default
    .checkboxmark {
    height: 12px;
    width: 12px;
    flex: 0 0 12px;
  }
  .roundtrippg .roundtrip_search
    .custom_box_result
    ul.list-search-resultss
    li.round_check
    .checkbox-default
    .checkboxmark:after {
    left: 3px;
    top: 3px;
    width: 6px;
    height: 6px;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li:nth-child(5) {
    width: calc(100% - 80px);
    float: left;
    padding: 0px 4px;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li:nth-child(4),
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li:nth-child(6) {
    width: 40px;
    float: left;
  }
  .roundtrippg .custom_box_result ul.list-search-resultss li span.date {
    font-size: 11px;
    line-height: 16px;
  }
  .roundtrippg .roundtrip_search
    .custom_box_result
    ul.list-search-resultss.result_list
    li
    span.date {
    font-size: 9px;
    line-height: 12px;
  }
  .roundtrippg .roundtrip_search
    .custom_box_result
    ul.list-search-resultss
    li
    span.duration
    span {
    font-size: 9px;
  }
  .roundtrippg .roundtrip_search
    .custom_box_result
    ul.list-search-resultss
    li
    span.duration
    > span:before {
    width: 35px;
  }
  .roundtrippg .custom_box_result ul.list-search-resultss li:nth-child(6) {
    width: 65px;
  }
  .roundtrippg .roundtrip_search
    .custom_box_result
    ul.list-search-resultss.result_list
    li
    span.date {
    font-size: 9px;
    line-height: 12px;
  }
  .roundtrippg .roundtrip_search .flight_details a {
    font-size: 9px;
    line-height: 12px;
  }
  .roundtrippg .cus_col_6 {
    padding-right: 0px;
  }
  .roundtrippg .cus_col_622 {
    padding-left: 0px;
  }
  .roundtrippg .roundtrip_search .flight_info ul li {
    padding: 0px;
  }
  .roundtrippg .round_trip_not_found {
    font-size: 18px;
    margin-top: 50px;
    height: 40vh;
  }
}
@media (max-width: 600px) {
  .roundtrippg .cus_col_6 {
    padding-right: 0px;
  }
  .roundtrippg .roundtrip_header ul li {
    font-size: 10px;
    line-height: 14px;
  }
  .roundtrippg .roundtrip_header ul li:first-child {
    width: 48%;
  }
  .roundtrippg .roundtrip_header ul li:nth-child(2) {
    width: 48%;
  }
  .roundtrippg .roundtrip_header ul li:last-child {
    width: 100%;
  }
  .roundtrippg .custom_block_content {
    margin-top: 5px;
  }
  .roundtrippg .sticky_bottom .stk_grand_total li.grandtotal_txt span {
    margin-left: 5px;
  }
}
@media (max-width: 767px) {
  .roundtrippg .col_pull_6 {
    padding-right: 0px;
  }
  .roundtrippg .cus_col_622 {
    padding-left: 0px;
  }
  .roundtrippg .roundtrip_header ul li.date {
    font-size: 10px;
    line-height: 14px;
  }
  .roundtrippg .roundtrip_header ul li.prev_next_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 4px;
  }
  .roundtrippg .roundtrip_header ul li.prev_next_btn a {
    font-size: 8px;
    line-height: 13px;
  }
  .roundtrippg .roundtrip_header ul li.prev_next_btn span {
    height: 23px;
  }
  .roundtrippg .flight_info ul li {
    font-size: 12px;
    line-height: 16px;
  }
  .roundtrippg .roundtrip_search .flight_info ul li {
    padding: 4px 3px;
  }
  .roundtrippg .roundtrip_search .flight_info ul li {
    width: 33.33%;
  }
  .roundtrippg .roundtrip_search .flight_info ul li:nth-child(1),
  .roundtrippg .roundtrip_search .flight_info ul li:nth-child(4) {
    display: none;
  }
  .roundtrippg .round_trip_list {
    max-height: 600px;
    overflow: scroll;
  }
  .roundtrippg .round_trip_list .custom_box_result .flight_namess {
    font-size: 13px;
    line-height: 16px;
  }
  .roundtrippg .round_trip_list .custom_box_result .flight_namess span {
    font-size: 12px;
    line-height: 16px;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss {
    display: block;
    float: left;
    width: 100%;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li:nth-child(1) {
    width: 55%;
    float: left;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li:nth-child(2) {
    width: 40%;
    float: left;
    text-align: right;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li.round_check {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li:nth-child(4) {
    clear: both;
  }
  .roundtrippg .roundtrip_search
    .custom_box_result
    ul.list-search-resultss
    li
    span.duration
    > span:before {
    margin: 0px auto;
  }
  .roundtrippg .flight_details a {
    font-size: 11px;
    line-height: 15px;
  }
  .roundtrippg .round_trip_list {
    max-height: 600px;
    overflow: scroll;
  }
  .roundtrippg .grandtotal_btn button {
    margin-top: 5px;
    background: #fff;
    padding: 10px 20px;
    font-size: 14px;
    display: block;
    width: 100%;
    border: 0px;
    outline: none;
    box-shadow: none;
  }
  .roundtrippg .sticky_bottom > div {
    padding: 0px 10px;
  }
  .roundtrippg .sticky_bottom .col_mob_60 {
    width: 60%;
    float: left;
    padding: 0px;
    margin: auto;
  }
  .roundtrippg .sticky_bottom .stick_col_4 {
    width: 100%;
    padding: 0px;
  }
  .roundtrippg .sticky_bottom .stick_col_4.brder_rgt {
    padding: 0px;
    padding-right: 6px;
    margin-right: 6px;
    border-right: 1px solid #fff;
  }
  .roundtrippg .sticky_bottom .stick_col_4.brder_rgt {
    width: auto;
  }
  .roundtrippg .sticky_bottom .stk_btm_sec ul li {
    display: inline-block;
    float: none;
    margin-left: 7px;
  }
  .roundtrippg .sticky_bottom ul li.flight_txt {
    width: 40px;
    margin-right: 3px;
    display: inline-block;
    float: none;
    position: relative;
    height: 22px;
  }
  .roundtrippg .hide_mob {
    display: none !important;
  }
  .roundtrippg .sticky_bottom ul li.flight_txt .flight_name {
    visibility: hidden;
    width: 100%;
    font-size: 12px;
    line-height: 16px;
  }
  .roundtrippg .sticky_bottom ul li.flight_txt .flight_name > span {
    visibility: visible;
    position: absolute;
    top: 4px;
    width: max-content;
  }
  .roundtrippg .sticky_bottom ul li.flight_price {
    width: auto;
    display: inline-block;
  }
  .roundtrippg .sticky_bottom .col_mob_40 {
    width: 40%;
    float: left;
    padding: 0px;
    margin: auto;
  }
  .roundtrippg .sticky_bottom .stick_col_4.brder_rgt + .stick_col_4.brder_rgt {
    padding-right: 0px;
    margin-right: 0px;
    border-right: 0px;
  }
  .roundtrippg .flight-details .details .contents .amen-details .meal {
    margin-left: 2%;
  }
  .roundtrippg .round_trip_not_found {
    font-size: 18px;
    margin-top: 50px;
    height: 55vh;
  }
}
@media (max-width: 768px) {
  .roundtrippg .list-search-resultss > li + li {
    padding-top: 30px;
    margin-top: 30px;
  }
}
@media (max-width: 770px) {
  .roundtrippg .custom_sidebar {
    display: none;
  }
}
@media (max-width: 991px) {
  .roundtrippg .modify_search_sec .flight_search_info .desktop_flight_info,
  .roundtrippg .inner_hotel_detail .insurance_search_info .desktop_flight_info,
  .roundtrippg .modify_search_sec .flight_search_info .desktop_bus_info,
  .roundtrippg .modify_search_sec .flight_search_info .desktop_hotel_info,
  .roundtrippg .modify_search_sec .modify_btn button {
    display: none;
  }
  .roundtrippg .modify_search_sec .flight_search_info .cus_flight_info {
    width: calc(100% - 50px);
  }
  .roundtrippg .modify_search_sec .flight_search_info .cus_flight_info ul li,
  .roundtrippg .inner_hotel_detail .insurance_search_info .cus_flight_info ul li {
    display: inline-block;
    color: #000;
    vertical-align: top;
  }
  .roundtrippg .modify_search_sec .flight_search_info .cus_flight_info ul li a,
  .roundtrippg .inner_hotel_detail .insurance_search_info .cus_flight_info ul li a {
    color: #000;
  }
  .roundtrippg .custom_box_result ul.list-search-resultss li.pad_left10 {
    padding-left: 5px;
  }
  .roundtrippg .page-search .section-content {
    padding-top: 6px;
  }
}
@media (max-width: 992px) {
  .roundtrippg .box-result {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 481px) {
  .roundtrippg .roundtrip_header {
    padding: 8px;
  }
  .roundtrippg .roundtrip_header ul li.flight_namess,
  .roundtrippg .roundtrip_header ul li.date {
    padding-top: 7px;
  }
  .roundtrippg .custom_block_content {
    padding: 8px 10px;
  }
}
@media (min-width: 601px) {
  .roundtrippg .block-content-2 .custom_block_content {
    margin-top: 10px;
  }
  .roundtrippg .custom_box_result ul.list-search-resultss li {
    font-size: 13px;
    line-height: 21px;
    padding: 0px 10px;
  }
  .roundtrippg .cus_tooltip .tooltiptext {
    width: 100px;
    font-size: 12px;
    line-height: 21px;
    padding: 5px 10px;
  }
  .roundtrippg .sticky_bottom {
    padding-bottom: 15px;
    padding-top: 15px;
  }
}
@media (min-width: 768px) {
  .roundtrippg .modify_search_sec .modify_btn button,
  .roundtrippg .modify_search_sec .modify_search .close_flight a,
  .roundtrippg .modify_search_sec .flight_search_info .offer_style_btn a.offer_btn,
  .roundtrippg .insurance_sidebar form.insurance_form .modify_btn button {
    font-size: 13px;
    line-height: 16px;
  }
  .roundtrippg .modify_search_sec .modify_btn button,
  .roundtrippg .modify_search_sec .flight_search_info .offer_style_btn a.offer_btn,
  .roundtrippg .insurance_sidebar form.insurance_form .modify_btn button {
    padding: 12px 16px;
  }
  .roundtrippg .col_pull_6 {
    padding-left: 8px;
    padding-right: 5px;
  }
  .roundtrippg .roundtrip_header ul li {
    font-size: 14px;
    line-height: 18px;
  }
  .roundtrippg .roundtrip_header ul li.date {
    font-size: 12px;
    line-height: 16px;
  }
  .roundtrippg .roundtrip_header ul li.prev_next_btn a {
    font-size: 10px;
    line-height: 15px;
  }
  .roundtrippg .roundtrip_header ul li.prev_next_btn span {
    height: 30px;
  }
  .roundtrippg .round_trip_list .custom_box_result .flight_namess {
    font-size: 14px;
    line-height: 18px;
  }
  .roundtrippg .round_trip_list .custom_box_result .flight_namess span {
    font-size: 13px;
    line-height: 16px;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss {
    display: flex;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li {
    width: 19%;
    padding: 0px;
    display: inline-flex;
    flex-direction: column;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li:first-child {
    width: 40px;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li:nth-child(2) {
    /* width: 22%; */
    padding-right: 5px;
    order: 5;
  }

  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li.round_check {
    width: 20px;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li:nth-child(3) {
    order: 6;
  }
  .roundtrippg .roundtrip_search
    .custom_box_result
    ul.list-search-resultss
    li.round_check
    .checkbox-default
    .checkboxmark {
    height: 20px;
    width: 20px;
    flex: 0 0 20px;
  }
  .roundtrippg .roundtrip_search
    .custom_box_result
    ul.list-search-resultss
    li.round_check
    .checkbox-default
    .checkboxmark:after {
    left: 5px;
    top: 5px;
    width: 10px;
    height: 10px;
  }
  .roundtrippg .cus_tooltip {
    font-size: 13px;
    line-height: 18px;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li:nth-child(4),
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li:nth-child(6) {
    padding: 0px 5px;
  }
  .roundtrippg .flight_details a {
    font-size: 12px;
    line-height: 16px;
    margin-left: auto;
  }
  .roundtrippg .brder_rgt {
    border-right: 1px solid #fff;
  }
  .roundtrippg .sticky_bottom ul li.flight_txt {
    width: 33%;
  }
  .roundtrippg .sticky_bottom ul li.flight_duration {
    width: 47%;
  }
  .roundtrippg .sticky_bottom ul li.flight_price {
    width: 20%;
  }
  .roundtrippg .sticky_bottom .stk_grand_total li {
    width: 50%;
  }
  .roundtrippg .hide_desk {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .roundtrippg .modify_search_sec .flight_search_info,
  .roundtrippg .inner_hotel_detail .insurance_search_info {
    display: flex;
    justify-content: space-between;
  }
  .roundtrippg .modify_search_sec .flight_search_info .cus_flight_info ul li,
  .roundtrippg .inner_hotel_detail .insurance_search_info .cus_flight_info ul li {
    display: inline-block;
    font-size: 16px;
    line-height: 21px;
    padding-right: 12px;
    margin-right: 12px;
  }
  /* .modify_search_sec .flight_search_info .cus_flight_info ul li,
  .inner_hotel_detail .insurance_search_info .cus_flight_info ul li {
    display: inline-block;
    font-size: 16px;
    line-height: 21px;
    padding-right: 12px;
    margin-right: 12px;
  } */
  .roundtrippg .modify_search_sec .flight_search_info .mobile_flight_info,
  .roundtrippg .inner_hotel_detail .insurance_search_info .mobile_flight_info,
  .roundtrippg .modify_search_sec .flight_search_info .mobile_bus_info,
  .roundtrippg .modify_search_sec .flight_search_info .mobile_hotel_info {
    display: none;
  }
  .roundtrippg .btn_hide_lg {
    display: none;
  }
  .roundtrippg .custom_box_result ul.list-search-resultss li.pad_left10 {
    padding-left: 10px;
  }
  .roundtrippg .sticky_bottom ul li.flight_txt img {
    width: 30px;
  }
  .roundtrippg .sticky_bottom ul li.flight_txt .flight_name {
    font-size: 12px;
    line-height: 16px;
    width: calc(100px - 30px);
  }
}
@media (min-width: 1200px) {
  .roundtrippg .roundtrip_header ul li:first-child {
    width: 38%;
  }
  .roundtrippg .roundtrip_header ul li:nth-child(2) {
    width: 26%;
  }
  .roundtrippg .roundtrip_header ul li:last-child {
    width: 34%;
  }
  .roundtrippg .roundtrip_search .flight_info ul li {
    padding: 8px 3px 5px;
    font-size: 13px;
    line-height: 18px;
    width: 19%;
  }
  .roundtrippg .roundtrip_search .flight_info ul li:last-child {
    width: 24%;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li.price {
    font-size: 16px;
    line-height: 21px;
  }
  .roundtrippg .roundtrip_search
    .custom_box_result
    ul.list-search-resultss
    li
    span.duration
    > span:before {
    width: 75px;
  }
  .roundtrippg .roundtrip_search
    .custom_box_result
    ul.list-search-resultss.result_list
    li
    span.date {
    font-size: 16px;
    line-height: 21px;
  }
  .roundtrippg .sticky_bottom ul li.flight_duration .cus_time {
    font-size: 14px;
    line-height: 18px;
  }
  .roundtrippg .sticky_bottom ul li.flight_duration .arrow {
    margin: 0px 20px;
  }
  .roundtrippg .sticky_bottom ul li.flight_duration .arrow i {
    font-size: 21px;
  }
  .roundtrippg .sticky_bottom ul li.flight_price {
    font-size: 18px;
    line-height: 21px;
  }
  .roundtrippg .sticky_bottom .stk_grand_total li.grandtotal_txt {
    font-size: 18px;
    line-height: 21px;
  }
  .roundtrippg .sticky_bottom .stk_grand_total li.grandtotal_btn button {
    font-size: 14px;
    line-height: 18px;
    padding: 10px 15px;
  }
}
@media (min-width: 601px) and (max-width: 767px) {
  .roundtrippg .roundtrip_header ul li {
    font-size: 12px;
    line-height: 16px;
  }
  .roundtrippg .roundtrip_header ul li:first-child {
    width: 28%;
  }

  .roundtrippg .roundtrip_header ul li:nth-child(2) {
    width: 30%;
  }
  .roundtrippg .roundtrip_header ul li:last-child {
    width: 40%;
  }
  .roundtrippg .roundtrip_search
    .custom_box_result
    ul.list-search-resultss.result_list
    li
    span.date {
    font-size: 12px;
    line-height: 16px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .roundtrippg .roundtrip_search .flight_info ul li {
    font-size: 11px;
    line-height: 15px;
  }
  .roundtrippg .roundtrip_search .custom_block_content {
    padding: 5px;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li {
    padding: 0px !important;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss.result_list li {
    font-size: 13px;
    line-height: 16px;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li.price {
    font-size: 16px;
    line-height: 21px;
  }
  .roundtrippg .roundtrip_search
    .custom_box_result
    ul.list-search-resultss
    li.round_check
    .checkbox-default
    .checkboxmark {
    height: 16px;
    width: 16px;
    flex: 0 0 16px;
  }
  .roundtrippg .roundtrip_search
    .custom_box_result
    ul.list-search-resultss
    li.round_check
    .checkbox-default
    .checkboxmark:after {
    left: 4px;
    top: 4px;
    width: 8px;
    height: 8px;
  }
  .roundtrippg .cus_tooltip {
    font-size: 12px;
    line-height: 16px;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li:nth-child(4),
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li:nth-child(6) {
    width: 60px;
    float: left;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li:nth-child(5) {
    width: calc(100% - 120px);
    float: left;
    padding: 0px 4px;
  }
  .roundtrippg .sticky_bottom ul li.flight_txt img {
    width: 25px;
  }
  .roundtrippg .sticky_bottom ul li.flight_duration .cus_time {
    font-size: 12px;
    line-height: 16px;
  }
  .roundtrippg .sticky_bottom ul li.flight_duration .arrow {
    margin: 0px 5px;
  }
  .roundtrippg .sticky_bottom ul li.flight_duration .arrow i {
    font-size: 16px;
  }
  .roundtrippg .sticky_bottom ul li.flight_price {
    font-size: 14px;
    line-height: 18px;
  }
  .roundtrippg .sticky_bottom .stk_grand_total li.grandtotal_txt {
    font-size: 16px;
    line-height: 21px;
    margin-top: 5px;
  }
  .roundtrippg .sticky_bottom .stk_grand_total li.grandtotal_txt > span {
    margin-left: 5px;
  }
  .roundtrippg .sticky_bottom .stk_grand_total li.grandtotal_btn button {
    font-size: 12px;
    line-height: 16px;
    padding: 7px 10px;
  }
}
@media (min-width: 481px) and (max-width: 1199px) {
  .roundtrippg .roundtrip_search
    .custom_box_result
    ul.list-search-resultss
    li
    span.duration
    > span:before {
    width: 55px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .roundtrippg .modify_search_sec .flight_search_info .cus_flight_info ul li.back,
  .roundtrippg .inner_hotel_detail .insurance_search_info .cus_flight_info ul li.back {
    margin-right: 15px;
  }
  .roundtrippg .modify_search_sec
    .flight_search_info
    .cus_flight_info
    ul
    li
    span.flight_namess,
    .roundtrippg .inner_hotel_detail
    .insurance_search_info
    .cus_flight_info
    ul
    li
    span.flight_namess {
    font-size: 15px;
    line-height: 21px;
    display: block;
  }
  .roundtrippg .modify_search_sec
    .flight_search_info
    .cus_flight_info
    ul
    li
    span.flight_time,
    .roundtrippg .inner_hotel_detail
    .insurance_search_info
    .cus_flight_info
    ul
    li
    span.flight_time {
    font-size: 12px;
    line-height: 16px;
  }
  .roundtrippg .roundtrip_search .flight_info ul li {
    padding: 6px 5px 5px;
  }
  .roundtrippg .roundtrip_search .flight_info ul li {
    padding: 8px 3px 5px;
    font-size: 12px;
    line-height: 16px;
    width: 19%;
  }
  .roundtrippg .roundtrip_search .flight_info ul li:last-child {
    width: 24%;
  }
  .roundtrippg .stick_col_4 {
    padding: 0px 5px;
  }
  .roundtrippg .sticky_bottom ul li.flight_txt img {
    width: 25px;
  }
  .roundtrippg .sticky_bottom ul li.flight_txt .flight_name {
    width: calc(85px - 25px);
    font-size: 10px;
    line-height: 14px;
  }
  .roundtrippg .sticky_bottom ul li.flight_duration .cus_time {
    font-size: 12px;
    line-height: 16px;
  }
  .roundtrippg .sticky_bottom ul li.flight_duration .arrow {
    margin: 0px 5px;
  }
  .roundtrippg .sticky_bottom ul li.flight_duration .arrow i {
    font-size: 16px;
  }
  .roundtrippg .sticky_bottom ul li.flight_price {
    font-size: 12px;
    line-height: 16px;
  }
  .roundtrippg .sticky_bottom .stk_grand_total li.grandtotal_txt {
    font-size: 13px;
    line-height: 18px;
  }
  .roundtrippg .sticky_bottom .stk_grand_total li.grandtotal_btn button {
    font-size: 12px;
    line-height: 16px;
    padding: 7px 10px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .roundtrippg .roundtrip_header ul li:first-child {
    width: 32%;
  }
  .roundtrippg .roundtrip_header ul li:nth-child(2) {
    width: 28%;
  }
  .roundtrippg .roundtrip_header ul li:last-child {
    width: 38%;
  }
  .roundtrippg .roundtrip_search .custom_box_result ul.list-search-resultss li.price {
    font-size: 15px;
    line-height: 21px;
  }
  .roundtrippg .roundtrip_search
    .custom_box_result
    ul.list-search-resultss.result_list
    li
    span.date {
    font-size: 14px;
    line-height: 18px;
  }
}
@media only screen and (max-width: 1024px) {
  .roundtrippg .search-page * {
    cursor: pointer;
  }
  .roundtrippg .search-page .flight-details .details .contents .airline-det .airline .name {
    max-width: 33%;
  }
  .roundtrippg .search-page .tipsy-content {
    animation: none !important;
  }
  .roundtrippg .search-page
    .flight-details
    .details
    .contents
    .amenties
    .amenety
    .tipsy-content {
    width: auto;
  }
  .roundtrippg .search-page.domestic
    .flight-details
    .details
    .contents
    .amenties
    .amenety
    .tipsy-content {
    width: max-content;
  }
}
@media (max-width: 522px) {
  .roundtrippg .flight-details .header {
    display: flex;

    overflow-x: scroll;
    width: auto;
  }
  .roundtrippg .flight-details .header .fs-13 {
    font-size: 8px !important;
  }
  .roundtrippg .flight-details .header .heading {
    padding: 7px;
  }
  .roundtrippg .fs-13 {
    font-size: 11px !important;
  }
  .roundtrippg .fs-12 {
    font-size: 11px !important;
  }
  .roundtrippg .flight-details .details .contents {
    padding: 10px 0 25px;
  }
  .roundtrippg .fs-11 {
    font-size: 9px !important;
  }
  .roundtrippg .fs-16 {
    font-size: 12px !important;
  }
  .roundtrippg .mb-14 {
    margin-bottom: 0px !important;
  }
  .roundtrippg .flight-details .details .contents .schedule-det .duration-stop {
    margin-top: 10px;
  }
  .roundtrippg .flight_detail_change_para {
    font-size: 9px;
  }
}

@media (max-width: 920px) {
  /*  li:nth-child(4) */
  .roundtrippg .modify-search .flight-details .sel-origin,
  .roundtrippg .modify-search .flight-details .sel-dest {
    width: 12%;
  }
  .roundtrippg .modify-search .flight-details .sel-origin {
    margin-right: 5%;
  }
  .roundtrippg .modify-search .flight-details .sel-dest {
    /* margin-left: 60px; */
  }
  .roundtrippg .modify-search .flight-details .sel-date {
    width: 23%;
  }
  .roundtrippg .modify-search .flight-details .sel-class .ytfi-angle-down,
  .roundtrippg .modify-search .flight-details .sel-class .ytfi-angle-up {
    right: -5px;
  }
}
@media (max-width: 480px) {
  .roundtrippg .wr-width {
    width: 100%;
    padding: 0px 9px;
  }
  .roundtrippg .grid > .col-10 {
    width: 100%;
  }
  .roundtrippg .modify-search .sel-submit .btn-submit {
    /* display: none; */
  }
  .roundtrippg .modify-search .flight-details .sel-origin,
  .roundtrippg .modify-search .flight-details .sel-dest {
    width: 30%;
  }
  .roundtrippg .modify-search .flight-details .sel-origin {
    margin-right: 17%;
  }
  .roundtrippg .modify-search .flight-details .sel-date .depart {
    width: 37%;
  }
  .roundtrippg .modify-search .flight-details .sel-date {
    width: 30%;
    margin-left: 20px;
  }
  .roundtrippg .modify-search .flight-details .sel-class {
    width: 31%;
    margin-left: 12%;
  }
  .roundtrippg .modify-search .flight-details .sel-date .hyphen {
    margin-right: 0px;
  }
}

/* @media (max-width: 426px) {
  .modify-search .sel-submit .btn-submit{
    display: none;
  }
  .wr-width {
    width: 100%;
    padding: 0px 9px;
  }
  .modify-search .flight-details .sel-origin {
    margin-right: 13%;
  }
} */
