/* .table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: none;
}
.bookingTicketFlightTable {
  border-inline: 1px solid #002543;
}
.bookingTicketFlightTable1 {
  border-inline: 1px solid #17194e;
}
.BookingTableTable tbody,
td,
tfoot,
th,
thead,
tr {
  border-bottom: 1px solid #17194e;
}
.BookingConfirmBookingHead tbody,
td,
tfoot,
th,
thead,
tr {
  border-bottom: 1px solid #002543;
}
.BookingTableTable {
  width: 90%;
  margin: auto;
}
.BookingTicketTableMainHead {
  border: 1px solid #002543 !important;
}
.BookingTicketTableHead {
  padding: 10px !important;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  background-color: #002543;
  color: white;
}

.BookingTicketTableBodyHead {
  padding: 10px !important;
  font-size: 16px;
  font-weight: 600;
}
.BookingTicketTableBodyHeadInner {
  padding: 10px !important;
  font-size: 16px;
  font-weight: 500;
} */

.BookingTicketBoldHeading {
  margin-top: 5px;
  font-size: 20px;
  color: #002543;
  font-weight: 600;
}

.BookingTicketBoldHeading2 {
  margin-top: 10px !important   ;
  font-size: 15px;
  color: black;
  font-weight: 600;
}

.BookingConfirmBookingHead {
  color: black;
  margin-bottom: 25px;
}
.BookingConfirmBookingHead p {
  padding: 0px;
  margin: 0px;
  font-size: 1.2rem;
}

.BookingTicketTableHead1 {
  padding: 10px !important;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  background-color: #002543;
  color: white;
}
.bookingTicketTableBodyHead1 {
  display: flex;
  gap: 10px;
  padding: 10px;
}
.bookingConfirmationPara {
  padding: 0px !important;
  margin: 0px !important;
  font-size: 13px;
  font-weight: 600;
}
.bookingConfirmationPara1 {
  font-size: 14px;
  font-weight: 700;
}
.bookingConfirmationPara2 {
  font-size: 14px;
  font-weight: 500;
}
/* .bookingConfirmatiionTableDesc {
  padding: 10px;
} */

.flightBookingHeaderDiv {
  display: flex;
  /* gap: 43px; */
  background-color: #002543;
  /* justify-content: center; */
  justify-content: space-around;
  margin: 10px 0px 10px 0px;
  flex-wrap: nowrap;
  overflow: auto;
  padding: 10px;
}

.flightBookingUpperIcon {
  padding: 3px 15px;
  background-color: #fff;
  font-size: 15px;
  color: #000;
  display: flex;
  gap: 5px;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}
.flightBookingTopHeader {
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin: auto;
}
@media (max-width: 768px) {
  .flightBookingHeaderDiv {
    gap: 10px;
    /* justify-content: start; */
  }
  .BookingTicketBoldHeading2 {
    font-size: 13px;
  }
}

.fareinfo .BookingTicketTableBodyHeadInner {
  text-wrap: nowrap;
}

.maindiv {
  width: 1250px;
  height: max-content;
  margin: 0 auto;
}
.top-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px dashed #dedede;
  border-color: transparent transparent #dedede transparent;
  padding: 0 4rem;
}
/* .top-part .logo {
  width: 200px;
  height: 70px;
} */
.ticket-details .details {
  text-align: right;
}
.ticket-details .details h4 {
  color: #000;
  font-weight: 700;
  font-size: 1.4rem;
}
.ticket-details .details p {
  font-size: 1.2rem;
  margin-bottom: 0.5rem !important;
}
.ticket-details .details p span {
  font-weight: 700;
}
.passenger-details {
  padding: 1rem 0;
}
.passenger-details h4 {
  font-size: 1.4rem;
  padding: 0 3.4rem;
  color: #000;
  font-weight: 700;
}
.passenger-details table {
  font-size: 1.2rem;
  width: 90%;
  margin: 0 auto;
}
.flight-details {
  padding: 1rem 0;
}
.flight-details h4 {
  font-size: 1.4rem;
  padding: 0 3.4rem;
  color: #000;
  font-weight: 700;
}
.flight-details table {
  font-size: 1.2rem;
  width: 90%;
  margin: 0 auto;
}

.baggage-flight {
  padding: 1rem 0;
}
.baggage-flight h4 {
  font-size: 1.4rem;
  padding: 0 3.4rem;
  color: #000;
  font-weight: 700;
}
.baggage-flight table {
  font-size: 1.2rem;
  width: 90%;
  margin: 0 auto;
}
.personal-details {
  padding: 1rem 0;
}
.personal-details h4 {
  font-size: 1.4rem;
  padding: 0 3.4rem;
  color: #000;
  font-weight: 700;
}
.personal-details table {
  font-size: 1.2rem;
  width: 90%;
  margin: 0 auto;
}

.information-details {
  padding: 1.4rem 0;
}
.information-details h5 {
  font-size: 1.4rem;
  padding: 0 3.4rem;
  color: #000;
  font-weight: 700;
}
.information-details ul {
  font-size: 1.2rem;
  width: 90%;
  margin: 0 auto;
}
