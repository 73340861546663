.benefits-one {
  position: relative;
  display: block;
  background-color: #f8f7f1;
  padding: 80px 0;
  z-index: 1;
}
.float-bob-x {
  -webkit-animation-name: float-bob-x;
  animation-name: float-bob-x;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
.benefits-one__shape-1 {
  position: absolute;
  bottom: 0;
  left: 95px;
  z-index: -1;
}
.benefits-one__shape-1 img {
  width: auto;
}

.float-bob-y {
  -webkit-animation-name: float-bob-y;
  animation-name: float-bob-y;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
.benefits-one__shape-2 {
  position: absolute;
  top: 180px;
  right: 120px;
  z-index: -1;
}
.benefits-one__shape-2 img {
  width: auto;
}
.benefits-one__left {
  position: relative;
  display: block;
}
.section-title {
  position: relative;
  display: block;
  margin-top: -9px;
  margin-bottom: 47px;
  z-index: 3;
}
.benefits-one .section-title {
  margin-bottom: 28px;
}
.section-title__taglineeee {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 16px;
  color: #8f10b2;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  z-index: 1;
}
.section-title__title {
  margin: 0;
  color: #161845;
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  letter-spacing: -0.04em;
  margin-top: 5px;
}
.benefits-one__text {
  padding-bottom: 40px;
}
.benefits-one__right {
  position: relative;
  display: block;
  margin-left: 10px;
}
.benefits-one__single {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 21px 15px 22px;
  background: linear-gradient(162deg, rgb(44 100 185 / 20%) 40%, rgb(248 165 0 / 21%) 100%);
  /* background-color: #ffefd066; */
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
  margin-bottom: 20px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}
.benefits-one__single:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: #161845;
  transform: scaleX(0.7) rotateX(20deg);
  opacity: 0;
  transition: all 0.4s linear;
  z-index: -1;
}
.benefits-one__content {
  position: relative;
  display: flex;
  align-items: center;
}
.benefits-one__icon {
  position: relative;
  display: inline-block;
  margin-right: 18px;
  top: 5px;
}
.benefits-one__icon span {
  font-size: 50px;
  color: #8f10b2;
}
.icon-travel-insurance-1:before {
  content: "\e904";
}
.benefits-one__title {
  position: relative;
  display: block;
}
.benefits-one__title h3 {
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
}
.benefits-one__title a {
  color: #184096;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.benefits-one__arrow {
  position: relative;
  display: block;
}
.benefits-one__arrow a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #f8f7f1;
  border-radius: 50%;
  font-size: 14px;
  color: #161845;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.benefits-one__single:hover:before {
  transform: scaleX(1) rotateX(0deg);
  transition: all 0.4s linear;
  opacity: 1;
}

.benefits-one__single:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background-color: #f8a500;
  color: #fff;
  transform: scaleX(0.7) rotateX(20deg);
  opacity: 0;
  transition: all 0.4s linear;
  z-index: -1;
}
.benefits-one__single:hover .benefits-one__title a {
  color: #fff;
}
.section-title__titlell {
  color: #f8a500;
  font-weight: 700;
  margin: 5px 0px;
  font-size: 32px;
  line-height: 45px;
  letter-spacing: -0.02em;
}

.benefits-one__btn {
  background: linear-gradient(20deg, rgb(27 52 140) 28%, rgb(248 165 0) 100%);
  color: white;
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 5px;
  border: none;
}
