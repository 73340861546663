.silk-image {
  /* height: 300px; */
  /* width: 600px; */
  width: 100%;
  object-fit: inherit;
  height: 170px;
  border-radius: 10px;
  object-position: left top;
  vertical-align: middle;
  border-style: none;
}

.silk-container {
  /* border: 0.1px solid rgba(0, 0, 0, 0.083); */
  border-radius: 10px;
  /* padding: 10px; */
  /* margin: 2px; */
  box-sizing: border-box;
}

.silk-name {
  padding: 6px 10px;
  font-size: 18px;
  font-weight: 600;
}
.awssld {
  height: 150px;
  padding: 10px 24px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}
.offers-head2 {
  font-size: 16px;
  /* padding: 20px; */
  margin-bottom: 20px;
  font-weight: 600;
  color: #1a1a1a;
}
.offers-paraa {
  font-size: 14px;
  width: 80%;
  font-weight: 500;
  color: #1a1a1a;
}
.offers-paraa2 {
  font-size: 14px;
  margin-bottom: 10px !important;
}
.offers-btn-know {
  background-color: transparent;
  border: none;
  color: #36c;

  font-size: 14px;
}

.listItemHover:hover {
  background-color: #eff5fc;
  border-radius: 6px;
}
.p-relative {
  position: relative;
}
.mr-4,
.mx-4 {
  margin-right: 16px;
}
.ml-4 {
  margin-left: 16px;
}
.mb-4 {
  margin-bottom: 16px;
}
.mt-4 {
  margin-top: 16px;
}
.listItemHover div a{
  color: #1a1a1a;
  font-weight: 500;
  line-height: 20px;
  font-size: 16px;
  text-decoration: none;
}
.fs-3, .fs-4 {
  line-height: 20px;
}.listItemHover .fs-3 {
  font-size: 14px !important;
}.fw-600 {
  font-weight: 600;
}.p-absolute {
  position: absolute;
}

.banner-again-div{
  width: 100%;
  margin-top: 15px;
  height: 100px;
}
.banner-again-div img{
  width: 100%;
  height: 150px;
  border-radius: 5px;
}