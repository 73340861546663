.popular-tours__container,
.popular-tours__img,
.popular-tours__single {
  position: relative;
  display: block;
}
.popular-tours__img,
.popular-tours__img img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.popular-tours__img {
  overflow: hidden;
}
.popular-tours__img img {
  width: 100%;
  opacity: 1;
  transform: scale(1);
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.popular-tours__icon a {
  width: 38px;
  height: 38px;
  background-color: rgba(49, 48, 65, 0.2);
  border-radius: 50%;
  text-align: center;
  display: flex;
  font-size: 14px;
  color: #fff;
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 10;
  transition: all 0.5s ease;
  align-items: center;
  justify-content: center;
  line-height: 38px;
}
@media only screen and (min-width: 1200px) and (max-width: 1590px) {
  .popular-tours .popular-tours__content {
    padding: 35px 15px 40px;
  }
}
.popular-tours__content {
  position: relative;
  display: block;
  border: 1px solid #ebe6de;
  border-top: 0;
  padding: 35px 40px 40px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
/* .popular-tours__stars {
    font-size: 13px;
    font-weight: 500;
    color: #787780;
    display: flex;
    align-items: center;
    line-height: 23px;
    letter-spacing: -.02em;
} */
@media only screen and (min-width: 1200px) and (max-width: 1590px) {
  .popular-tours .popular-tours__title {
    font-size: 20px;
    line-height: 30px;
  }
}

.popular-tours__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.02em;
  margin-top: 4px;
  margin-bottom: 7px;
}
.popular-tours__title a {
  color: #313041;
  transition: all 0.5s ease;
}
.popular-tours__rate {
  font-size: 15px;
  margin: 0;
  letter-spacing: -0.02em;
}
.popular-tours__rate span {
  font-size: 20px;
  font-weight: 700;
  color: #002543;
}
@media only screen and (min-width: 1200px) and (max-width: 1590px) {
  .popular-tours .popular-tours__meta {
    justify-content: center;
  }
}
.popular-tours__meta {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #faf5ee;
  border-radius: 8px;
  padding: 0 28px;
  margin-top: 19px;
}
.popular-tours__meta li a {
  font-size: 12px;
  color: #313041;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: all 0.5s ease;
}
@media (max-width: 426px) {
  .popular-tours__container,
  .popular-tours__img,
  .popular-tours__single {
    margin-left: 0px;
    margin-right: 0px;
  }
  .tourBannerSearchHeadInput {
    width: 65% !important;
  }
  .destinations-one {
    padding: 65px 0px 0px;
  }
}
