.recent-bookings-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 10px 0 5px 0;
  }
  
  .flight-bookings,
  .hotel-bookings {
    background-color: #ffffff3b;
    padding: 5px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .booking-card {
    background-color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .booking-card .card-body {
    text-align: center;
  }

  .recent-bookings-container p{
    margin-bottom: 0;
    text-align: left;
  }