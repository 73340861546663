.travelagent:not(.forgotpasswordmodal .travelagent) {
  /* background-image: linear-gradient(to right, rgb(6 9 60), #2d32909e),
    url(https://heritage.travelsdata.com/public/images/agent-login.jpg); */
    background: #e0eaf5;
  padding: 67px 0px 50px;
  background-size: cover;
  background-position: center;
}

@media (min-width: 1366px) {
  .container {
    max-width: 1200px;
  }
}

.flex_wrap {
  flex-wrap: wrap;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.text-white {
  color: #fff;
}

.btn-primary {
  background: #f8a500;
  border-color: #f8a500;
  padding: 13px 30px;
  font-size: 16px;
}

@media (min-width: 768px) {
  .travelagent .floatrgt_lg {
    float: right;
  }
}

.travelagent .agent_form {
  /* margin-top: 30px !important; */
  padding: 20px;
  margin: auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 82%;
}

.input {
  padding: 0 15px;
  width: auto;
  font-weight: 400;
  outline: none;
  box-shadow: none;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: all 0.2s;
  background-color: #fff;
  color: #222;
}

.input-group {
  position: relative;
  /* display: table; */
  border-collapse: separate;
}

.travelagent .agent_form h1 {
  font-size: 24px;
  line-height: 38px;
  margin: 0px 0 -18px;
  color: #f8a500;
  text-align: center;
  font-weight: 600;
}

.form-group {
  position: relative;
}

.inner_form_field .radio label {
  color: #191818;
  border: 1px dashed #f8a500;
  padding: 10px 31px;
  border-radius: 50px;
  display: block;
  text-align: center;
  background: #fff;
  visibility: hidden;
  height: 0;
}

.inner_form_field .radio label {
  color: #191818;
  border: 1px dashed #f8a500;
  padding: 10px 31px;
  border-radius: 50px;
  display: block;
  text-align: center;
  background: #fff;
  visibility: hidden;
  height: 0;
}

.travelagent .agent_form .input-group span.input-group-addon {
  border-radius: 50px 0px 0px 50px;
  background: #f8a500;
  color: #ffffff;
  border-color: #f8a500;
  padding: 15px 22px;
}

.fas {
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
}

.fas {
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.travelagent .agent_form .input-group input {
  border-radius: 0px 50px 50px 0px !important;
  border-color: #f8a500;
  font-size: 14px;
  line-height: 18px;
  color: #f8a500;
  height: auto;
  padding: 15px 10px;
}

.travelagent .agent_form .form-group .input-group .pass_eyeswitch {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 9;
}

.checkbox {
  display: inline-block;
  margin: 0px;
}

.checkbox .label-container.checkbox-default {
  padding: 0px;
  font-size: 14px;
  line-height: 21px;
  color: #000;
}

.label-container.checkbox-default input {
  /* position: absolute; */
  /* opacity: 0; */
  cursor: pointer;
  height: 12px;
  width: 12px;
}

.label-container.checkbox-default .checkmark {
  margin-right: 10px;
  border-radius: 5px;
  box-shadow: inset 0 0 0 2px #ddd;
  vertical-align: text-bottom;
  margin-right: 5px;
}

.travelagent .agent_form .form_btn input {
  color: #fff;
  background: #f8a500;
  font-size: 17px;
  line-height: 21px;
  font-weight: 600;
  display: block;
  border-radius: 50px;
  width: 100%;
  border: 0px;
  padding: 15px 10px;
  text-transform: uppercase;
  height: auto;
}

.travelagent .agent_form .cus_txt {
  text-align: center;
  margin: 20px 0px;
}

.travelagent .agent_form .cus_txt p {
  font-size: 14px;
  line-height: 18px;
  color: #000;
  font-weight: normal;
  margin: 0px 0 10px;
}

.travelagent .agent_form .cus_txt p a {
  color: #f8a500;
  text-decoration: none;
}

.forgotpasswordmodal {
  z-index: 999999;
}

.forgotpasswordmodal .modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.forgotpasswordmodal .modal-header .modal-title {
  font-size: 19px;
  margin-bottom: 0;
}

/* .forgotpasswordmodal .btn-close {
    float: none;
    opacity: 1;
    margin: 0;
  }
  
  .modal-header .btn-close {
    background: #0c6ad3;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    color: #fff;
    font-size: 15px;
    opacity: 1;
  } */

.travelagent .agent_form {
  padding: 20px;
  margin: auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 82%;
}

.forgotpasswordmodal .agent_form {
  width: 90%;
  box-shadow: none;
  padding: 0;
  margin-block: 5px !important;
}

.forgotpasswordmodal .input-group {
  margin-bottom: 25px;
}

.travelagent .agent_form .input-group span.input-group-addon {
  border-radius: 50px 0px 0px 50px;
  background: #f8a500;
  color: #ffffff;
  border-color: #f8a500;
  padding: 15px 22px;
}

.travelagent .agent_form .input-group input {
  border-radius: 0px 50px 50px 0px !important;
  border-color: #f8a500;
  font-size: 14px;
  line-height: 18px;
  color: #f8a500;
  height: auto;
  padding: 15px 10px;
}

.forgotpasswordmodal .form_btn {
  width: 50%;
  margin: 0 auto;
}

.travelagent .agent_form .form_btn .btn {
  color: #fff;
  background: #f8a500;
  font-size: 17px;
  line-height: 21px;
  font-weight: 600;
  display: block;
  border-radius: 50px;
  width: 100%;
  border: 0px;
  padding: 15px 10px;
  text-transform: uppercase;
  height: auto;
}

/* .modal-header{
    padding: 10px;
  } */

.modal-dialog {
  margin-top: 100px !important;
  /* max-width: 40% !important; */
}

@media (max-width: 768px) {
  .floatrgt_lg {
    padding-inline: 5px;
  }
  .travelagent .agent_form {
    width: 100%;
  }
}

.travelagent .align-items-center {
    align-items: flex-start !important;
}