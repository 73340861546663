.popular-tours {
  position: relative;
  display: block;
  /* padding: 0 0 185px; */
}
.popular-tours__container,
.popular-tours__img,
.popular-tours__single {
  position: relative;
  display: block;
}
.popular-tours__container {
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
.section-title {
  margin-top: 5px;
  /* margin-bottom: 47px; */
}

.text-center {
  text-align: center !important;
}
.section-title__tagline {
  display: block;
  color: #002543;
  font-size: 24px;
  align-items: center;
  line-height: 30px;
  font-weight: 400;
}
.section-title__title {
  margin: 0;
  font-weight: 700;
  margin: 5px 0px;
  color: #002543;
  font-size: 32px;
  line-height: 60px;
  letter-spacing: -0.02em;
}
.popularTourPrev {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  background: black;
  margin: 0 3px;
  border-radius: 50%;
  transition: all 0.1s linear;
  transition-delay: 0.1s;
  zoom: 1;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
