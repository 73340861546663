.about-one {
  position: relative;
  display: block;
  padding: 105px 0 111px;
}
.about-one-shape-1 {
  position: absolute;
  top: 180px;
  left: 300px;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.slideInLeft {
  transform: translate3d(-100%, 0, 0);
  visibility: visible;
}
/* .about-one-shape-1 img {
    -webkit-animation: animation1 5s ease-in infinite;
    animation: animation1 5s ease-in infinite;
} */
.about-one-shape-2 {
  position: absolute;
  top: 70px;
  right: 100px;
}
.float-bob-y {
  -webkit-animation-name: float-bob-y;
  animation-name: float-bob-y;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
.about-one__img,
.about-one__img-box,
.about-one__left {
  position: relative;
  display: block;
}
.about-one__left {
  margin-left: -45px;
  margin-right: -10px;
}
.about-one__img img {
  width: 100%;
}
.about-one__call {
  position: absolute;
  bottom: 216px;
  left: -95px;
  background-color: #fff;
  box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 25px 40px 24px;
  border-radius: 8px;
}
.about-one__call:before {
  position: absolute;
  bottom: -20px;
  right: 26px;
  content: "";
  background-image: url("https://tevily-nextjs.vercel.app/_next/static/media/about-one-call-shape-1.3c7a9a58.png");
  height: 20px;
  width: 33px;
}
.about-one__call-icon {
  display: flex;
  align-items: center;
}
.about-one__call-icon span {
  font-size: 25px;
  color: #002543;
}
.icon-phone-call:before {
  content: "\e903";
}
.about-one__call-number {
  margin-left: 20px;
}
.about-one__call-number p {
  font-size: 10px;
  margin: 0;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 13px;
}
.about-one__call-number h4 {
  font-size: 18px;
  line-height: 20px;
}
.about-one__call-number h4 a {
  font-size: 18px;
  font-weight: 700;
  color: black;
}
.about-one__discount {
  position: absolute;
  top: 130px;
  right: -6px;
}
.about-one__discount h2 {
  font-size: 80px;
  color: #002543;
  line-height: 80px;
  /* font-family: reey; */
}
.about-one__discount h3 {
  font-size: 50px;
  font-weight: 700;
  margin-top: -27px;
}
.about-one__right {
  position: relative;
  display: block;
  margin-left: 70px;
  margin-top: 10px;
}
.about-one__right .section-title {
  margin-bottom: 37px;
}
.section-title {
  margin-top: 5px;
  /* margin-bottom: 47px; */
}
.section-title__tagline {
  display: block;
  color: #002543;
  font-size: 20px;
  align-items: center;
  line-height: 30px;
  font-weight: 400;
  /* font-family: reey; */
}
/* .section-title__title {
  margin: 0;
  font-weight: 700;
  color: black;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: -0.02em;
} */
.about-one__right-text {
  margin: 0;
  color: #787780;
  font-size: 18px;
  line-height: 30px;
}
.about-one__points {
  position: relative;
  display: block;
  margin-top: 34px;
  margin-bottom: 61px;
}
.list-inline,
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.about-one__points li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.about-one__points li .icon {
  height: 16px;
  width: 16px;
  background-color: #002543;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
}
.fa-check:before {
  content: "\f00c";
}
.about-one__points li .text {
  margin-left: 15px;
}
.about-one__points li .text p {
  color: #51506b;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}
.thm-btn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border: none;
  text-decoration: none;
  outline: none !important;
  background-color: #002543;
  color: white;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 18px 30px;
  border-radius: 8px;
  transition: all 0.3s linear;
  overflow: hidden;
  letter-spacing: 0.1em;
  z-index: 1;
}
.thm-btn:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: black;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-property: all;
  opacity: 1;
  transform-origin: bottom;
  transform-style: preserve-3d;
  transform: scaleY(0);
  z-index: -1;
}
.thm-btn:hover {
  color: white;
}
