.innerWrap {
  width: 1200px;
  margin: 26px auto;
  padding: 0 15px;
}

.slchtl_mn {
  width: 100%;
}

.pg_tle {
  width: 100%;
  text-align: center;
}

.f28 {
  font-size: 28px;
}

.wt600 {
  font-weight: 600;
}

._polrdestnbx {
  display: flex;
  flex-wrap: wrap;
  gap: 23px;
}

.mt20 {
  margin-top: 20px;
}

.ecpl_rbx {
  width: 32%;
  /* background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgb(60 128 205 / 24%) 100%); */
  background: linear-gradient(162deg, rgb(44 100 185 / 20%) 40%, rgb(248 165 0 / 21%) 100%);
  border-radius: 2px;
  padding: 15px;
  display: flex
;
  justify-content: space-between;
}

.mgdestn {
  width: 20%;
  height: 70px;
}
.mgdestn img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

._pckgdscrptn {
  width: 76%;
  display: flex;
  flex-flow: column;
}

._pckgdscrptn h5 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.htlnking a {
  color: #000;
  text-decoration: none;
  position: relative;
}

.htlnking a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0px;
  background: #2d3290;
  visibility: hidden;
  border-radius: 5px;
  transition: 0.25s linear;
  transform: scaleX(0);
}

.htlnking {
  font-size: 13px;
}

.ctmrg {
  margin: 25px 0 0 !important;
}

.view_btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}

.view_btn a {
  font-size: 15px;
  /* background: #2d3290; */
  background: linear-gradient(20deg, rgb(27 52 140) 28%, rgb(248 165 0) 100%);
  padding: 5px 20px;
  color: #ffffff;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 500;
}

@media (max-width: 767.98px) {
  .ecpl_rbx {
    width: 100%;
  }
  .f28 {
    font-size: 24px;
  }
}

@media (max-width: 1200px) {
  .innerWrap {
    width: 100%;
  }
}
