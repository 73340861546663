.inRound .block-content-2 {
  padding: 30px 30px;
  box-shadow: 0px 2px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.inRound .roundtrip_search .custom_block_content,
.inRound .oneway_search .custom_block_content,
.inRound .internationtrip_search .custom_block_content {
  border-radius: 5px;
  border: 1px solid #d2d2d2;
}

.inRound .box-result {
  display: flex;
}

.inRound .custom_box_result {
  display: block;
}

.inRound .inter_trip_left h4 {
  color: #000;
}

.inRound .custom_box_result ul.list-search-result li {
  float: left;
  width: 16.6%;
  margin: 0px;
}

.inRound .custom_box_result ul.list-search-result li img {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
}

.inRound .oneway_search .custom_box_result ul.list-search-result li img,
.inRound
  .internationtrip_search
  .custom_box_result
  ul.list-search-result
  li
  img {
  width: 30px;
}

.inRound .custom_box_result ul.list-search-result li .flight_name {
  display: inline-block;
  vertical-align: top;
  font-size: 11px;
  line-height: 16px;
}

.inRound .custom_box_result ul.list-search-result li .flight_name span {
  display: block;
  font-size: 12px;
}

.inRound .list-search-result > li + li {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px dashed #ccc;
}

.inRound .inter_trip_left .depart_time {
  text-align: right;
}

.inRound .inter_trip_left .cus_dep_arr_time,
.inRound .inter_trip_left .cus_dep_arr_time .date_time {
  color: #333;
}

.inRound .custom_box_result ul.list-search-result li + li {
  border: 0px;
}

.inRound .custom_box_result ul.list-search-result li span.date {
  display: block;
  color: #000;
  font-weight: 600;
}

.inRound .inter_trip_left .flight_time_between {
  text-align: center;
}

.inRound .custom_box_result ul.list-search-result li span.duration {
  display: block;
  text-align: center;
}

.inRound .cus_tooltip {
  position: relative;
  display: inline-block;
  z-index: 99;
  width: 100%;
}

.inRound .cus_tooltip .tooltiptext {
  visibility: hidden;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: -35px;
  left: 50%;
  opacity: 0;
  transition: opacity 0.3s;
  transform: translate(-50%, 0);
}

.inRound .custom_box_result ul.list-search-result li span.duration span {
  display: block;
  font-size: 12px;
}

.inRound .cus_tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}

.inRound .inter_trip_left .flight_time_between .time_separete {
  border-top: 1px solid #e4e4e4;
  position: relative;
  margin: 10px 0px;
}

.inRound .inter_trip_left .flight_time_between .time_separete:before,
.inRound .inter_trip_left .flight_time_between .time_separete:after {
  content: "";
  background-color: #e4e4e4;
  display: inline-block;
  height: 8px;
  position: absolute;
  top: -5px;
  width: 1px;
}

.inRound .inter_trip_left .flight_time_between .time_separete:before {
  left: 0px;
}

.inRound .inter_trip_left .flight_time_between .time_separete:after {
  right: 0px;
}

.inRound .inter_trip_left .flight_time_between .flight_rel {
  color: #89ad3e;
}

.inRound .hr_seperator {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.inRound .internationtrip_search .hr_seperator {
  margin: 15px 0px 10px;
}

.inRound .book_flight .refundable span {
  border-radius: 34px;
  margin-bottom: 6px;
  display: inline-block;
  text-transform: uppercase;
}

.inRound .book_flight .refundable.clr_green span {
  border: 1px solid #002543;
  color: #002543;
}

.inRound .book_flight .fli_price {
  color: #002543;
  display: block;
  font-weight: 600;
}

.inRound .btn {
  overflow: hidden;
  position: relative;
  z-index: 10;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 17px 35px;
  font-size: 14px;
  line-height: 1.8;
  border-radius: 3px;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-align: center;
  border: 2px solid;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.05);
  font-family: "GothamRounded", "Almarai", sans-serif;
  transition: color 0.2s, background 0.2s, border-color 0.2s, box-shadow 0.2s,
    transform 0.15s;
}

.inRound .book_flight a.btn_green {
  display: inline-block;
  text-align: right;
  align-items: baseline;
}

.inRound .btn.colorful-transparent.btn_green {
  border-color: #002543;
  color: #fff;
  background-image: linear-gradient(to right, #002543, #002543);
}

@media (max-width: 1400px) {
  .inRound h4 {
    font-size: 19.8px;
  }
}

@media (min-width: 768px) {
  .inRound .inter_trip_left {
    width: 80%;
  }

  .inRound .internationtrip_search .custom_box_result ul.list-search-result li,
  .inRound .multiway_search .custom_box_result ul.list-search-result li {
    width: 25%;
  }

  .inRound .cus_tooltip {
    font-size: 13px;
    line-height: 18px;
  }

  .inRound .hr_seperator {
    margin: 25px 0px 15px;
  }

  .inRound .book_flight {
    width: 20%;
  }

  .inRound .book_flight .refundable span {
    padding: 2px 8px;
    font-size: 11px;
  }
}

@media (min-width: 601px) {
  .inRound .custom_block_content {
    margin-top: 10px;
  }

  .inRound .custom_box_result ul.list-search-result li {
    font-size: 13px;
    line-height: 21px;
    padding: 0px 10px;
  }

  .inRound .custom_block_content .inter_trip_left .cus_dep_arr_time span.date {
    font-size: 18px;
    line-height: 24px;
  }

  .inRound .cus_tooltip .tooltiptext {
    width: 100px;
    font-size: 12px;
    line-height: 21px;
    padding: 5px 10px;
  }

  .inRound .btn.colorful-transparent.btn_green {
    font-size: 12px;
    padding: 5px 10px;
  }
}

@media (min-width: 481px) {
  .inRound .custom_block_content {
    padding: 8px 10px;
  }

  .inRound .inter_trip_left {
    float: left;
    padding-right: 10px;
    border-right: 1px solid #d4d4d4;
  }

  .inRound .inter_trip_left h4 {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 10px;
  }

  .inRound .inter_trip_left .cus_dep_arr_time,
  .inRound .inter_trip_left .cus_dep_arr_time .date_time,
  .inRound .inter_trip_left .flight_time_between span.duration {
    font-size: 12px;
    line-height: 16px;
  }

  .inRound .book_flight {
    float: left;
    padding: 15px 0px 0px;
    text-align: right;
  }

  .inRound .book_flight .fli_price {
    font-size: 21px;
    line-height: 24px;
    margin: 10px 0px;
  }
}

@media (min-width: 481px) and (max-width: 600px) {
  .inRound .custom_box_result ul.list-search-result li {
    font-size: 12px;
    line-height: 18px;
    padding: 0px 5px;
  }

  .inRound .custom_block_content .inter_trip_left .cus_dep_arr_time span.date {
    font-size: 14px;
    line-height: 18px;
  }

  .inRound .custom_box_result ul.list-search-result.result_list li span.date {
    font-size: 14px;
    line-height: 18px;
  }

  .inRound .custom_box_result ul.list-search-result li {
    font-size: 12px;
    line-height: 18px;
    padding: 0px 5px;
  }

  .inRound .btn.colorful-transparent.btn_green {
    font-size: 10px;
    padding: 5px 5px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .inRound .inter_trip_left {
    width: 75%;
  }

  .inRound .custom_box_result ul.list-search-result li {
    font-size: 12px;
    line-height: 18px;
    padding: 0px 5px;
  }

  .inRound
    .internationtrip_search
    .custom_box_result
    ul.list-search-result
    li.cus_dep_arr_time,
  .inRound
    .multiway_search
    .custom_box_result
    ul.list-search-result
    li.cus_dep_arr_time {
    width: 30%;
  }

  .inRound
    .internationtrip_search
    .custom_box_result
    ul.list-search-result
    li.flight_time_between,
  .inRound
    .multiway_search
    .custom_box_result
    ul.list-search-result
    li.flight_time_between {
    width: 40%;
  }

  .inRound .cus_tooltip {
    font-size: 12px;
    line-height: 16px;
  }

  .inRound .book_flight {
    width: 25%;
  }

  .inRound .book_flight .refundable span {
    padding: 2px 6px;
    font-size: 10px;
  }
}

@media (max-width: 1400px) {
  .inRound h4 {
    font-size: 19.8px;
  }
}

@media (max-width: 992px) {
  .inRound .box-result {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .inRound .list-search-result > li + li {
    padding-top: 30px;
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .inRound
    .internationtrip_search
    .custom_box_result
    ul.list-search-result
    li:first-child,
  .inRound
    .multiway_search
    .custom_box_result
    ul.list-search-result
    li:first-child {
    width: 100%;
  }

  .inRound
    .internationtrip_search
    .custom_box_result
    .inter_trip_left
    ul.list-search-result
    li
    .flight_name,
  .inRound
    .multiway_search
    .custom_box_result
    .inter_trip_left
    ul.list-search-result
    li
    .flight_name {
    font-size: 13px;
    line-height: 16px;
  }

  .inRound
    .internationtrip_search
    .custom_box_result
    .inter_trip_left
    ul.list-search-result
    li
    .flight_name
    span,
  .inRound
    .multiway_search
    .custom_box_result
    .inter_trip_left
    ul.list-search-result
    li
    .flight_name
    span {
    font-size: 12px;
    line-height: 15px;
    margin-left: 5px;
    display: inline-block;
  }

  .inRound .list-search-result > li + li {
    padding-top: 30px;
    margin-top: 30px;
  }

  .inRound .hr_seperator {
    margin: 12px 0px 8px;
  }

  .inRound .flight_details a {
    font-size: 11px;
    line-height: 15px;
  }
}

@media (max-width: 600px) {
  .inRound .custom_block_content {
    margin-top: 5px;
  }

  .inRound .cus_tooltip .tooltiptext {
    padding: 5px 7px;
    width: 80px;
    font-size: 10px;
    line-height: 14px;
  }
}

@media (max-width: 480px) {
  .inRound .custom_block_content {
    padding: 5px 5px;
  }

  .inRound .inter_trip_left {
    border-bottom: 1px solid #d4d4d4;
    padding-bottom: 5px;
    float: left;
    width: 100%;
  }

  .inRound h4 {
    font-size: 15.4px;
  }

  .inRound .inter_trip_left h4 {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 6px;
  }

  .inRound .custom_box_result ul.list-search-result li {
    font-size: 10px;
    line-height: 16px;
    padding: 0px;
  }

  .inRound .custom_box_result ul.list-search-result li:first-child {
    width: 100%;
    padding: 0px;
  }

  .inRound .oneway_search .custom_box_result ul.list-search-result li img,
  .inRound
    .internationtrip_search
    .custom_box_result
    ul.list-search-result
    li
    img {
    width: 25px;
  }

  .inRound .custom_box_result ul.list-search-result li .flight_name {
    font-size: 13px;
    line-height: 16px;
    color: #000;
  }

  .inRound .custom_box_result ul.list-search-result li .flight_name span {
    display: inline-block;
    margin-left: 5px;
    font-size: 11px;
    line-height: 14px;
    color: #525252;
  }

  .inRound .inter_trip_left .cus_dep_arr_time,
  .inRound .inter_trip_left .cus_dep_arr_time .date_time,
  .inRound .inter_trip_left .flight_time_between span.duration {
    font-size: 11px;
    line-height: 14px;
  }

  .inRound
    .internationtrip_search
    .custom_box_result
    ul.list-search-result
    li.cus_dep_arr_time,
  .inRound
    .multiway_search
    .custom_box_result
    ul.list-search-result
    li.cus_dep_arr_time {
    width: 30%;
  }

  .inRound .custom_block_content .inter_trip_left .cus_dep_arr_time span.date {
    font-size: 12px;
    line-height: 16px;
  }

  .inRound .custom_box_result ul.list-search-result li {
    font-size: 10px;
    line-height: 16px;
    padding: 0px;
  }

  .inRound .custom_box_result ul.list-search-result li:nth-child(3) {
    width: calc(50% - 90px);
    margin: 0px 5px;
  }

  .inRound
    .internationtrip_search
    .custom_box_result
    ul.list-search-result
    li.flight_time_between,
  .inRound
    .multiway_search
    .custom_box_result
    ul.list-search-result
    li.flight_time_between {
    width: calc(40% - 10px);
  }

  .inRound .inter_trip_left .cus_dep_arr_time,
  .inRound .inter_trip_left .cus_dep_arr_time .date_time,
  .inRound .inter_trip_left .flight_time_between span.duration {
    font-size: 11px;
    line-height: 14px;
  }

  .inRound .cus_tooltip {
    font-size: 8px;
    line-height: 12px;
  }

  .inRound .book_flight {
    padding: 6px 0px 4px;
    text-align: center;
    clear: both;
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .inRound .book_flight .refundable,
  .inRound .book_flight span.fli_price {
    margin-right: 5px;
  }

  .inRound .book_flight .refundable,
  .inRound .book_flight span.fli_price,
  .inRound .btn.colorful-transparent.btn_green {
    display: inline-block;
  }

  .inRound .book_flight .refundable span {
    padding: 2px 5px;
    font-size: 9px;
  }

  .inRound .book_flight .fli_price {
    font-size: 15px;
    line-height: 21px;
    margin: 3px 0px;
  }

  .inRound .book_flight .refundable,
  .inRound .book_flight span.fli_price {
    margin-right: 5px;
  }

  .inRound .book_flight .refundable,
  .inRound .book_flight span.fli_price,
  .inRound .btn.colorful-transparent.btn_green {
    display: inline-block;
  }

  .inRound .internationtrip_search .book_flight .bookbtn_morefare {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .inRound .btn.colorful-transparent.btn_green {
    font-size: 9px;
    padding: 4px 5px;
    display: block !important;
  }

  .inRound .book_flight .refundable,
  .inRound .book_flight span.fli_price,
  .inRound .btn.colorful-transparent.btn_green {
    display: inline-block;
  }

  .inRound .book_flight .btn.colorful-transparent.btn_green {
    font-size: 10px;
  }

  .inRound
    .internationtrip_search
    .book_flight
    .bookbtn_morefare
    .more_farebtn {
    position: absolute;
    left: 0px;
    bottom: -30px;
  }
}

.inRound .refendable11onword {
  max-width: 100% !important;
}
