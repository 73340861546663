.hotelsearchmobile .listProduct {
    margin: auto;
    padding: 0;
    float: left;
    width: 100%;
    /* padding-bottom: 10px; */
}

.hotelsearchmobile .outer {
    display: flex;
    overflow-x: auto;
    scrollbar-width: none;
    /* border-bottom: 1px solid #e0dede; */
    align-items: center;
}

.hotelsearchmobile ._innerbx {
    flex: 1;
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.hotelsearchmobile ._innerbx a {
    display: flex;
    align-items: center;
    flex-flow: column;
}

.hotelsearchmobile .flight-icon {
    width: 50px;
    height: 50px;
    margin: 11px auto;
    background: url(https://www.easemytrip.com/images/mob-emthome/flight-icon.svg);
    background-size: 100%;
    display: block;
    background-repeat: no-repeat;
}

.hotelsearchmobile .hotel-icon {
    width: 50px;
    height: 50px;
    margin: 11px auto;
    background: url(https://www.easemytrip.com/images/mob-emthome/hotel-icon.svg);
    background-size: 100%;
    display: block;
    background-repeat: no-repeat;
}

.hotelsearchmobile .holiday-icon {
    width: 32px;
    height: 45px;
    background: url(https://www.easemytrip.com/images/mob-emthome/holiday-icon.svg);
    background-size: 32px 45px;
    margin: 0px auto;
    display: flex;
    background-repeat: no-repeat;
    background-position: center;
    align-items: center;
    justify-content: center;
}

.hotelsearchmobile .giftcard-icon {
    width: 32px;
    height: 45px;
    background: url(https://www.easemytrip.com/images/mob-emthome/giftcard-icon.svg);
    background-size: 32px 45px;
    margin: 0px auto;
    display: flex;
    background-repeat: no-repeat;
    background-position: center;
    align-items: center;
    justify-content: center;
}

.hotelsearchmobile .listProduct .produt-icon {
    min-width: 30px;
    height: 30px;
    width: 30px;
    margin: auto;
    background-size: 30px 30px;

}

.hotelsearchmobile ._icnPttl {
    font-size: 13px;
    font-weight: 500;
    color: #000;
    text-align: center;
    display: block;
    text-transform: capitalize;
}

.hotelsearchmobile .actvMenu a {
    box-shadow: 0 0 7px rgb(0 0 6 / 6%);
    border: 1px solid #dcdcff;
    background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgb(230, 230, 255) 100%);
    color: #2d3290;
    padding: 5px 10px;
    border-radius: 10px;
}

.hotelsearchmobile ._innerbx2 {
    flex: 1;
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}