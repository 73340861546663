.DestinationDetailMainPage p {
  margin: 0px;
}
.DestinationDetailMainPage {
  /* padding: 50px 60px 50px 100px; */
  /* width: 100%; */
}

.DestinationDetailMainDiv {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.DestinationDetailMainDivHead {
  width: 100%;
  /* border: 1px solid black; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 15px 16px 15px 16px;
  border-radius: 8px;
}
.DestinationDetailMainDivHead h2 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #336d85;
}
.DestinationDetailMailFilter {
  display: flex;
  width: 33%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* border: 1px solid black;    */
  padding: 20px 25px 20px 25px;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px;
  height: max-content;
}
.DestinationDetailMailFilterHead1 {
  font-size: 20px;
  font-weight: 600;
  border-bottom: 0.1px solid black;
  padding: 8px 0px 8px 0px;
  color: #002543;
}
.DestinationDetailMailFilterHead2 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #002543;
}
.DestinationDetailMailFilterInput {
  font-size: 13px;
  display: flex;
  gap: 12px;
}
.DestinationDetailMailFilterHead3 {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.DestinationDetailMailFilterInput2 {
  border: 1px solid rgba(0, 0, 0, 0.39);
  padding: 8px;
  border-radius: 8px;
}
.DestinationDetailMailFilterSearch {
  padding: 8px 12px 8px 12px;
  width: 13rem;
  border: 1px solid rgba(0, 0, 0, 0.37);
}
.DestinationDetailMainDiv2 {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 0px 25px 20px;
}
.DestinationDetailMainDivInner1 {
  display: flex;
  align-items: end;
  justify-content: end;
}
.DestinationDetailMainDivInner1sel {
  width: 9rem;
  padding: 4px;
  /* border: 1px solid black; */
}

/* DetailPageReuse CSSSS */
.DetailPageReuseMain {
  border-radius: 8px;
  margin-bottom: 16px;
  width: 100%;
  padding: 0px;
}
.DetailPageReuseMain2 {
  display: flex;
  /* flex-direction: column;     */
  gap: 4px;
}
.DetailPageReuseMainInner1 {
  padding: 13px 12px 13px 12px;
}
.DetailPageReuseMainInner1 img {
  border-radius: 6px;
  height: 225px;
  width: 250px;
  object-fit: cover;
}
.DetailPageReuseMain3 {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1px;
}
.DetailPageReuseMainInner2 {
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  justify-content: space-between;
}
.DetailPageReuseMainInner2Head {
  font-size: 19px;
  color: #002543;
  width: 20vw;
  font-weight: 600;
  /* opacity: 0.8; */
  margin-top: 8px;
}
.DetailPageReuseMainInner2para {
  font-size: 12px;
  color: #002543;
  font-weight: 600;
}
.DetailPageReuseMainInner2para2 {
  font-size: 12px;
  color: #686868;
  text-decoration-line: line-through;
  text-align: end;
}
.DetailPageReuseMainInner2para3 {
  font-size: 12px;
  font-weight: 500;
  /* width: 12vw; */
  margin-top: 4px !important;
  margin-right: 6px !important;
}
.DetailPageReuseMainInner2Span {
  font-weight: 600;
  font-size: 18px;
  color: #002543;
}
.DetailPageReuseMainInner3 {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 5px;
}
.DetailPageReuseMainInner3Para {
  font-size: 13px;
  width: 100%;
  /* max-width: 20vw; */
}
.DetailPageReuseMainInner3Para2 {
  font-size: 14px;
  color: #002543;
  font-weight: 500;
}
.DetailPageReuseMainInner4 {
  display: flex;
  gap: 20px;
  margin-top: 1px;
}
.DetailPageReuseMainInner4 img {
  width: 25px;
  height: 20px;
}
.DetailPageReuseMainInner5 {
  display: flex;
  gap: 12px;
  align-items: end;
  justify-content: end;
  margin-top: 40px;
  margin-bottom: 8px;
  margin-right: 16px;
}
.DetailPageReuseMainInner5Btn1 {
  border: 1.9px solid #336d85;
  border-radius: 2px;
  padding: 8px 24px 8px 24px;
  font-weight: 600;
  font-size: 14px;
  color: #336d85;
}
.DetailPageReuseMainInner5Btn2 {
  border: 1px solid black;
  border-radius: 2px;
  background: #002543;
  padding: 8px 24px 8px 24px;
  font-size: 14px;
  color: white;
}
@media (max-width: 768px) {
  .DetailPageReuseMain2 {
    display: flex;
    flex-direction: column;
  }
  .DetailPageReuseMainInner1 img {
    width: 100% !important;
    height: 220px !important;
  }
  .DestinationDetailMainDiv2 {
    width: 100%;
    padding: 0px !important;
  }
  .DetailPageReuseMainInner2 {
    display: flex;
    flex-direction: column;
  }
  .DetailPageReuseMainInner2para2 {
    text-align: start !important;
  }
  .DestinationDetailMainPage {
    padding: 10px !important;
  }
  .DetailPageReuseMain3 {
    padding: 0px 20px;
  }
  .DetailPageReuseMainInner2Head {
    max-width: 100% !important;
  }
}
@media (max-width: 1000px) {
  .DestinationDetailMailFilter {
    display: none;
  }
  .DestinationDetailMainDiv2 {
    width: 100%;
  }
  .DetailPageReuseMainInner1 img {
    height: 220px;
    width: 250px;
  }
}
@media (max-width: 1026px) {
  .DestinationDetailMainDiv2 {
    width: 100% !important;
  }
  .DetailPageReuseMainInner2Head {
    width: 200px !important;
  }
  /* .DetailPageReuseMainInner2para3 {
    margin-top: 12px !important;
    margin-right: -15px !important;
  } */
  .DestinationDetailMainPage {
    padding: 10px;
  }
}
@media (max-width: 426px) {
  .DetailPageReuseMainInner5 {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;
    justify-content: start;
    align-items: start;
  }
}
@media (min-width: 576px) {
  .containerss {
    max-width: 740px !important;
  }
}
@media (min-width: 768px) {
  .containerss {
    max-width: 920px !important;
  }
}
@media (min-width: 992px) {
  .containerss {
    max-width: 960px !important;
  }
}
@media (min-width: 1200px) {
  .containerss {
    max-width: 1140px !important;
  }
}

@media (min-width: 1400px) {
  .containerss {
    max-width: 1320px !important;
  }
}
