.hotelsearchmobile .mnblock {
    width: 94%;
    margin: 0 auto;
}

.hotelsearchmobile .page_ttl {
    font-size: 17px;
    color: #000;
    font-weight: 700;
}

.hotelsearchmobile ._OffrScrll, ._GenScrll {
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    scrollbar-width: none;
    padding: 3px 0px 3px 0px;
    width: 100%;
}

.hotelsearchmobile ._offrDiv {
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 8%, rgba(250, 253, 255, 1) 100%);
    padding: 10px;
    box-shadow: 0 0 7px rgb(0 0 0 / 8%);
    border-radius: 10px;
    flex: 0 0 210px;
    margin-right: 10px;
    position: relative;
    border: 1px solid #e3e3e3;
}

.hotelsearchmobile img {
    max-width: 100%;
}

.hotelsearchmobile ._offrimg img {
    border-radius: 10px;
    height: 125px;
    width: 100%;
    /* object-fit: cover; */
}

.hotelsearchmobile .flxoffrr {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 10px;
}

.hotelsearchmobile ._typOff {
    display: flex;
    align-items: center;
    background: #fff;
    box-shadow: 0 0 7px rgb(0 0 0 / 8%);
    padding: 5px 10px 5px 5px;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    top: -35px;
    left: 10px;
}

.hotelsearchmobile .typHotel {
    background: url(https://www.easemytrip.com/images/mob-emthome/typHotel.svg);
    min-width: 18px;
    height: 18px;
    background-size: 100%;
    display: flex;
    margin-right: 5px;
    background-repeat: no-repeat;
}

.hotelsearchmobile ._OffrH {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    color: #000;
}

.hotelsearchmobile ._OffrP {
    font-size: 11px;
    color: #000;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.hotelsearchmobile ._OffrCoup {
    padding: 3px 10px;
    border: 1px dashed #2d3290;
    background: #E7F4FF;
    border-radius: 5px;
    margin: 8px 0;
    display: flex;
    font-size: 12px;
    align-items: center;
    width: auto;
    float: left;
}

.hotelsearchmobile ._OffrCoup ._code {
    font-size: 14px;
    font-weight: 600;
    margin-left: 5px;
}

.hotelsearchmobile ._OffrVld {
    font-size: 10px;
}