.hotelsearchmobile .mai-dv {
    width: 100%;
    /* height: calc(100vh - 70px); */
    height: 100%;
    display: none;
    background-color: #fff;
}

.hotelsearchmobile .subdiv {
    width: 100%;
    /* background: #2196F3; */
    height: auto;
    /* padding: 14px 0; */
    float: left;
    position: relative;
    z-index: 999999999999991;

    width: 100%;
    /* height: 80px !important; */
    position: fixed !important;
    top: 0;
}

.hotelsearchmobile .weekBg {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #333333;
    text-transform: uppercase;
    width: 100%;
    float: left;
    background: #EFEAEA;
}

.hotelsearchmobile .stickytop2 {
    position: sticky;
    top: 0px;
    z-index: 999999999999992;
}

.hotelsearchmobile .topHeader {
    width: 100%;
    float: left;
    background: #ffffff;
    padding: 11px 0;
}

.hotelsearchmobile .chekinCol {
    display: flex;
    position: relative;
}

.hotelsearchmobile .innerFlex {
    padding: 0px 16px 0 16px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.hotelsearchmobile .checColm {
    width: 45%;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    text-align: center;
}

.hotelsearchmobile .chktophed {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
}

.hotelsearchmobile .chktopdate {
    font-weight: 500;
    font-size: 13px;
}

.hotelsearchmobile .checotColm {
    display: flex;
    flex-direction: column;
    width: 45%;
    align-items: center;
}

.hotelsearchmobile .weektable,
.hotelsearchmobile .monthtable {
    margin: auto;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    text-align: center;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
}

.hotelsearchmobile .weekTxt {
    font-weight: 500;
    padding: 8px 0;
}

.hotelsearchmobile .weekTxt span {
    font-size: 14px;
}

.hotelsearchmobile .botmSectn {
    display: flex;
    padding: 16px;
    background: rgb(255, 255, 255);
    box-sizing: border-box;
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 999999999999991;
}

.hotelsearchmobile .btnDone {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    background: #2d3290;
    border-radius: 20px;
    border: 0px;
    color: rgb(255, 255, 255);
    padding: 10px 0px;
    width: 100%;
    text-decoration: none;
}

.hotelsearchmobile .ttlNight {
    font-size: 14px;
    font-weight: 600;
}

/* .DayPicker__withBorder {
    width: 100%;
    position: relative;
    height: calc(100vh - 70px);
    overflow-y: auto;
    scrollbar-width: thin;
} */

.hotelsearchmobile .DayPicker_weekHeader {
    display: none;
}

.hotelsearchmobile .CalendarMonthGrid__vertical{
    width: 100% !important;
}

.hotelsearchmobile .CalendarMonth_caption {
    padding-top: 5px;
    padding-bottom: 5px;
    /* width: 100%; */
}

.hotelsearchmobile .CalendarMonth{
    padding: 0px !important;
}

.DayPickerNavigation_svg__vertical{
    height: 100%;
}

.hotelsearchmobile .DayPickerNavigation__verticalDefault{
    height: 30px;
    display: none;
}

.hotelsearchmobile .CalendarMonthGrid__vertical{
    width: 100%;
    position: relative;
    height: calc(100vh - 70px);
    overflow-y: auto;
    scrollbar-width: thin;
}


@media (max-width: 374.99998px) {
    .hotelsearchmobile .DayPicker {
        /* width: 280px; */
        margin-left: 0px !important;
        margin-inline: auto !important;
        /* margin-top: -10px; */
    }
}