.hoteldetail .fl-wrap {
    float: left;
    width: 100%;
    position: relative;
}

.hoteldetail .scroll-nav-wrapper {
    background: #fff;
    border-bottom: 1px solid #eee;
    padding: 14px 0;
    -webkit-transform: translate3d(0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    /* width: 100%; */
}

.hoteldetail .scroll-nav-wrapper .show-hidden-map {
    float: right;
    position: relative;
    color: #fff;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    padding: 0 25px;
    font-size: 12px;
    font-weight: 600;
    /* background: #18458B; */
    background: #F9B90F;
}

/* .scroll-nav-wrapper .scroll-nav{
    width: 80%;
}

.scroll-nav-wrapper .w20{
    width: 20%;
} */

.hoteldetail .scroll-nav-wrapper .container {
    display: flex;
    justify-content: space-between;
}

.hoteldetail .scroll-nav-wrapper .scroll-nav li {
    float: left;
    position: relative;
    overflow: hidden;
}

.hoteldetail .scroll-nav-wrapper .scroll-nav li:first-child {
    border-radius: 4px 0 0 4px;
}

.hoteldetail .scroll-nav-wrapper .scroll-nav li .nav-link {
    padding: 0 30px;
    float: left;
    height: 100%;
    height: 40px;
    line-height: 40px;
    position: relative;
    font-weight: 700;
    color: #888DA0;
    font-size: 12px;
    background: #ECF6F8;
}

.hoteldetail .scroll-nav-wrapper .scroll-nav li .nav-link.act-scrlink {
    color: #fff;
    background: #2d3290;
}



.hoteldetail .scroll-nav-wrapper {
    z-index: 100;
    position: absolute;
    top: 0px;
}

@media (min-width:1025px) {
    .hoteldetail .scroll-nav-wrapper.sticky {
        z-index: 1112;
        position: fixed;
        top: 0px;
    }
}