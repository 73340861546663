.tourPackagefullDetail p {
  margin: 0px;
  border: none;
  display: inline-block;
}
.tourPackagefullDetail {
  /* margin-top: 20px; */
  padding: 10px 50px;
}
.tourPackagefullDetailmain {
  display: flex;
  gap: 25px;
  flex-direction: column;
}
.tourPackageFullDetailUnderline {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
.tourPackageUnderLineIcon1 {
  padding: 6px;
  background-color: #f8a500;
  color: white;
  cursor: pointer;
}
.tourPackageUnderLineIcon2 {
  padding: 6px;
  background-color: #002543;
  color: white;
  cursor: pointer;
}
.tourPackageUnderLineIcon {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}
.tourPackagefullDetailUnder {
  display: flex;
  gap: 24px;
  padding: 10px 50px;
  overflow: auto;
} 
.tourPackage-ful-strip {
  background-color: #3a7fcd1a;
  position: sticky;
  top: 0;
  z-index: 99999;
  margin-top: 10px;
}
.tourPackageFullDetailUnderline {
  position: relative; /* Make sure the container has a relative position */
}

.tourPackagefullDetailUnder {
  position: sticky;
  top: 0; /* Stick to the top of the container */
  z-index: 10; /* Ensure it stays on top */
}
.tourPackage-ful-strip.sticky {
  background-color: #002543; 
  width: 100%;/* Change to the color you want */
}
.tourPackagefullDetailUnderss li {
  background-color: #002543;
  color: white;
  padding: 8px 20px 8px 20px;
  border-radius: 6px;
}
.tourPackagefullDetailUnder li {
  background-color: #fff;
  color: #000;
  box-shadow: rgb(238 238 238 / 20%) 0px 2px 8px 0px;
  padding: 8px 20px 8px 20px;
  border-radius: 6px;
}
.tourPackagefullDetailMain1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.tourPackagefullDetailinner1 {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  gap: 30px;
}
.tourPackagefullDetailinnerMain {
  width: 65%;
}
.tourPaclageDetailinnerimg {
  height: 400px;
  border-radius: 8px;
  width: 850px !important;
  margin-bottom: 26px;
}
.tourPaclageDetailinnerimggg {
  width: 250px;
  margin-right: 20px;
  height: 160px;
  border-radius: 8px;
}
.tourpackageDesc {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: max-content;
}
.tourpackageDescIner {
  display: flex;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 8px;
  line-height: 28px;
}
.tourpackageDeshead1 {
  font-size: 18px;
  color: #222222;
  font-weight: 600;
}
.tourpackageDescpara1 {
  font-size: 16px;
}
.tourpackageDescbtn {
  color: blue;
  margin-left: 8px;
  background: none;
  border: none;
  text-decoration: underline;
}
.tourPackageForm {
  width: 32%;
  /* position: sticky;
  top: 140px; */
  height: max-content;
}
.tourPackageFormMainDiv {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.tourPackageFormSecDiv {
  border-radius: 25px 25px 0px 4px !important;
  /* border-radius: 10px !important;  */
}
.tourPackageFormDiv {
  background-color: #18477b;
  padding: 15px;
  text-align: center;
  border-radius: 20px 20px 0px 4px !important;
}
.tourPackageFormDivh1 {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}
.tourPackageFormDiv p {
  font-size: 12px;
  color: #fff;
}
.tourPackageFormPackage {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.tourPackageFormPackageInput {
  width: 100%;
  padding: 8px;
  font-size: 14px;
}
.tourPackageFormAdult {
  display: flex;
  /* flex-direction: column; */
  gap: 20px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0px 20px;
}
.tourPackageFormAdultInner {
  width: 23%;
  margin-right: 16px;
}
.tourPackageFormAdultInner1 {
  font-size: 13px;
  /* text-align: center; */
}
.tourPackageFormAdultInnerFlex {
  display: flex;
  align-items: center;
}
.tourPackageFormAdultInnerPara {
  padding: 5px 8px;
  /* border-radius: 5px; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* border: 1px solid rgba(0, 0, 0, 0.26) !important; */
}
.tourPackageFormAdultInnerbtn {
  background-color: #3a7fcd1a;
  padding: 7px 12px;
  border-radius: 4px;
  text-align: center;
  border: none !important;
  color: black;
  font-size: 15px;
}
.tourPackageFormName {
  display: flex;
  padding: 16px 20px;
  gap: 4px;
}
.tourPackageFormNameLabel {
  font-size: 13px;
}
.tourPackageFormNameLabelInput {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.26) !important;
  margin-top: 8px;
  border-radius: 4px;
  padding: 8px;
}
.tourPackageFormContact {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0px 20px;
}
.tourPackageFormContactInput {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.26) !important;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
}
.tourPackageFormQuery {
  padding: 20px;
  margin-top: 12px;
}
.tourPackageFormQuerybtn {
  width: 100%;
  background-color: #18477b;
  color: #fff;
  border: none;
  padding: 8px;
  border-radius: 10px;
}
.tourPackageDetail {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.11) !important;
  padding-top: 15px;
}
.tourPackageDetailHotel {
  display: flex;
  gap: 14px;
  font-size: 12px;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 12px;
}
.tourPackageDetailHotelInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
.tourPackageDetailDuration {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tourPackageDetailDurationLine {
  height: 1px;
  width: 29%;
  background-color: #222222;
  opacity: 0.4;
}
.tourPackageDetailDurationpara {
  font-size: 13px;
  background-color: #eeeeee;
  padding: 1px 8px;
  border-radius: 12px;
}
.tourPackageDetailDays {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 26px !important;
  padding-bottom: 10px;
}
.tourPackageDetailDaySpan {
  font-weight: 600;
  /* color: #17194e; */
}
.tourPackageDetailIcons {
  padding: 20px;
  border-radius: 12px;
  display: flex;
  gap: 12px;
  background-color: #f1f1f1;
  margin-top: 20px;
}
.tourPackageDetailIconsPara {
  font-size: 18px;
  font-weight: 600;
}
.tourPackageDetailIconsPara2 {
  font-size: 14px;
  font-weight: 500;
  /* color: white; */
}
.tourPackagefullDetailUnder li a{
  color: #000;
}
@media (max-width: 1200px) {
  .tourPackagefullDetail {
    padding: 0px 0px;
  }
  .tourPackagefullDetailinner1 {
    flex-direction: column;
    /* margin: auto; */
    justify-content: center;
    align-items: center;
  }
  .tourPackagefullDetailinnerMain {
    width: 90%;
  }
  .tourPaclageDetailinnerimg {
    width: 100% !important;
  }
  .tourPackageFullDetailUnderline {
    padding: 0px 50px;
    margin-top: 20px;
  }
  .tourPackageForm {
    width: 90%;
  }
  /* .tourPackageFormPackageInput {
    width: 100%;
  } */
  .tourPackageFormAdultInnerPara {
    padding: 6px 80px;
  }
  .tourPackageFormName {
    flex-direction: column;
  }
  .tourPackageFormLabelDiv {
    display: flex;
    flex-direction: column;
  }
  /* .tourPackageFormNameLabelInput {
    width: 100%;
  } */
}
@media (max-width: 426px) {
  .tourPaclageDetailinnerimg {
    height: 300px;
    object-fit: cover;
  }
  .tourpackageDescpara1 {
    font-size: 14px;
  }
  .itineraryHotelDetailMain {
    flex-direction: column;
  }
  .tourPackageFormAdultInnerPara {
    padding: 6px 4px !important;
  }
  .tourPackageFullDetailUnderline {
    padding: 0px 15px;
  }
  .tourPackageFormAdult {
    gap: 12px;
  }
}
@media (max-width: 768px) {
  .tourPackageFormAdultInnerPara {
    padding: 6px 8px;
  }
}

@media (max-width: 768px) {
  .tourPackagefullDetailUnder li {
    padding: 0px 10px 0px 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
  }
  .tourPackagefullDetailUnder {
padding: 10px 10px;
  }
  .tourPackagefullDetailUnder{
    gap: 10px;
  }
  /* .tourPackageFormAdultInnerPara {
    padding: 6px 40px !important;
  } */
}

.right-side-tour-package > h3 {
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 600;
  font-size: 22px;
  text-transform: capitalize;
  background: #3a7fcd1a;
  text-align: center;
  border-radius: 5px;
  color: #000;
  padding: 15px 10px;
}
.package-card-alpha {
  box-shadow: 3px 5px 35px rgba(86, 68, 169, 0.1);
  border-radius: 10px;
  overflow: hidden;
}
.right-side-tour-package .package-card-alpha {
  margin-bottom: 30px;
}
.package-card-alpha .package-thumb {
  position: relative;
  overflow: hidden;
}
.package-card-alpha:hover .package-thumb a img {
  transform: scale(1.2);
}
.package-card-alpha .package-thumb img {
  width: 416px;
  height: 260px;
  transition: all 0.35s;
}
.package-card-alpha .package-thumb .card-lavel {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  background: #002543;
  border-radius: 3px;
  position: absolute;
  padding: 7px 14px;
  top: 0;
  left: 20px;
  transform: translateY(50%);
}
.package-card-alpha .package-thumb .card-lavel i {
  margin-right: 4px;
}
.bi-clock::before {
  content: "\f293";
}
.package-card-alpha .package-card-body {
  padding: 20px;
}
.package-card-alpha .package-card-body .p-card-title {
  font-weight: 600;
  font-size: 23px;
  color: #454545;
}
.package-card-alpha .package-card-body .p-card-title a {
  color: inherit;
}
.package-card-alpha .package-card-body .p-card-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  gap: 15px;
  flex-wrap: wrap;
}
.package-card-alpha .package-card-body .p-card-bottom .book-btn a {
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: #002543;
  background: #ffffff;
  border: 1px solid #002543;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 7px 12px;
  transition: all 0.3s;
  white-space: nowrap;
}
.package-card-alpha .package-card-body .p-card-bottom .p-card-info {
  margin-top: -5px;
}
.package-card-alpha .package-card-body .p-card-bottom .p-card-info span {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  color: #2d373c;
}
.package-card-alpha .package-card-body .p-card-bottom .p-card-info h6 {
  color: #002543;
  font-weight: 800;
  font-size: 18px;
  line-height: 1;
}