.hotelsearchmobile .mnblock {
    width: 94%;
    margin: 0 auto;
}

.hotelsearchmobile .slchtl_mn {
    width: 100%;
    margin: 10px 0;
}

.hotelsearchmobile .page_ttl {
    font-size: 17px;
    color: #000;
    font-weight: 700;
}

.hotelsearchmobile ._polrdestnbx {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.hotelsearchmobile .mt20 {
    margin-top: 20px;
}

.hotelsearchmobile .ecpl_rbx {
    width: 100%;
    background: #F6FBFF;
    border: 1px solid #2d3290;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
}

.hotelsearchmobile .mgdestn {
    width: 20%;
    height: 70px;
}

.hotelsearchmobile .mgdestn img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.hotelsearchmobile ._pckgdscrptn {
    width: 76%;
    display: flex;
    flex-flow: column;
}

.hotelsearchmobile .htlnking {
    font-size: 13px;
}

.hotelsearchmobile ._pckgdscrptn h5 a {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
}
.hotelsearchmobile .htlnking a {
    color: #000;
    text-decoration: none;
    position: relative;
}

.hotelsearchmobile .htlnking a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0px;
    background: #2d3290;
    visibility: hidden;
    border-radius: 5px;
    transition: .25s linear;
    transform: scaleX(0);
}

.hotelsearchmobile .htlnking {
    font-size: 13px;
}

.hotelsearchmobile .htlnking{
    margin-bottom: 0px !important;
    font-size: smaller;
    font-weight: 500;
}

.hotelsearchmobile .ctmrg {
    margin: 25px 0 0 !important;
}

.hotelsearchmobile .view_btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
}

.hotelsearchmobile .view_btn a {
    font-size: 15px;
    background: #2d3290;
    padding: 5px 20px;
    color: #ffffff;
    border-radius: 50px;
    cursor: pointer;
    font-weight: 500;
}