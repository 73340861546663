.search-area {
  position: absolute;
  bottom: 0;
  z-index: 99;
  width: 60%;
  bottom: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media ((max-width: 1400px) and (min-width:1200px)) {
  .search-area {
    bottom: 42%;
  }
}
.text-center {
  text-align: center !important;
}
.trip-selection-area {
  width: auto;
}
@media (min-width: 768px) {
  .d-md-block {
    display: block !important;
  }
}
.explore-tour-package-banner h5 {
  font-size: 3em !important;
  color: #fff !important;
  margin: 15px 0px 50px 0px;
  font-weight: 300 !important;
}
.bold-font {
  font-weight: 600;
}
.mb-5 {
  margin-bottom: 3rem !important;
}
.pt-5 {
  padding-top: 3rem !important;
}
.carousel-caption {
  position: absolute;
  right: 13%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}
.trip-selection-area a {
  width: 230px;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
}
.trip-selection-area a.active {
  background-color: #ec1d23 !important;
  color: #fff !important;
  padding: 10px;
  border-radius: 4px;
  margin-right: 10px !important;
  border: solid 1px #ec1d23 !important;
}
.btn-white-outline {
  background-color: transparent !important;
  color: #fff !important;
  padding: 10px;
  border: solid 1px #fff !important;
}
.trip-selection-area a {
  width: 230px;
}
.outline-anim {
  transform: translate(0%, 0%);
  text-transform: uppercase;
  color: #fff;
  padding: 10px;
  background-color: #575757;
  text-decoration: none;
  border-radius: 4px;
  overflow: hidden;
  text-decoration: none !important;
}
.trip-selection-area a {
  width: 230px;
}
.outline-anim:before {
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  content: "";
  border: none !important;
  width: 50%;
  background: rgba(255, 255, 255, 0.05);
}
.outline-anim span:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #fff, #fff);
  animation: animate1 2s linear infinite;
}
.outline-anim span:nth-child(2) {
  position: absolute;
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(to bottom, #fff, #fff);
  animation: animate2 2s linear infinite;
  animation-delay: 1s;
}
.outline-anim span:nth-child(3) {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to left, #fff, #fff);
  animation: animate3 2s linear infinite;
}
.outline-anim span:nth-child(4) {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(to top, #fff, #fff);
  animation: animate4 2s linear infinite;
  animation-delay: 1s;
}
.trip-selection-area p {
  font-weight: 300 !important;
  line-height: 15pt !important;
  margin-bottom: 25px;
  margin-top: 15px;
}
.ui-widget {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
  position: relative;
}
.form-controlsss {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-size: 1em;
}
.search-area input[type="text"] {
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #fff !important;
}
.search_btn {
  position: absolute;
  right: 5px;
  top: 0px;
  background: none !important;
  border: none !important;
  font-size: 1em;
  cursor: pointer;
}
.feature-icon-area {
  padding-top: 40px;
  position: absolute;
  width: 100%;
}

.text-white {
  color: #fff !important;
}

.no-margin {
  margin: 0;
}

.feature-icon-area p {
  font-size: 12px;
}

@keyframes animate1 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes animate2 {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(100%);
  }
}
@keyframes animate3 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes animate4 {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}
@media (max-width: 481px) {
  .search-area {
    /* bottom: 25%; */
    bottom: 0%;
  }
}
@media (max-width: 641px) {
  .search-area {
    width: 100% !important;
    transform: translate(-50%);
  }
}
.trip-selection-area {
  width: auto;
}
@media (max-width: 481px) {
  .carousel-caption {
    bottom: 8.25rem !important;
  }
}
@media (max-width: 641px) {
  .carousel-caption h5 {
    font-size: 2.5em !important;
  }
}
@media (max-width: 641px) {
  .trip-selection-area a {
    font-size: 13px !important;
    width: auto;
  }
}
@media (max-width: 481px) {
  .trip-selection-area p {
    font-size: 13px !important;
  }
}
@media (max-width: 481px) {
  .search-area input[type="text"] {
    font-size: 11px;
  }
}
@media (max-width: 481px) {
  .search_btn {
    right: 10px;
    top: 0px;
    display: none;
  }
}
@media (max-width: 481px) {
  .search_btn i {
    font-size: 13px;
  }
}
@media (max-width: 481px) {
  .feature-icon-area {
    display: none;
  }
}
@media (max-width: 1400px) {
  .feature-icon-area {
    padding-top: 12px;
  }
}
/* Section One  */

.section-title1 {
  margin-top: 60px;
  text-align: center;
}
.section-title1 .destinn {
  display: block;
  font-family: "Work Sans", sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  color: #f8a500;
  margin-bottom: 10px;
  letter-spacing: 3px;
  text-transform: uppercase;
}
.section-title1 h2 {
  font-size: 32px;
  font-weight: 700;
  color: #002543;
  margin-bottom: 5px !important;
  /* margin-bottom: 15px; */
  margin-top: -7px;
  display: inline-block;
}
.heading-ribbon {
  margin-bottom: 10px;
}
.overflow-hidden {
  overflow: hidden !important;
}
.destination-single4 {
  height: auto;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.destination-single4 img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.destination-single4 img {
  width: 100%;
  cursor: pointer;
}
.destination-single4:hover .desti-content {
  opacity: 1;
  transform: translateY(0px);
}
.destination-single4 .desti-content {
  opacity: 1;
  transition: all 0.55s ease;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 9;
  padding: 20px 25px;
  transform: translateY(10px);
  cursor: pointer;
}
/* .destination-single4 .desti-content:hover {
  opacity: 1;
} */
.destination-single4 .desti-content h4 {
  margin-bottom: 8px;
}
.destination-single4 .desti-content h4 a {
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
}
.destination-single4 .desti-content h4 a:hover {
  color: #fff;
}
.destination-single4 a {
  box-shadow: none;
  text-decoration: none;
}
.destination-single4 .desti-content span {
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
}
.destination-single4::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(20, 22, 28, 0) 65.09%,
    rgba(20, 22, 28, 0.7) 84.38%
  );
  z-index: 1;
}

/* TripCategory Section  */
.tour-category-sections {
  margin-top: 100px;
}
.trip-category-section {
  position: relative;
  padding-bottom: 25px;
  z-index: 1;
}
/* .pt-120 {
    padding-top: 100px;
  } */
.overflow-hidden {
  overflow: hidden !important;
}
.trip-category-section img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.trip-category-section .trip-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  object-fit: cover;
  height: 300px;
}
.text-center {
  text-align: center !important;
}
.section-title4 {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.section-title4.sibling2 span {
  display: block;
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.section-title4.sibling2 h2 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 15px;
  margin-top: -7px;
  display: inline-block;
}
.section-title4 img {
  max-width: 370px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.trip-category-single {
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
  background: #fff;
}
.trip-category-single .trip-image {
  position: relative;
  overflow: hidden;
}
.trip-category-single .trip-image img {
  transition: all 0.65s ease;
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.trip-category-single .trip-image span.blog-date {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background: #002543;
  padding: 6px 12px;
}
.trip-category-single .trip-content {
  padding: 20px 20px;
  border-left: 1px solid rgba(238, 238, 238, 0.8);
  border-bottom: 1px solid rgba(238, 238, 238, 0.8);
  border-right: 1px solid rgba(238, 238, 238, 0.8);
  background-color: #fff;
}
.trip-category-single .trip-content h4 a {
  font-size: 1.4rem;
  text-decoration: none;
  font-weight: 600;
  color: #09100d;
  line-height: 1.5;
  transition: all 0.4s ease;
}
.trip-category-single .trip-content .trip-text {
  transition: all 0.55s ease;
}
.trip-category-single .trip-content .trip-bottom {
  width: 100%;
  height: 25px;
  overflow: hidden;
}
.trip-category-single .trip-meta-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.trip-category-single .trip-content .trip-bottom .trip-meta-list {
  margin-top: 0px;
  transition: all 0.5s ease;
}
.trip-category-single .trip-meta-list li {
  font-size: 16px;
  font-weight: 500;
  color: #09100d;
  margin-right: 16px;
}
.trip-category-single .trip-meta-list li .bx {
  font-size: 16px;
  vertical-align: center;
  margin-right: 8px;
  margin-top: -3px;
}
.trip-text .inclusionsBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  margin: 10px 0;
  padding: 10px 0;
}
.trip-text .inclusionsBox span {
  text-align: center;
  font-size: 12px;
  flex: 0 0 23%;
}
.trip-text .cardTrending .inclusionsBox img {
  margin: 0 auto;
  display: block;
  max-width: 35px;
}
.trip-text .inclusionsBox span img {
  height: 28px;
}
/* i.bx {
    vertical-align: middle;
}
.bx {
    font-family: boxicons !important;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
    display: inline-block;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.bx-user-circle:before {
    content: "\eec8";
} */
.trip-category-single .trip-content .explore-btn {
  font-size: 18px;
  font-weight: 500;
  color: #09100d;
  text-decoration: none;
}
.trip-category-single .trip-content .trip-bottom .explore-btn {
  margin-top: 0px;
  transition: all 0.5s ease;
}
.trip-category-single:hover .trip-image img {
  transform: scale(1.1);
}
.trip-category-single:hover .trip-content .trip-bottom .explore-btn {
  margin-top: -25px;
}
.trip-category-single:hover .trip-content .trip-bottom .trip-meta-list {
  margin-top: -25px;
}

/* WHy USSS  */
.facility-single2 {
  background: #ffffff;
  box-shadow: 4px 3px 40px rgba(16, 33, 34, 0.06);
  text-align: center;
  padding: 35px 25px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.facility-single2:hover::before {
  transform: scale(1);
  opacity: 1;
}
.facility-single2::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #bbd2ec;
  z-index: -1;
  transform: scale(0.8);
  opacity: 0;
  transition: all 0.55s ease;
}
.facility-single2 .facility-icon {
  margin-bottom: 30px;
}
.facility-single2 .facility-content h4 {
  font-size: 1.4rem;
  font-weight: 600;
  margin-top: 12px;
  color: #09100d;
  transition: all 0.45s ease;
  margin-bottom: 10px;
}
.facility-single2 .facility-content p,
.facility-single2 .facility-content .info-single .info-text a,
.info-single .info-text .facility-single2 .facility-content a {
  font-size: 17px;
  font-weight: 400;
  color: #5e5e5e;
  margin-bottom: 0px;
  transition: all 0.45s ease;
}
.facility-single2:hover .facility-content h4 {
  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;
}
.facility-single2:hover .facility-content p {
  font-size: 17px;
  font-weight: 400;
  color: #fff;
}

@media (max-width: 595.9px) {
  .section-title1 {
    margin-top: 30px;
    text-align: center;
  }
  .section-title1 h2 {
    font-size: 25px;
  }
}
.tour-category-sections .swiper {
  position: initial !important;
}
.tour-category-sections .swiper-button-prev,
.tour-category-sections .swiper-button-next {
  top: 400px !important;
}

/* Trending Page  */
.custom_heading {
  margin-top: 50px;
  font-size: 32px;
  text-align: center;
  font-weight: 700;
}
.text-b {
  color: #f8a500;
}

.trending-tour-textbb {
  font-size: 15px;
  text-align: center;
  margin-bottom: 20px;
}
.jckpt_box_prnt {
  gap: 15px;
  margin-top: 30px;
}
.align-items-center {
  align-items: center;
}
.d-flex {
  display: flex;
}
.text-center {
  text-align: center;
}
.jckpt_box .img_box {
  border-radius: 20px;
  overflow: hidden;
  max-height: 240px;
}
.hoverEff {
  position: relative;
  overflow: hidden;
}
.jckpt_box .img_box img {
  width: 100%;
}
.jckpt_box span {
  display: block;
  color: #000;
  font-size: 20px;
  margin-top: 15px;
  font-weight: 600;
}
.custom-heading-text {
  margin-bottom: 20px;
}
.jckpt_box {
  width: 19% !important;
  text-decoration: none;
}
@media (max-width: 595.98px) {
  .jckpt_box {
    width: 30% !important;
  }
  .custom_heading {
    font-size: 26px;
  }
}

@media ((max-width:1400px) and (min-width:1200px)){
  .jckpt_box {
    width: 18% !important;
  }
}


.tour-category-sections .swiper-button-next{
  right: 35px;
}

.tour-category-sections .swiper-button-prev{
  left: 35px;
}

.jckpt_box_prnt{
  justify-content: center;
}


