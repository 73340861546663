.flightListPage p,
.flightListPage label {
  font-size: 13px;
  margin-bottom: 0px;
}
.flightListPage {
  background-color: #f5f6ff9e !important;
  padding-top: 10px;
}

.flightSearchMain {
  margin-bottom: 50px;
  background-color: #e8f2fa;
}

.flightSearchMain2 {
  /* height: max-content; */
  /* margin: 10px; */
  margin-top: 10px;
  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: white;
  /* border: 1px solid #002543;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgb(230, 230, 255) 100%
  ); */
}

/* .flightSearchForm {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    align-items: center;
    height: 100%;
  } */

/* .flightSearchFormInner {
    width: 10%;
  } */

/* .flightSearchFormInner2 {
    width: 14%;
  } */

.flightSearchFormInput {
  padding: 5px 10px;
  width: 100%;
  border-radius: 5px;
  margin-top: 5px;
  border: 1px solid #002543;
}

/* 
  .flightSearchFormInner3 {
    width: 15%;
    margin-left: 10px;
  } */

/* .flightSearchFormInner4 {
    width: 12%;
  } */

.flightSearchFormBtn {
  width: 100%;
  background-color: #002543;
  color: white;
  /* padding: 10px 5px; */
  padding: 5px 10px;
  text-align: center;
  align-items: center;
  margin-top: 4px;
  border-radius: 10px;
}

.flightSearchFilter {
  display: flex;
}

.flightSearchFilter1 {
  padding-left: 70px;
  width: 20%;
}

.flightSearchFilter2 {
  margin-top: 25px;
  color: #002543;
}

.flightTable {
  width: 75%;
  margin-left: 40px;
  margin-top: 64px;
}

.flightTableHead {
  border-bottom: 1px solid black;
  margin-bottom: 40px;
}

.flightSeachTableRow1 {
  display: flex;
  gap: 8px;
  margin-top: 0px;
  align-items: center;
  margin-y: auto;
}

.flightSeachTableImg {
  width: 30px;
}

.flightSearchText1 {
  font-size: 13px;
}

.flightSearchText2 {
  font-size: 12px;
}

.flightSearchText3 {
  font-size: 20px;
  font-weight: 900;
}

.flightSearchText4 {
  font-size: 13px;
  margin-top: -3px;
}

.flightSearchText5 {
  font-size: 13px;
  margin-bottom: -7px;
}

.flightSearchtableInner5 {
  display: flex;
  align-items: center;
}


.flightHorizontal {
  margin: 5px !important;
  width: 120px;
}

.flightsMainBody {
  /* background-color: rgb(238, 238, 238); */
  height: 100%;
  margin-top: 10px;
  /* width: 100%; */
}

.searchDiv {
  height: 100px;
  /* width: 100%; */
  background-color: rgb(33, 150, 243);
  color: rgb(219, 215, 215);
}

.searchDiv > p {
  font-size: 25px;
  padding-top: 25px;
  margin-top: 0px;
}

.bodyDiv {
  /* width: 90%; */
  /* margin-top: 30px; */
  /* margin-left: 5%; */
  /* display: flex; */
  /* gap: 20px; */
  /* margin: 40px; */
  /* justify-content: space-between; */
  /* border: 1px solid grey; */
}

.filterDiv {
  /* width: 24%; */
  border-radius: 5px;
  /* border: 1px solid grey; */
  height: max-content;
  box-shadow: rgb(0 0 0 / 5%) 1px 1px 2px 1px, rgb(0 0 0 / 10%) 0px 2px 8px 0px;
  /* background-color: rgb(255,255,255); */
  background-color: white;
}

.applyFilter {
  display: none;
}

.formrow.row > * {
  padding-left: 2px;
  padding-right: 2px;
}
.arrow-md-lm {
  background-image: url("https://flight.easemytrip.com/Content/img/arow_main.png");
  background-repeat: no-repeat;
  height: 6px;
}
@media (max-width: 768px) {
  .filterDiv {
    position: fixed;
    /* top: 140px; */
    bottom: -15px;
    left: 0px;
    width: 100%;
    height: 85vh;
    overflow: auto;
    z-index: 999;
    background: #fff;
    box-shadow: 0px 2px 3px 0 rgba(0, 0, 0, 0.1);
    display: none;
    padding: 0px 0px 0px;
  }

  .applyFilter {
    /* display: block; */
  }

  .applyFilter.show {
    /* display: block; */
    position: fixed;
    bottom: 25px;
    right: 70px;
    background-color: #002543;
    color: #fff;
    /* width: 40px; */
    /* height: 40px; */
    border-radius: 25px;
    padding: 5px 10px;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.filterHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(255, 255, 255);
}

.filterHead > p:first-child {
  margin-left: 15px;
  font-size: 13px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 8px;
}

.filterHead > p:last-child {
  margin-right: 15px;
  font-size: 11px;
  color: rgb(33, 150, 243);
  margin-top: 10px;
}

.filterDivs {
  /* width: 100%; */
  /* height: 125px; */
  border-top: 1px solid rgb(228, 226, 226);
  background-color: rgb(255, 255, 255);
}

.filterDivs > p:last-child {
  font-size: 14px;
  margin-top: 5px;
  margin-left: 12px;
  color: rgb(51, 51, 51);
}

.filterSubDiv {
  display: flex;
  margin-bottom: 10px;
  align-items: center;

  /* justify-content: space-around; */
}

.filterSubDiv > img {
  width: 30px;
  height: 30px;
  margin-top: 10px;
  margin-left: 14px;
  color: rgb(102, 101, 101);
}

.filterSubDiv > p {
  margin-left: 12px;
  margin-right: 10px;
  margin-top: 15px;
  font-weight: 600;
  font-size: 14px;
  color: rgb(51, 51, 51);
}

.sliderParent input {
  width: 85%;
  height: 5px;
  outline: none;
  margin-left: 15px;
  /* padding-right: 10px; */
  color: rgb(33, 150, 243);
  background-color: #f3f3f3;
}

.sliderParent {
  position: relative;
  margin-top: -10px;
}

.bubble {
  position: absolute;
  font-size: 13px;
  /* margin-left: 10px; */
}

.stops {
  display: flex;
  height: 40px;
  /* width: 100%; */
  padding-top: 0px;
  margin-top: 0px;
  /* border: 1px solid black; */
}

.stops input {
  height: 18px;
  width: 18px;
  margin-left: 15px;
  margin-top: 20px;
}

/* .stops p {
    margin-left: 10px;
    padding-top: 0px;
    margin-top: 0px;
} */
.stopsDiv {
  /* height: 190px !important; */
  /* background-color: red; */
}

.flightIcon {
  height: 20px;
  width: 20px;
  margin-top: 18px;
  margin-left: 5px;
}

.flightIconDiv {
  /* min-height: 320px !important; */
  /* background-color: red; */
  min-height: max-content;
  margin-bottom: 20px;
}

.rightDiv {
  /* width: 78%; */
  /* height: 1200px; */
  /* border: 1px solid grey; */
}

.calendarSlider {
  /* height: 60px; */
  /* width: 100%; */
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgb(226, 226, 226);
  background-color: rgb(255, 255, 255);
}

.menuItems {
  /* width: 100%; */
  height: 40px;
  display: flex;
  font-size: 13px;
  /* margin-top: 10px; */
  font-weight: 400;
  /* margin-bottom: 10px; */
  color: rgb(51, 51, 51);
  justify-content: space-between;
  /* margin-top: 15px; */
  align-items: center;
}

.menuItems p {
  margin-bottom: 0;
}

.menuItems p:hover {
  cursor: pointer;
}

.toggleSwitch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
  border-radius: 20px;

  /* background-color: red; */
}

.toggleInput {
  opacity: 0;
  width: 0;
  height: 0;
  /* margin-top: 10px; */
}

.toggleSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  background-color: #ccc;
}

.toggleSlider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 3px;
  transition: 0.4s;
  border-radius: 50%;
  background-color: white;
}

.toggleInput:checked + .toggleSlider {
  background-color: rgb(33, 150, 243);
}

.toggleInput:checked + .toggleSlider:before {
  transform: translate(26px);
}

.flightDetailDiv {
  width: 100%;
  /* height: 150px; */
  border-radius: 5px;
  /* margin-bottom: 15px; */
  padding: 10px;
  /* border: 1px solid rgb(226, 226, 226); */
  /* background-color: rgb(255, 255, 255); */
  /* box-shadow: 0px 0px 5px rgb(199, 199, 199); */
}

.card:hover {
  box-shadow: 0px 0px 8px rgb(199, 199, 199);
}

.flightDetails {
  /* height: 60%; */
  width: 100%;
  margin-bottom: 0px;
  /* border: 1px solid red; */
  display: flex;
  gap: 25px;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none;
  justify-content: space-between !important;
  align-items: center !important;
}

.flightImage {
  /* width: 120px; */
  height: 100%;
  align-items: center;
  /* padding-left: 15px; */
  padding-right: 0px;
  display: flex;
  flex-wrap: wrap;
  /* border: 1px solid black; */
  justify-content: space-between;
}

.flightImage > img {
  /* padding: 10px; */
  height: 32px;
  margin-right: 5px;
  /* width: 50px; */
  /* margin-top: 15px; */
}

.flightImage > div > p:first-child {
  font-size: 13px;
  line-height: 0.5cm;
  /* margin-top: 20px; */
}

.flightImage > div > p:last-child {
  font-size: 11px;
  color: rgb(136, 135, 135);
}

.departDetails {
  /* width: 100px; */
  /* margin-left: 45px; */
  /* text-align: center; */
  /* background-color: hotpink; */
  line-height: 0.6cm;
}
.departDetails p {
  margin: 0px;
}

.departDetails > p:first-child {
  font-size: 18px;
  font-weight: bold;
  /* padding-left: 10px; */
  /* margin-top: 15px; */
}

.departDetails > div:last-child {
  color: rgb(136, 135, 135);
  padding-left: 10px;
}

.durationDetails {
  /* width: 150px; */
  line-height: 0.5cm;
  text-align: center;
  /* margin-left: 40px; */

  /* background-color: indigo; */
}
.durationDetails p {
  margin: 0px;
}
.durationDetails > p:last-child {
  color: rgb(136, 135, 135);
  font-size: 13px;
  /* margin-top: 5px; */
}

.arriveDetails {
  /* width: 120px; */
  /* margin-left: 45px; */
  text-align: center;
  line-height: 0.6cm;
  /* background-color: khaki; */
}
.arriveDetails p {
  margin: 0px;
}

.arriveDetails > p:first-child {
  font-size: 18px;
  font-weight: bold;
  /* margin-top: 18px; */
}

.arriveDetails > p:last-child {
  color: rgb(136, 135, 135);
}

.priceDetails {
  /* width: 120px; */
  /* line-height: 0cm; */
  /* margin-left: 40px; */
  text-align: center;
  font-size: 12px;
  /* background-color: lawngreen; */
}

.priceDetails > button {
  height: 30px;
  width: 100px;
  /* margin-left: -20px; */
  border-radius: 20px;
  /* margin-top: 0px; */
  color: rgb(33, 150, 243);
  background-color: white;
  border: 1px solid rgb(33, 150, 243);
  text-wrap: nowrap;
}

.priceDetails > div > img {
  /* margin-left: 25px; */
  /* width: 15px; */
  height: 15px;
  /* margin-top: 26px !important; */
  color: #002543 !important;
}

.priceDetails > div > p {
  color: #002543;
  font-weight: bold;
  font-size: 18px;
  /* margin-top: 18px; */
  margin-bottom: 5px;
  margin-left: 8px;
}

.bookButton {
  /* height: 36px; */
  /* width: 120px; */
  width: 100%;
  padding: 10px 15px;
  color: white;
  border: none;
  font-weight: 600;
  border-radius: 7px;
  /* margin-left: 80px; */
  /* margin-top: 30px; */
  background-color: #002543;
}

.offPriceDiv {
  /* width: 55%; */
  /* height: 18%; */
  width: 100%;
  /* margin-left: 15px; */
  padding-top: 0px;
  padding-bottom: 0px;
  /* padding-bottom: 0px; */
  background-color: rgb(255, 251, 237);
  border-left: 3px solid rgb(239, 220, 156);
}

.offPriceDiv > p {
  padding-top: 5px;
  /* padding-top: 0px; */
  padding-bottom: 0px;
  /* margin-bottom: 0px; */
  font-size: 14px;
  padding-left: 5px;
}

.lastDetailDiv {
  width: 100%;
  /* height: 17%; */
  margin-top: 2px;
  margin-bottom: 0px;
  background-color: rgb(249, 249, 249);
}

.lastDetailDiv > p {
  /* padding-top: 0px; */
  /* padding-left: 15px; */
  font-size: 14px;
  color: rgb(33, 150, 243);
}

.lastDetailDiv > p:hover {
  text-decoration: underline;
  cursor: pointer;
}

.sliderLeft {
  height: 100%;
  /* width: 92%; */
  display: flex;
  /* flex-wrap: nowrap;
    overflow-x: auto;
    scrollbar-width: thin; */
  align-items: center;
  justify-content: space-between !important;
  /* border: 1px solid black; */
}

.sliderRight {
  height: 100%;
  /* width: 8%; */

  align-items: center;
  display: flex;
  justify-content: center;
  /* border: 1px solid rgb(228, 228, 228); */
}

.sliderRight > img {
  /* height: 30px; */
  /* width: 30px; */
  /* text-align: center; */
  /* margin-top: 13px; */
  /* margin-left: 10px; */
}

.calenderData {
  display: flex;
}

/* .calenderData>img {
    height: 12px;
    width: 12px;
    margin-left: 32px;
    margin-top: 18px;
} */

.calenderData > p {
  color: #002543;
  /* line-height: 0cm; */
  margin-top: 8px;
  font-size: 14px;
  margin-left: 32px;
}

@media (max-width: 768px) {
  .mbsm {
    margin-bottom: 15px !important;
  }

  /* .calenderslidercontent{
        font-size: 15px !important;
    }
    .calenderprice{
        font-size: 12px !important;
    } */
}

/* 
@media (max-width:550px){
    .calenderslidercontent{
        font-size: 10px !important;
    }

    .calenderprice{
        font-size: 8px !important;
    }
} */

.datesdiv {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: calc(100% - 96px);
  scrollbar-width: thin;
}

.flightDetailsDepartureDetails {
  display: flex;
  width: 40%;
  justify-content: space-between;
  align-items: center;
}

.flightBookingDetailPrice {
  display: none;
}

@media (max-width: 586px) {
  .flightImage > img {
    width: 30px !important;
    height: 30px;
  }

  .flightImage > div > p:first-child {
    font-weight: 600;
    display: flex;
    flex-direction: row;
  }

  .flightDetails {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: normal !important;
    flex-wrap: nowrap;
    gap: 4px;
    /* justify-content: space-between; */
  }

  .flightInerPhotoComp {
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
    /* align-items: center; */
    justify-content: space-between;
  }

  .flightBookingDetailslightNumer {
    font-size: 12px;
    font-weight: 600;
  }

  .flightImage {
    display: flex;
    flex-wrap: nowrap;
    /* justify-content: space-between !important; */
    gap: 45px;
  }

  .flightImage > div > p:last-child {
    font-size: 12px;
    font-weight: 600;
    color: black;
  }

  /* .lastDetailDiv>p:hover {
        margin-top: -30px;
        margin-left: -12px;
    } */

  .flightImage > div > p:first-child {
    font-size: 12px;
  }

  .flightDetailsDepartureDetails {
    width: 100%;
    display: flex;
    justify-content: space-between !important;
  }

  .flightBookingMoreFare {
    display: none;
  }

  .FlightBookingLinkTag {
    width: 100%;
    text-align: end;
    padding: 0px;
    margin: 0px;
  }

  .priceDetails > div > p {
    display: none;
    font-size: 15px;
    width: 100%;
    text-align: end;
    margin-right: 10px;
  }

  .bookButton {
    width: 31%;
    padding: 5px 5px;
    /* padding: 0px 0px; */
    margin: 0px;
    color: white;
    border: none;
    font-size: 9px;
    font-weight: 600;
    border-radius: 5px;
    /* margin-left: 80px; */
    /* margin-top: 30px; */
    /* text-align: end; */
    background-color: #002543;
  }

  .durationDetails {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: normal;
    height: max-content !important;
  }

  .flightHorizontal {
    /* width: 76px; */
    margin: -10px !important;
  }

  .durationDetails > p:last-child {
    margin-top: 8px;
  }

  .departDetails > p:first-child {
    font-size: 15px;
    text-align: start;
  }

  .arriveDetails > p:first-child {
    font-size: 15px;
    text-align: end;
  }

  .departDetails > div:last-child {
    padding: 0px;
    margin-top: -14px;
  }

  /* .arriveDetails > p:last-child {
    margin-top: -14px;
  } */

  .flightDetailDiv {
    padding: 0px;
    padding-top: 10px;
  }

  .offPriceDiv {
    display: none;
    width: 60%;
    margin-top: -50px;
  }

  .lastDetailDiv {
    background-color: transparent;
    margin-top: -30px;
    margin-left: -15px;
  }

  .offPriceDiv > p {
    font-size: 10px;
  }

  .flightBookingDetailPrice {
    display: block;
  }
}

#modal-newb2b {
  display: none;
  position: fixed;
  z-index: 9999;
  padding-top: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  /* background-color: rgb(0, 0, 0); */
  background-color: rgba(0, 0, 0, 0.6);
}

.morefarediv {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0 !important;
  overflow-x: hidden;
  transition: 0.8s;
  padding-top: 0px;
}

.morefarediv .modal-content-newb2b {
  background-color: #fefefe;
  margin: auto;
  width: 75%;
  border-radius: 0;
  position: absolute;
  right: 0;
  height: 100vh;
}

.morefarediv .flx {
  display: flex;
}

.morefarediv ._lopogt_nhy {
  border-bottom: 1px solid #ccd5dc;
  padding: 5px 20px;
  margin-bottom: 0px;
  justify-content: space-between;
  display: flex;
}

.morefarediv ._newrthed {
  font-size: 19px;
  font-weight: 600;
}

.morefarediv .close-newb2b {
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
}

.morefarediv ._nhty {
  padding: 10px 20px 0;
  width: 100%;
  background: #fff;
}

.morefarediv ._newdiv-left {
  width: 100%;
}

.morefarediv ._newdiv-left:last-child {
  border: none;
}

.morefarediv .acntr {
  align-items: center;
}

.morefarediv ._bgcls {
  background: #edeeff;
  border: 1px solid #002543;
  border-radius: 8px;
  padding: 5px;
  margin-bottom: 6px;
  display: flex;
}

.morefarediv ._airlilogo {
  margin-right: 10px;
}

.morefarediv ._airlilogo img {
  width: 25px;
  border-radius: 5px;
}

.morefarediv ._detislsnp {
  width: 70%;
  font-size: 13px;
}

.morefarediv ._detislsnp span {
  font-size: 17px;
  font-weight: 600;
}

.morefarediv .marg-left {
  margin-left: auto;
}

.morefarediv ._onwdr {
  background: #fff6e4;
  padding: 6px;
  font-weight: 600;
  font-size: 13px;
  border-radius: 5px;
  display: none;
}

.morefarediv ._fltDtlsd {
  background: #fff;
  padding: 6px 10px;
  font-weight: 600;
  font-size: 13px;
  border-radius: 5px;
  display: none;
  color: #002543;
  border-radius: 6px;
  border: 1px solid #002543;
  line-height: 21px;
  margin-left: auto;
  cursor: pointer;
}

.morefarediv .dwnarrowfl {
  border: solid #002543;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: relative;
  top: -2px;
  margin-left: 7px;
}

.morefarediv ._bxses {
  overflow-x: auto;
  margin-top: 12px;
  height: calc(100vh - 210px);
}

.morefarediv .al_flx_opt {
  width: 100%;
  display: flex;
}

.morefarediv label {
  font-weight: unset;
}

.morefarediv .al_flx_opt label {
  display: flex;
  flex: 0 0 32%;
  border: none;
  margin-right: 15px;
}

.morefarediv .al_flx_opt label:last-child {
  margin-right: 0;
}

.morefarediv .pls_bxs {
  width: 100%;
  border: 1px solid #e6ecef;
  border-radius: 8px;
  position: relative;
  min-height: 360px;
}

.morefarediv .actve {
  border: 1px solid #002543 !important;
  background: #f8fbfd;
}

.morefarediv .upr_sctn {
  border-bottom: 1px solid #dcdcdc;
  padding: 5px 15px;
}

.morefarediv .flx_p {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.morefarediv .dtl_plus {
  width: 80%;
}

.morefarediv .optn_head {
  width: 100%;
  display: flex;
}

.morefarediv .optn_head span {
  font-size: 15px;
  font-weight: 600;
  color: #002543;
  text-transform: uppercase;
}

.morefarediv .acntr {
  align-items: center;
}

.morefarediv .fflx {
  display: flex;
}

.morefarediv .prce_mn {
  font-weight: 600;
  font-size: 13px;
}

.morefarediv .prce_mn {
  font-size: 20px;
  color: #000;
  font-weight: 600;
}

.morefarediv .discbxd {
  display: inline-block;
  font-size: 12px;
  padding: 0px 5px;
  border-radius: 4px;
  font-weight: 600;
  color: #02bb91;
  float: left;
  width: 100%;
  text-align: left;
  padding: 0;
  margin-top: 2px;
  margin-bottom: 2px;
}

.morefarediv .slct_optn {
  width: 13%;
}

.morefarediv .conta-radio {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  color: #d63b05;
  font-size: 16px;
  user-select: none;
  margin-left: 8px;
}

.morefarediv .al_flx_opt label {
  display: flex;
  flex: 0 0 32%;
  border: none;
  margin-right: 15px;
}

.morefarediv .al_flx_opt label:last-child {
  margin-right: 0;
}

.morefarediv input[type="checkbox"],
.morefarediv input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

.morefarediv input[type="checkbox"],
.morefarediv input[type="radio"] {
  line-height: normal;
  margin: 4px 0 0;
}

.morefarediv .conta-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.morefarediv .checkk-radio {
  position: absolute;
  top: 6px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  right: 0px;
  border: 2px solid #e6ecef;
}

.morefarediv .conta-radio input:checked ~ .checkk-radio {
  background-color: #ffffff;
  border: 1px solid #002543;
}

.morefarediv .checkk-radio:after {
  content: "";
  position: absolute;
  display: none;
}

.morefarediv .conta-radio .checkk-radio:after {
  top: 2px;
  left: 2px;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: #002543;
}

.morefarediv .conta-radio input:checked ~ .checkk-radio:after {
  display: block;
}

.morefarediv .faclty {
  width: 100%;
  padding: 10px 15px 0;
  height: 245px;
  overflow-y: auto;
}

.morefarediv .fjsb {
  justify-content: space-between;
}

.morefarediv ._fcltechdt {
  margin-bottom: 10px;
}

.morefarediv ._fcltechdt i {
  width: 30px;
}

.morefarediv ._dsrcp_rpt {
  width: 90%;
}

.morefarediv .fclm {
  flex-direction: column;
}

.morefarediv ._tpbldtle {
  font-size: 13px;
  font-weight: 700;
}

.morefarediv .faclty ul {
  margin: 0px 0 0 15px;
  padding: 0;
}

.morefarediv ._cabinfcnl {
  font-size: 12px;
  font-weight: normal;
}

.morefarediv .faclty ul li {
  list-style: circle;
  font-size: 12px;
}

.morefarediv .al_flx_opt {
  width: 100%;
  display: flex;
}

.morefarediv .pd15 {
  padding: 0 15px;
}

.morefarediv .sclted_bt {
  width: 90%;
  position: absolute;
  bottom: 13px;
  left: 15px;
}

.morefarediv .sclted_bt a {
  color: #fff;
  display: block;
  padding: 7px;
  border-radius: 25px;
  background: #002543;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
}

.morefarediv ._btmftrdv {
  width: 75%;
  margin-top: 25px;
  position: fixed;
  bottom: 0;
}

.morefarediv ._emtfxder {
  padding: 10px 20px;
  background: #fff;
  width: 100%;
  box-shadow: 0 -5px 4px -4px #ccc;
  display: flex;
  justify-content: space-between;
}

.morefarediv ._btnm {
  width: 100%;
  display: flex;
}

.morefarediv ._nhyuop {
  width: 40%;
  display: flex;
  align-items: center;
}

.morefarediv ._prgty {
  color: #2f2f2f;
  font-size: 14px;
  margin-right: 10px;
}

.morefarediv .f30 {
  font-size: 28px;
  font-weight: 600;
  color: #002543;
}

.morefarediv .marg-left {
  /* margin-left: auto; */
}

.morefarediv .book-bt-nwap {
  background-color: #002543;
  text-shadow: none;
  color: #fff;
  outline: none;
  text-align: center;
  padding: 10px 35px;
  border-radius: 40px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  display: block;
}

.morefarediv .clr {
  clear: both;
}

.morefarediv ._rfndle {
  color: #2dca1c;
  border: 1px solid #2dca1c;
  font-size: 10px;
  font-weight: 500;
  padding: 2px 5px;
  display: inline-block;
  border-radius: 40px;
}

.flightListPage .pag1 {
  width: 100%;
  float: left;
  display: flex;
  text-align: center;
  justify-content: center;
}

.flightListPage .wid33 {
  flex: 1;
  max-width: 31.3%;
  margin: auto;
}

.flightListPage .pa11,
.flightListPage .pa22,
.flightListPage .pa33 {
  width: 100%;
  margin: auto;
  background: #fff;
  border: 1px solid #e0e0e0;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  float: left;
}

.flightListPage .blue {
  background: #002543 !important;
  color: #fff !important;
}

.flightListPage .sb_flt {
  text-align: center;
  font-size: 10px;
  display: block;
  padding: 0 !important;
  margin: 5px 0 !important;
  font-weight: 400 !important;
}

.flightListPage .prc_val {
  width: 100%;
  float: left;
}

.flightListPage .tm-dt1,
.flightListPage .tm-dt11 {
  width: 100%;
  height: auto;
  border: 1px solid #e0e0e0;
  float: left;
  margin: 5px 0;
  background: #fff;
  border-radius: 5px;
}

.flightListPage .tm11,
.flightListPage .tm111 {
  width: 25%;
  float: left;
  border-right: 1px solid #e0e0e0;
  cursor: pointer;
}

.flightListPage .tm-m11,
.flightListPage .tm-m111 {
  width: 100%;
  float: left;
  padding: 4px 0;
  cursor: pointer;
  border-radius: 5px 0 0 5px;
}

.flightListPage .mor-n1,
.flightListPage .mor-n11 {
  margin: 0 auto;
  width: 28px;
  height: 25px;
  background: url(https://flight.easemytrip.com/Content/CSS/filter-sprite.png);
  background-position: -5px -42px;
}

.flightListPage .mor1-n2,
.flightListPage .mor1-n22 {
  margin: 0 auto;
  width: 24px;
  height: 25px;
  background: url(https://flight.easemytrip.com/Content/CSS/filter-sprite.png);
  background-position: -38px -43px;
}

.flightListPage .mor2-n3,
.flightListPage .mor2-n33 {
  margin: 0 auto;
  width: 20px;
  height: 25px;
  background: url(https://flight.easemytrip.com/Content/CSS/filter-sprite.png);
  background-position: -66px -42px;
}

.flightListPage .mor3-n4,
.flightListPage .mor3-n44 {
  margin: 0 auto;
  width: 16px;
  height: 25px;
  background: url(https://flight.easemytrip.com/Content/CSS/filter-sprite.png);
  background-position: -95px -43px;
}

.flightListPage .tm-nf1,
.flightListPage .tm-nf11 {
  width: 100%;
  float: left;
  color: #737373;
  font-size: 10px;
  text-align: center;
}

.flightListPage .tm-m12,
.flightListPage .tm-m122,
.flightListPage .tm-m222 {
  width: 100%;
  float: left;
  padding: 4px 0;
  cursor: pointer;
}

.flightListPage .tm-nf2,
.flightListPage .tm-nf22 {
  width: 100%;
  float: left;
  color: #737373;
  font-size: 10px;
  text-align: center;
}

.flightListPage .tm22,
.flightListPage .tm222 {
  width: 25%;
  float: left;
  border-right: 1px solid #e0e0e0;
  cursor: pointer;
}

.flightListPage .tm33,
.flightListPage .tm333 {
  width: 25%;
  float: left;
  border-right: 1px solid #e0e0e0;
  cursor: pointer;
}

.flightListPage .tm-m22,
.flightListPage .tm-m22 {
  width: 100%;
  float: left;
  padding: 4px 0;
  cursor: pointer;
}

.flightListPage .tm-nf3,
.flightListPage .tm-nf33 {
  width: 100%;
  float: left;
  color: #737373;
  font-size: 10px;
  text-align: center;
}

.flightListPage .tm11-n {
  width: 25%;
  float: left;
}

.flightListPage .tm-m33,
.flightListPage .tm-m333 {
  width: 100%;
  float: left;
  padding: 4px 0;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
}

.flightListPage .tm-nf4,
.flightListPage .tm-nf44 {
  width: 100%;
  float: left;
  color: #737373;
  font-size: 10px;
  text-align: center;
}

.flightListPage .twh {
  color: #fff !important;
}

.flightListPage .blue .mor-n11,
.flightListPage .blue .mor1-n22,
.flightListPage .blue .mor2-n33,
.flightListPage .blue .mor3-n44,
.flightListPage .blue .mor-n1,
.flightListPage .blue .mor1-n2,
.flightListPage .blue .mor2-n3,
.flightListPage .blue .mor3-n4 {
  filter: brightness(0) invert(1);
}

.flightListPage .box-content .rc-slider {
  width: 87%;
  margin: auto;
}
.flightListPage .box-content .slider-value {
  margin: 0px 10px;
  align-items: start !important;
  display: flex;
  justify-content: space-between;
}

.flightListPage .rc-slider-track,
.flightListPage .rc-slider-tracks,
.flightListPage .rc-slider-handle {
  background-color: #002543 !important;
  border: 1px solid #002543 !important;
}

@media (min-width: 768px) {
  .applyfilter {
    display: none !important;
  }
}
