.intineraryMainDiv button,
.intineraryMainDiv p {
  line-height: normal;
}
.intineraryMainDiv {
  margin-top: 16px;
  margin-bottom: 20px;
}
.intineraryMainhead {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
}
.itinerarysecDiv {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

  border-radius: 12px;
}
.itinerarysecDivbtn1 {
  display: flex;
  width: 100%;
  /* margin-top: 8px; */
  padding: 10px 10px;
  margin-bottom: 10px;
  border: none;
  background-color: #ebf2fa;
}
.itinerarysecDiv2 {
  display: flex;
  gap: 12px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.itinerarysecDiv2para1 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  font-family: 500;
  width: 90px ;
  background-color: #18477b;
  padding: 7px 18px 7px 15px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.itinerarysecDiv2para2 {
  font-size: 15px;
  font-weight: 500;
  text-align: start;
  text-wrap: wrap;
  /* word-spacing: 10px; */
}
.itinerarysecDiv2para3 {
  font-size: 14px;
  padding:10px;
  color: rgb(53, 53, 53);
  word-spacing: 5px;
}
.itineraryInclusion {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 600;
}
.itineraryInclusionUnder {
  border-radius: 15px;
  padding: 20px !important;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  gap: 8px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}
.itineraryInclusionUnderss {
  border-radius: 15px;
  padding: 20px 20px 20px 40px !important;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  gap: 8px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}
.itineraryInclusionInnerDiv {
  display: flex;
  gap: 4px;
}
.itineraryInclusionInnerDiv span {
  color: #0c11a3;
}
.itineraryInclusionInnerDivs {
  display: flex;
  gap: 4px;
}
.itineraryInclusionInnerDivs span {
  color: rgb(10, 99, 10);
  font-size: 22px;
}
.itineraryHotelDetailMainHead {
  width: 100%;
}
.itineraryHotelDetailHead {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 16px;
}
.itineraryHotelDetailMain {
  padding: 20px;
  border-radius: 12px;
  display: flex;
  gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.itineraryHotelDetailMain2 {
  width: 34%;
}
.itineraryHotelDetailMain3 {
  width: 66%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 20px;
}
.itineraryHotelDetailMain3Inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.itineraryHotelDetailMain3Innerh3 {
  font-weight: 600;
  font-size: 15px;
}
.itineraryHotelDetailpara1 {
  font-size: 13px;
  background-color: #337ab72b;
  color: #337ab7;
  padding: 1px 12px;
  border-radius: 12px;
}
.itineraryHotelDetailparaIn {
  display: none;
}
.itineraryHotelDetailStar {
  display: flex;
  gap: 4px;
}
.itineraryHotelDetailpara3 {
  font-size: 13px;
}
.ItineraryHotelDetailMainImage {
  width: 100%;
}
@media (max-width: 787px) {
  .ItineraryHotelDetailMainImage {
    height: 150px;
    object-fit: cover;
  }
  .itineraryHotelDetailMain2 {
    width: 100% !important;
    /* height: 150px !important; */
  }
  .itineraryHotelDetailMain3 {
    width: 100%;
    gap: 10px;
  }
  .itineraryHotelDetailMain {
    flex-direction: column;
  }
  .itineraryHotelDetailpara1 {
    display: none;
  }
  .itineraryHotelDetailparaIn {
    display: inline-block;
    /* text-align: end; */
    font-size: 13px;
    background-color: #337ab72b;
    color: #337ab7;
    padding: 1px 30px;
    border-radius: 12px;
  }
}
