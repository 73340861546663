.offerpage {
  margin-block: 20px;
}
._top_innr {
  width: 1150px;
  margin: auto;
  margin-bottom: 20px;
}
._top_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
._heading {
  font-size: 22px;
  font-weight: 600;
}
._offrgrid {
  margin: 15px 0;
  /* display: grid; */
  /* grid-template-columns: repeat(4, 1fr); */
  /* gap: 30px 4%; */
  width: 100%;
  float: left;
}
._offrbx {
  width: 100%;
  float: left;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 15%);
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: #000;
}
._imgbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
._imgbox img {
  width: 100%;
  display: flex;
  border-radius: 10px;
}
._dealName {
  font-weight: 600;
  margin: 0px 0px;
  font-size: 16px;
  color: #333333;
  margin-top: 8px;
}
._dealDtl {
  margin-top: 2px;
  font-size: 13px;
  display: flex;
  width: 100%;
}
._mflxp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
._bookng_prd {
  font-size: 10px;
  text-align: left;
  margin-right: auto;
  margin-top: 10px;
}
.promcolm {
    border: 1px dashed #A7A7A7;
    border-radius: 4px;
    display: inline-flex;
    position: relative;
    margin-top: 15px;
    height: 32px;
    background: #fff;
}
.promcde {
    background: #2196f3;
    border-radius: 20px;
    text-align: center;
    padding: 1px 5px;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: -11px;
    left: 7px;
}
.coupncde {
    font-size: 13px;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
    padding: 6px 8px;
    display: flex;
    border-right: 1px dashed #A7A7A7;
}
.copycol {
    padding: 6px 5px;
    cursor: pointer;
}
.copycol img {
    min-width: 15px;
}