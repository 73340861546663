.detailCardMainDiv p {
  margin: 0px;
}
.detailCardMainDiv {
  /* background-color: #002543; */
  background: url(https://triprex.egenslab.com/wp-content/plugins/triprex-core//inc/theme-options/images/breadcrumb/inner-banner-bg.jpg);
  background-position: center;
  height: 6.5 rem;
  display: flex;
  justify-content: space-between;
  padding: 30px 100px 30px 100px;
}
.detailCardMainDivInner1 {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.detailCardMainDivInner1 h2 {
  color: white;
  font-size: 24px;
  font-weight: 500;
}
.detailCardMainDivInner1para1 {
  font-size: 14px;
  color: white;
}
.detailCardMainDivInner1para2 {
  font-size: 18px;
  color: #fff;
  width: max-content;
  /* background-color: white; */
  /* padding: 1px 8px 4px 8px; */
}
.detailCardMainDivInner2 {
  display: flex;
  flex-direction: column;
  gap: 3px;
  color: white;
  align-items: end;
  font-weight: 500;
}
.detailCardMainDivInner2p1 {
  font-size: 14px;
}
.detailCardMainDivInner2p2 {
  font-size: 24px;
}
.detailCardMainDivInner2p3 {
  font-size: 16px;
}
.detailCardMainDivInner3 {
  background-color: #336d850d;
  padding: 16px 120px;

  margin-bottom: 80px;
}
.detailCardMainDivInner3head {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (max-width: 1200px) {
  .detailCardMainDiv {
    padding: 10px 50px 10px 50px;
  }
}
@media (max-width: 450px) {
  .detailCardMainDiv {
    padding: 10px 10px 10px 10px !important;
  }
  .detailCardMainDivInner1para2 {
    /* margin-left: 50px !important; */
    padding: 2px 2px 2px 2px;
  }
  .detailCardMainDivInner2p3 {
    margin-left: 50px !important;
  }
}
@media (max-width: 786px) {
  .detailCardMainDiv {
    padding: 10px 50px 10px 50px;
  }
}
@media (max-width: 1351px) {
  .detailCardMainDivInner3 {
    padding: 0px 0px;
  }
}
