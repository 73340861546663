.inquirymodal .modal-title {
  color: #fff;
}

.pkgform-wrapper .pkgform-box {
  background: #fff;
  padding: 20px 15px;
}
#inquirymodal .pkgform-wrapper .pkgform-box {
  background: transparent;
  padding: 0;
}
.pkgform-wrapper .pkgform-box input,
.pkgform-wrapper .pkgform-box select,
.pkgform-wrapper .pkgform-box textarea {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  color: #555;
  margin-bottom: 20px;
  padding: 6px;
  font-size: 14px;
  line-height: 18px;
}
#inquirymodal .pkgform-wrapper .pkgform-box input,
#inquirymodal .pkgform-wrapper .pkgform-box select,
#inquirymodal .pkgform-wrapper .pkgform-box textarea {
  box-shadow: none;
  margin-bottom: 20px;
}
#inquirymodal button[type="button"] {
  margin-top: 0;
  cursor: pointer;
  font-weight: 500;
  border: none;
  border-radius: 3px;
  transition: all 0.2s;
  width: auto;
  color: #fff !important;
  background-color: #9dc541;
  height: auto;
  padding: 6px 35px;
  line-height: 28px;
}
/* @media (min-width: 576px) {
  .inquirymodal .modal-dialog {
    max-width: 650px !important;
  }
} */
.modal-dialog {
  max-width: 650px !important;
}

