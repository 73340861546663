/* .container-fluid {
    width: 100%;
    padding-right: 7.5px;
    padding-left: 7.5px;
    margin-right: auto;
    margin-left: auto;
} */

.content-wrapper {
    min-height: 80vh;
    margin: 15px;
}


.content-wrapper .text-dark {
    color: #2d3290 !important;
    font-size: 1.8rem;

}

.content-wrapper .card {
    margin: 15px;
    border: none;
    box-shadow: 0 0 1px rgba(233, 235, 255, .125), 0 1px 3px rgba(233, 235, 255, .2);
}

/* .card-header{
    border: none;
} */


/* .row {

    display: flex;

    flex-wrap: wrap;
    margin-right: -7.5px;
    margin-left: -7.5px;
} */

/* .m-0 {
    margin: 0 !important;
} */

/* .content-header h1 {
    font-size: 1.8rem;
    margin: 0;
} */

/* .col-sm-6,
.col-md-12 {
    position: relative;
    width: 100%;
    padding-right: 7.5px;
    padding-left: 7.5px;
} */

.content-wrapper .content-header .breadcrumb {
    background: 0 0;
    line-height: 1.8rem;
    margin-bottom: 0;
    padding: 0;



    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e8e8fd;
    border-radius: .25rem;
}

.content-wrapper .float-sm-right {
    float: right !important;
}

.content-wrapper a:hover,
.content-wrapper a,
.content-wrapper .breadcrumb li a {
    color: #000;
}

.content-wrapper a {
    /* color: #37b1c2; */
    text-decoration: none;
    background-color: transparent;
}

/* .breadcrumb-item.active {
    color: #6c757d;
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/";
} */

/* .content-wrapper>.content {
    padding: 0 .5rem;
} */

/* .card {
    box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
    margin-bottom: 1rem;
} */

/* .card {
    position: relative;

    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, .125);
    border-radius: 0.25rem;
} */

/* .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    padding: .75rem 1.25rem;
    position: relative;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
} */

.content-wrapper .cus_user_tags {
    /* display: inline-block; */
    display: flex;
}

.content-wrapper .cus_user_tags .list-group {
    display: flex;
    /* Change to flex */
    flex-direction: row !important;
    flex-wrap: wrap;
    /* Allow wrapping */
}

.content-wrapper .cus_user_tags .list-group .cus_tag {
    display: inline-flex;
    box-shadow: 0 0 3px rgb(233 235 255 / 11%);
    gap: 8px;
    border-radius: 4px;
    align-items: center;
    margin: 5px;
}

.content-wrapper .cus_user_tags .list-group .success_tag span.span_tag {
    background: #008000;
}

.content-wrapper .cus_user_tags .list-group .failed_tag span.span_tag {
    background: #e3111a;
}

.content-wrapper .cus_user_tags .list-group .pending_tag span.span_tag {
    background: #ffb22b;
}

.content-wrapper .cus_user_tags .list-group .cus_tag span.span_tag {
    color: #fff;
    font-size: 14px;
    line-height: 24px;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    padding: 0px 8px;
    border-radius: 4px;
}

.content-wrapper .cus_user_tags .list-group .cus_tag span.tag_label {
    font-size: 15px;
    line-height: 21px;
    font-weight: 400;
    color: #000;
    display: block;
    /* vertical-align: bottom; */
}



/* .cus_user_tags .list-group .cus_tag:last-child {
    margin-right: 0px;
} */



.content-wrapper .card_custom_menu ul,
.print_export ul {
    list-style-type: none;
    padding-left: 0px;
    margin: 0px;
}

.content-wrapper .card_custom_menu ul li.active a,
.card_custom_menu ul li a:hover {
    background: #2d3290;
    color: #fff;
    border-color: #2d3290;
}

.content-wrapper .card_custom_menu ul li {
    margin: 5px;
}

.content-wrapper .card_custom_menu ul li a {
    color: #2d3290;
    border: 1px solid #cfcfd2;
    display: block;
    background: #f0f1ff;
    border-radius: 3px;
    transition: .2s all ease;
}

.content-wrapper .card_custom_menu ul li a {
    font-size: 14px;
    line-height: 21px;
    padding: 5px 12px;
}


/* .card_custom_menu ul{
    display: flex !important;
    justify-content: flex-start !important;
    flex-wrap: wrap;
} */

/* .filter_btn{
    margin-left: auto !important;
} */

/* .card_custom_menu:last-child{
    margin-left: auto;
    margin-right: 0;
} */

.content-wrapper .full-container {
    width: 100%;
    display: flex;
    justify-content: space-between !important;
}

.content-wrapper .custom-menu-container {
    display: flex !important;
    justify-content: flex-start !important;
    flex-wrap: wrap;
}


/* .content-wrapper .card_tools {
    text-align: right;
    margin-bottom: 1200px;
}

.card-tools {
    margin-right: 0px;
}

.card-tools {
    float: right;
    margin-right: -0.625rem;
}

.content-wrapper .card_tools a {
    display: inline-block;
} */

/* .btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
} */

/* .btn-theme {
    background: #a1c849;
    border-color: #a1c849;
    color: #fff;
} */

.content-wrapper .btn-theme,
.content-wrapper .btn-theme:hover,
.content-wrapper .btn-theme:focus {
    color: #fff;
    background-color: #2d3290;
    border-color: #2d3290;
}



/* .card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
} */


.content-wrapper .filter-icon {
    font-weight: 900;
    font-size: 15px;
}

.content-wrapper .eye-icon {
    color: #2d3290;
    font-weight: 900;
    font-size: 18px;
}

.content-wrapper .action-icon {
    color: #2d3290;
    font-weight: 900;
    font-size: 18px;
}



.content-wrapper .filter_panel {
    background: #edeeff;
    margin-bottom: 10px;
    border: 1px solid #e7e7e7;
}

.content-wrapper .filter_panel h4 {
    color: #2d3290;
}

.content-wrapper .card .card-body .filter_panel {
    padding: 20px;
}



/* .form-group {
    margin-bottom: 10px;
} */

/* label:not(.form-check-label):not(.custom-file-label) {
    font-weight: 500;
    font-size: 14px;
} */

/* .col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
} */

/* .form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 transparent;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
} */

/* .ui-datepicker-trigger {
    display: none;
} */

/* .card .card-body .form-group input, .card .card-body .form-group select, .card .card-body .form-group textarea {
    font-size: 14px;
    line-height: 21px;
} */

/* .card .card-body .form-group input, .card .card-body .form-group select {
    height: 34px;
} */

/* .text-center {
    text-align: center !important;
} */

/* .search-btn{
    text-transform: uppercase;
} */

.content-wrapper .search-filter-btn {
    margin-top: 10px;
}




/* .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    background-color: transparent;
} */

/* .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling:touch;
} */

/* .text-nowrap {
    white-space: nowrap !important;
} */

.content-wrapper .table-bordered {
    border: 1px solid #e2e2e2;
}

/* .card .card-body table.table tr th:first-child, .card .card-body table.table tr td:first-child {
    border-left: 0;
} */

.content-wrapper tr th {
    font-size: 15px;
    padding: 8px;
    font-weight: 600;
    letter-spacing: .5px;
}

.content-wrapper .table-bordered thead th {
    background: #edeeff;
}

.content-wrapper .table-bordered tbody td,
.content-wrapper .table-bordered thead th {
    border-left: 0;
    border-right: 0;
    padding: 10px !important;
    vertical-align: middle;
}

/* .table-bordered thead td, .table-bordered thead th {
    border-bottom-width: 2px;
} */

/* .table-bordered thead th {
    vertical-align: bottom;
    border-bottom: 0;
} */

/* .table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6;
} */

/* .table td, .table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
} */

/* .card .card-body table.table tr td {
    font-size: 14px;
    padding: 8px;
} */

/* .btn-xs {
    padding: 0.125rem 0.25rem;
    font-size: .75rem;
    line-height: 1.5;
    border-radius: 0.15rem;
} */

.content-wrapper .btn-info {
    color: #fff;
    background-color: #8c8fce;
    border-color: #8c8fce;
    box-shadow: none;
}

/* .cus_action_btn {
    display: inline-block;
} */

/* .action_dropdown {
    padding: 0px;
    text-align: center;
    font-size: 18px;
    color: #a1c849;
} */

/* .dropdown, .dropleft, .dropright, .dropup {
    position: relative;
} */

.content-wrapper .card .cus_action_btn {
    display: inline-block;
    margin-bottom: 5px;
}

.content-wrapper .cus_action_btn .action_btn {
    color: #fff;
    background: #2d3290;
    border-color: #2d3290;
}

.content-wrapper .cus_action_btn .action_btn,
.content-wrapper .cus_action_btn .finance_btn {
    font-size: 14px;
    line-height: 16px;
    padding: 3px 5px;
}

/* .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
} */

/* .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .175);
} */

/* .action_dropdown .dropdown-menu {
    padding: 0;
    top: 30px !important;
    right: 0px;
    left: auto !important;
    transform: none !important;
} */

.content-wrapper .action_dropdown .dropdown-menu:first-child {
    border-top: 0px;
}

.content-wrapper .action_dropdown .dropdown-menu {
    color: #000;
    font-size: 14px;
    line-height: 21px;
    padding: 7px 10px;
    border-top: 1px solid #e3e3e3;
}

/* .card .card-body .booking_data .check_status {
    text-align: center;
} */

.content-wrapper .card .card-body .booking_data .check_status .red_clr {
    background: #ff00001f;
    color: #ff0000;
}

.content-wrapper .card .card-body .booking_data .check_status .green_clr {
    background: #00ff001f;
    color: #00ff00;
}

.content-wrapper .card .card-body .booking_data .check_status .yellow_clr {
    background: #ffff001f;
    color: #d1d100;
}

.content-wrapper .card .card-body .booking_data .check_status .chk_stat_btn {
    font-size: 13px;
    line-height: 16px;
    border-radius: 4px;
    display: inline-block;
    padding: 3px 7px;
    font-weight: 600;
    text-decoration: none;
}






.content-wrapper .dataTables_info {
    padding-top: 0.85em;
    white-space: nowrap;
}

.content-wrapper .dataTables_paginate {
    margin: 0;
    white-space: nowrap;
    text-align: right;
}

.content-wrapper div.dataTables_paginate ul.pagination {
    margin: 2px 0;
    white-space: nowrap;
    justify-content: flex-end;
}

.content-wrapper .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}

.content-wrapper .page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.content-wrapper .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.content-wrapper .page-item .page-link {
    color: #2d3290;
    border-color: #2d3290;
}

.content-wrapper .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    /* margin-left: -1px; */
    line-height: 1.25;
    /* color: #37b1c2; */
    /* background-color: #fff; */
    border: 1px solid #dee2e6;
}

.content-wrapper .page-item.active .page-link {
    color: #fff;
    background-color: #2d3290;
    border-color: #2d3290;
}

.content-wrapper .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}





@media (min-width: 576px) {


    /* .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    } */
}

@media (min-width: 768px) {
    /* .cus_user_tags ul li.cus_tag {
        margin-right: 15px;
        padding: 6px 12px;
    } */

    /* .card_custom_menu ul li {
        display: inline-block;
        margin-right: 10px;
    } */



    /* .btn-theme-sm {
        font-size: 14px;
        line-height: 18px;
        padding: 6px 13px;
    } */



    /* .btn-theme-lg {
        font-size: 15px;
        line-height: 21px;
        padding: 8px 15px;
    } */
}

/* @media (min-width: 601px){
.card_custom_menu {
    display: inline-block;
}
} */

@media (max-width:576px) {
    .content-wrapper .sm-center {
        text-align: center;
    }

    .content-wrapper .float-sm-right {
        float: none !important;
        justify-content: center !important;
        text-align: center;
    }

    .content-wrapper .container-fluid {
        padding: 2px;
    }

    .content-wrapper {
        margin: 0 !important;
    }

    .content-wrapper .card {
        margin: 10px 0;
    }
}

@media (max-width: 767.98px) {
    .content-wrapper .update_btn {
        margin-top: 5px;
    }
}

.table-hover>tbody>tr:hover>* {
    /* --bs-table-color-state: #e1e2ff; */
    --bs-table-bg-state: #e7e8ff;
}




.card .card-body .fare_details li {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.30);
    padding-bottom: 8px;
    font-size: 15px;
    line-height: 21px;
    margin-bottom: 5px;
}

.cancelbookingmodal.modal-dialog{
    margin: 0px !important;
}

.request-form .request-form-group {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding-bottom: 10px;
}